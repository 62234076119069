//@ts-ignore
import BiologyBook from "src/assets/images/biology-book.jpg";
import { Button } from "../ui/button";
import {
  CalendarCheck,
  ChevronRight,
  Key,
  Languages,
  Ticket,
  User,
  UserCircle,
} from "lucide-react";
import { speakingLanguages } from "src/data/courses";

const MainCourses = () => {
  return (
    <div className="p-6">
      <div className="flex flex-col gap-4 mb-10 justify-between">
        <div className="grid grid-cols-7 gap-4">
          <div className="col-span-2">
            <div className="bg-white p-4 ">
              <div className="flex items-center justify-center">
                <UserCircle className="h-16 w-16 text-gray-400" />
              </div>
              <div className="flex flex-col items-start space-y-4 mt-4">
                <div className="flex flex-col items-start">
                  <p className="text-xs text-gray-400">Competencies</p>
                  <p className="text-sm">Maths</p>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-xs text-gray-400">Learning Language</p>
                  <p className="text-sm">Spanish</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="bg-white p-4 rounded-lg">
              <div className="flex items-center border-b border-gray-100 pb-4">
                <p className="text-lg font-medium">My results</p>
              </div>
              <div className="flex items-center justify-center h-36">
                <p className="text-lg font-medium">No results yet</p>
              </div>
            </div>
            <div className="bg-white p-4 mt-4 rounded-lg">
              <div className="flex items-end py-4 justify-between">
                <p className="text-lg font-medium ">
                  Xtlessons's Language Exam
                </p>
                <p className="text-2xl font-semibold">
                  <span className="text-xs">€</span>17
                </p>
              </div>
              <div className="pb-4">
                <p className="text-sm">Key characteristics:</p>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="h-8 w-8 rounded-full bg-[#333] flex items-center justify-center">
                    <Ticket className="fill-white text-[#333] h-5 w-5" />
                  </div>
                  <p className="text-xs">4 exam attempts</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="h-8 w-8 rounded-full bg-[#333] flex items-center justify-center">
                    <Languages className="text-white h-5 w-5" />
                  </div>
                  <p className="text-xs">Multiple languages</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="h-8 w-8 rounded-full bg-[#333] flex items-center justify-center">
                    <CalendarCheck className="text-white h-5 w-5" />
                  </div>
                  <p className="text-xs">Multiple languages</p>
                </div>
              </div>
              <div className="py-8">
                <p className="text-sm">Available languages:</p>
                <div className="flex items-center space-x-2 mt-4">
                  {speakingLanguages[1].languages.map((lang) => (
                    <p className="text-sm text-[#333]">{lang}</p>
                  ))}
                </div>
              </div>
              <div className="py-8">
                <div className="flex items-start justify-between ">
                  <div className="flex items-center space-x-2">
                    <p className="text-sm text-gray-500">Show details</p>
                    <ChevronRight className="h-4 w-4 text-gray-500" />
                  </div>
                  <Button size={"lg"} variant={"orange"}>
                    Buy now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex items-center justify-between w-full mt-4">
          <div className="flex flex-col items-start">
            <p className="text-xl font-semibold">Courses</p>
            <p className="text-sm text-gray-400">
              Read and manage all your courses
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-10">
          <div className="flex flex-col items-center w-[200px] bg-white p-4 rounded-lg">
            <img src={BiologyBook} className="h-52" />
            <p className="text-sm font-medium pt-3">
              Course Material Biology Module 1
            </p>
            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-gray-400">45% Completed</p>
              <Button variant={"outline"} size={"sm"}>
                Read
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center w-[200px] bg-white p-4 rounded-lg">
            <img src={BiologyBook} className="h-52" />
            <p className="text-sm font-medium pt-3">
              Course Material Biology Module 1
            </p>
            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-gray-400">45% Completed</p>
              <Button variant={"outline"} size={"sm"}>
                Read
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center w-[200px] bg-white p-4 rounded-lg">
            <img src={BiologyBook} className="h-52" />
            <p className="text-sm font-medium pt-3">
              Course Material Biology Module 1
            </p>
            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-gray-400">45% Completed</p>
              <Button variant={"outline"} size={"sm"}>
                Read
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center w-[200px] bg-white p-4 rounded-lg">
            <img src={BiologyBook} className="h-52" />
            <p className="text-sm font-medium pt-3">
              Course Material Biology Module 1
            </p>
            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-gray-400">45% Completed</p>
              <Button variant={"outline"} size={"sm"}>
                Read
              </Button>
            </div>
          </div>
        </div>
        <div className=" flex-col space-y-10 bg-white rounded-lg p-6">
          <p className="text-xl font-semibold mb-10">Popular Courses Pack</p>
          <div className="flex items-center p-6 border border-border border-dashed gap-10 justify-between">
            <div className="flex flex-col items-start space-y-10 w-[500px]">
              <p className="font-medium">Starter Pack</p>
              <p className="text-sm text-gray-400">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque
                quo omnis ipsum vero hic et rem nobis qui, iste excepturi?
                Perspiciatis a quo alias tempore magni corporis culpa doloremque
                animi?
              </p>
              <div className="flex items-center gap-10 ">
                <button className="bg-orange-400 hover:bg-orange-500 transition text-white  py-2 w-1/2 rounded">
                  <p className="font-medium text-sm"> Register</p>
                </button>
                <div className="flex items-center gap-3 w-full">
                  <Key className="h-10 w-10" />
                  <p className="text-sm font-medium">
                    Unlock this pack by registering for Biology Module 2
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col items-center bg-white">
              <div className="w-60 h-52 relative overflow-hidden rounded-2xl flex flex-col items-center justify-center">
                <img
                  src={BiologyBook}
                  className="absolute top-0 right-0 object-cover blur-md -z-10"
                  alt="biology-module-2"
                />
                <div className="z-20 ">
                  <p className="text-center text-sm text-white">
                    Unlock this pack by registering for Biology Module 2 here
                  </p>
                  <p className=" pt-2 underline text-center text-white text-sm">
                    Register here
                  </p>
                </div>
              </div>
              <p className="text-sm w-60 pt-2">
                Course Material of Biology Class Module 2
              </p>
            </div> */}
            <div className="flex flex-col items-start ">
              <div className="w-60 h-52 relative overflow-hidden  flex flex-col items-center justify-center">
                <img
                  src={BiologyBook}
                  className="absolute top-0 right-0 object-cover blur-md -z-10"
                  alt="biology-module-2"
                />
                <div className="z-20 ">
                  <p className="text-center text-sm text-white">
                    Unlock this pack by registering for Biology Module 2 here
                  </p>
                  <p className=" pt-2 underline text-center text-white text-sm">
                    Register here
                  </p>
                </div>
              </div>
              <p className="text-sm w-60 pt-2">
                Course Material of Biology Class Module 2
              </p>
            </div>
          </div>
          <div className="flex items-center p-6 border border-border border-dashed gap-10 justify-between">
            <div className="flex flex-col items-start space-y-10 w-[500px]">
              <p className="font-medium">Intermediate Pack</p>
              <p className="text-sm text-gray-400">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque
                quo omnis ipsum vero hic et rem nobis qui, iste excepturi?
                Perspiciatis a quo alias tempore magni corporis culpa doloremque
                animi?
              </p>
              <div className="flex items-center gap-10 ">
                <button className="bg-orange-400 hover:bg-orange-500 transition text-white  py-2 w-1/2 rounded">
                  <p className="font-medium text-sm"> Register</p>
                </button>
                <div className="flex items-center gap-3 w-full">
                  <Key className="h-10 w-10" />
                  <p className="text-sm font-medium">
                    Unlock this pack by registering for Biology Module 2
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-60 h-52 relative overflow-hidden rounded-2xl flex flex-col items-center justify-center">
                <img
                  src={BiologyBook}
                  className="absolute top-0 right-0 object-cover blur-md -z-10"
                  alt="biology-module-2"
                />
                <div className="z-20 ">
                  <p className="text-center text-sm text-white">
                    Unlock this pack by registering for Biology Module 2 here
                  </p>
                  <p className=" pt-2 underline text-center text-white text-sm">
                    Register here
                  </p>
                </div>
              </div>
              <p className="text-sm w-60 pt-2">
                Course Material of Biology Class Module 2
              </p>
            </div>
            <div className="flex flex-col items-start">
              <div className="w-60 h-52 relative overflow-hidden rounded-2xl flex flex-col items-center justify-center">
                <img
                  src={BiologyBook}
                  className="absolute top-0 right-0 object-cover blur-md -z-10"
                  alt="biology-module-2"
                />
                <div className="z-20 ">
                  <p className="text-center text-sm text-white">
                    Unlock this pack by registering for Biology Module 2 here
                  </p>
                  <p className=" pt-2 underline text-center text-white text-sm">
                    Register here
                  </p>
                </div>
              </div>
              <p className="text-sm w-60 pt-2">
                Course Material of Biology Class Module 2
              </p>
            </div>
            <div className="flex flex-col items-start">
              <div className="w-60 h-52 relative overflow-hidden rounded-2xl flex flex-col items-center justify-center">
                <img
                  src={BiologyBook}
                  className="absolute top-0 right-0 object-cover blur-md -z-10"
                  alt="biology-module-2"
                />
                <div className="z-20 ">
                  <p className="text-center text-sm text-white">
                    Unlock this pack by registering for Biology Module 2 here
                  </p>
                  <p className=" pt-2 underline text-center text-white text-sm">
                    Register here
                  </p>
                </div>
              </div>
              <p className="text-sm w-60 pt-2">
                Course Material of Biology Class Module 2
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCourses;
