import autoAnimate from "@formkit/auto-animate";
import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { cn } from "../lib/utils";

interface QuestionCardProps {
  quest: {
    question: string;
    answer: string;
  };
}

const QuestionCard = ({ quest }: QuestionCardProps) => {
  const [show, setShow] = useState(false);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const reveal = () => setShow(!show);
  return (
    <div ref={parent} className="bg-white px-4 py-2 mb-4">
      <div
        onClick={reveal}
        className="flex items-center justify-between cursor-pointer"
      >
        <p className="text-[#333] font-semibold text-sm">{quest.question}</p>
        <ChevronDown
          className={cn(
            "h-4 w-4",
            show && "-rotate-180 transition duration-200 ease-in-out"
          )}
        />
      </div>
      {show && <p className="text-[#333] pt-4 text-sm">{quest.answer}</p>}
    </div>
  );
};

export default QuestionCard;
