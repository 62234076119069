import React from "react";
import MainCourses from "../components/courses/main-courses";
import LeftSidebar from "../components/sidebar-left";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../components/ui/navigation-menu";
import { cn } from "../lib/utils";
import {
  coursesSecundaria,
  coursesBachillerato,
  coursesPrimaria,
} from "../data/courses";
import HeaderDashboard from "../components/HeaderDashboard";
import Footer from "../components/footer/Footer";

const Materials = () => {
  const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
  >(({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-2 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            target="_blank"
            rel="noreferrer"
            {...props}
          >
            <div className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {title}
            </div>
            {/* <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p> */}
          </a>
        </NavigationMenuLink>
      </li>
    );
  });
  ListItem.displayName = "ListItem";
  return (
    <div className=" h-full w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      {/* <div>
        <LeftSidebar />
      </div> */}
      <div className="p-6 flex items-center bg-white justify-between  mt-20">
        <p className="text-xl font-semibold">My Materials</p>
        <div className="flex items-center space-x-4">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <p className="text-sm font-medium">Courses</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {coursesPrimaria.map((component) => (
                      <ListItem
                        // key={component.description}
                        title={component.title}
                        // href={component.href}
                      >
                        {/* {component.title} */}
                        {/* {component.description} */}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <p className="text-sm font-medium">Secundaria</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {coursesSecundaria.map((component) => (
                      <ListItem
                        // key={component.description}
                        title={component.title}
                        // href={component.href}
                      >
                        {/* {component.title} */}
                        {/* {component.description} */}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <p className="text-sm font-medium">Bachillerato</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent className="">
                  <ul className="grid w-[400px]  gap-2 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {coursesBachillerato.map((component) => (
                      <ListItem
                        // key={component.description}
                        title={component.title}
                        // href={component.href}
                      >
                        {/* {component.title} */}
                        {/* {component.description} */}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <p className="text-sm font-medium">Webinars</p>
        </div>
      </div>
      <main className="max-w-7xl mx-auto w-full">
        <div>
          <MainCourses />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Materials;
