import { MessageCircle, Search, ThumbsUp } from "lucide-react";
import HeaderDashboard from "../components/HeaderDashboard";
import Footer from "../components/footer/Footer";
import { Button } from "../components/ui/button";

//@ts-ignore
import GirlBanner from "src/assets/images/girlBanner.jpg";

//@ts-ignore
import People4 from "src/assets/images/people4.jpg";

const communityActions = [
  { title: "Following" },
  {
    title: "For you",
  },
  {
    title: "Podcasts",
  },
  {
    title: "Excercise",
  },
  {
    title: "Questions",
  },
];

const hashtags = [
  { title: "# Xtlessons" },
  { title: "# TrendingWords" },
  { title: "# XtLessonsOnline" },
  { title: "# XtlessonsLanguage" },
  { title: "# XtlessonsLearn" },
  { title: "# Students" },
];

const Community = () => {
  return (
    <div className="flex flex-col h-full min-h-[100vh] w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="min-h-[calc(100vh-370px)]">
        <div className="flex items-center justify-between w-full mt-20 bg-white border-t border-border px-10 ">
          <div className="flex items-center space-x-2">
            {communityActions.map((action) => (
              <div className="py-4 cursor-pointer px-6">
                <p className="text-gray-400 hover:text-gray-500 text-sm transition">
                  {action.title}
                </p>
              </div>
            ))}
          </div>
          <div className="border px-2 rounded-lg flex items-center py-2">
            <Search className="h-4 w-4 text-gray-400" />
            <input
              placeholder="Search by word..."
              className="outline-none bg-transparent px-2 text-sm font-paragraph"
            />
          </div>
        </div>
        <div className="p-6 bg-white w-full">
          <div className="max-w-7xl mx-auto">
            <p className="font-semibold text-xl mb-4">Themes for you </p>
            <div className="flex  items-center space-x-4 overflow-x-auto">
              {hashtags.map((hastag) => (
                <div className="rounded-lg w-60 h-20 border border-border flex items-center justify-center">
                  <p className="font-medium text-[16px]">{hastag.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-4 mt-4 max-w-7xl mx-auto">
          <div className="col-span-5">
            <div className="bg-white p-4">
              <div className="flex items-center ">
                <img
                  src={GirlBanner}
                  className="h-10 w-10 rounded-full object-cover"
                  alt="girl"
                />
                <div className="flex flex-col items-start space-y-1 ml-4">
                  <p className="font-medium">Ellie Hoffman</p>
                  <p className="font-medium text-gray-400 text-sm">
                    Professional Teacher
                  </p>
                </div>
              </div>
              <div className="mt-4 ">
                <p className="text-lg font-semibold">
                  How to practive Language Every Day You Eat
                </p>
                <p className="truncate w-2/3 mt-4 text-gray-400 font-medium text-sm">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut
                  temporibus, fugit aliquam animi asperiores doloribus. Nemo,
                  minus pariatur amet, vel facilis ratione temporibus harum,
                  expedita natus ab perferendis eum aperiam.
                </p>
                <div className="flex items-center justify-between mt-4">
                  <p className="text-sm text-gray-400">
                    {new Date().toDateString()}
                  </p>
                  <div className="flex items-center space-x-6">
                    <div className="flex items-center space-x-1">
                      <MessageCircle className="h-5 w-5" />
                      <p className="text-sm">12</p>
                    </div>
                    <div className="flex items-center space-x-1">
                      <ThumbsUp className="h-5 w-5" />
                      <p className="text-sm">23</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 border-t border-border">
              <div className="flex items-center">
                <img
                  src={People4}
                  className="h-10 w-10 rounded-full object-cover"
                  alt=" juan"
                />
                <div className="flex flex-col items-start space-y-1 ml-4">
                  <p className="font-medium">Juan Sanchez</p>
                  <p className="font-medium text-gray-400 text-sm">
                    Community Tutor
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="bg-white p-4 rounded-lg border border-border">
              <p className="text-lg font-medium">Ask a question</p>
              <p className="text-sm mt-4 text-gray-500">
                Ask any question you have about the subject.
              </p>
              <Button className="w-full mt-4">Ask</Button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Community;
