import { Search } from "lucide-react";
import HeaderDashboard from "../components/HeaderDashboard";

//@ts-ignore
import SupportImage from "src/assets/images/support.jpg";
import { useState } from "react";
import { cn } from "../lib/utils";
import Footer from "../components/footer/Footer";

const Support = () => {
  const [activeType, setActiveType] = useState("students");
  return (
    <>
      <div className="h-screen w-screen bg-[#f8f8f8]">
        <HeaderDashboard />
        <div className="bg-white mt-20">
          <div className="relative h-96 w-full">
            <img
              src={SupportImage}
              className="w-full h-full object-cover"
              alt="support"
            />

            <div className="h-full w-full absolute bg-black/30 top-0 flex flex-col items-center justify-center">
              <p className="text-white text-3xl font-medium pb-4">
                How can we help?
              </p>
              <div className="bg-white flex items-center px-4 py-2 rounded-md w-[500px]">
                <Search className="h-4 w-4 text-gray-400" />
                <input
                  className="bg-transparent outline-none  px-2 font-paragraph"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="max-w-7xl mx-auto mt-10">
              <div className="flex items-center justify-center ">
                <p
                  onClick={() => setActiveType("students")}
                  className={cn(
                    "text-xl font-medium pb-4 border-b-2 px-4 transition duration-200 ease-in-out cursor-pointer",
                    activeType === "students" && "  border-orange"
                  )}
                >
                  For Students
                </p>
                <p
                  onClick={() => setActiveType("teachers")}
                  className={cn(
                    "text-xl font-medium pb-4 border-b-2 px-4 transition duration-200 ease-in-out cursor-pointer",
                    activeType === "teachers" && " border-orange"
                  )}
                >
                  For Teachers
                </p>
              </div>
              <div className="grid grid-cols-3 gap-14 mt-10 py-6">
                <div className="border rounded p-4">
                  <p className="font-medium">Cómo utilizar xtlessons</p>
                  <ul className="list-inside list-disc my-2">
                    <li className="text-blue-500 text-sm">
                      Notice of termination of regional service
                    </li>
                    <li className="text-blue-500 text-sm">
                      How to book instant lessons?
                    </li>
                    <li className="text-blue-500 text-sm">
                      Frequently asked questions about italki and OOPT language
                      test
                    </li>
                  </ul>
                  <p className="text-blue-600 font-semibold items-end text-right text-sm">
                    Show all the articles
                  </p>
                </div>
                <div className="border rounded p-4">
                  <p className="font-medium">Cómo utilizar xtlessons</p>
                  <ul className="list-inside list-disc my-2">
                    <li className="text-blue-500 text-sm">
                      Notice of termination of regional service
                    </li>
                    <li className="text-blue-500 text-sm">
                      How to book instant lessons?
                    </li>
                    <li className="text-blue-500 text-sm">
                      Frequently asked questions about italki and OOPT language
                      test
                    </li>
                  </ul>
                  <p className="text-blue-600 font-semibold items-end text-right text-sm">
                    Show all the articles
                  </p>
                </div>
                <div className="border rounded p-4">
                  <p className="font-medium">Cómo utilizar xtlessons</p>
                  <ul className="list-inside list-disc my-2">
                    <li className="text-blue-500 text-sm">
                      Notice of termination of regional service
                    </li>
                    <li className="text-blue-500 text-sm">
                      How to book instant lessons?
                    </li>
                    <li className="text-blue-500 text-sm">
                      Frequently asked questions about italki and OOPT language
                      test
                    </li>
                  </ul>
                  <p className="text-blue-600 font-semibold items-end text-right text-sm">
                    Show all the articles
                  </p>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="mb-4 font-semibold">Frequent questions</h1>
                <ul className="list-inside list-disc space-y-4">
                  <li className="text-blue-500">
                    {" "}
                    Notice of termination of regional service
                  </li>
                  <li className="text-blue-500">
                    {" "}
                    How to book instant lessons?
                  </li>
                  <li className="text-blue-500">
                    Frequently asked questions about italki and OOPT language
                    test
                  </li>
                </ul>
              </div>
              <div className="flex items-center justify-center mt-10">
                <p className="text-lg">Still need help?</p>
                <div className="border rounded border-blue-500 p-2 ml-2 cursor-pointer">
                  <p className="text-blue-500 hover:underline ">
                    Submit a request
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
