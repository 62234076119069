import React, { useState } from "react";
import LeftSidebar from "../components/sidebar-left";
import MainTeachers from "../components/teachers/main-teachers";
import SidebarTeachers from "../components/teachers/sidebar-teachers";
import ModalVideo from "react-modal-video";

const Teachers = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div>
          <div className="p-6 flex items-center border border-border">
            <p className="text-xl font-semibold">
              Let's find a tutor for Ismael
            </p>
          </div>
          <div className="grid grid-cols-7">
            <div className="col-span-5">
              <MainTeachers />
            </div>
            <div className="col-span-2">
              <SidebarTeachers />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Teachers;
