import LeftSidebar from "../components/sidebar-left";
import MainSubscriptions from "../components/subscriptions/MainSubscriptions";
import VerticalLayout from "../layout/VerticalLayout";

const Subscriptions = () => {
  return (
    <VerticalLayout>
      <div>
        <div className="p-6 flex items-center border border-border">
          <p className="text-xl font-semibold">Suscriptions</p>
        </div>
        <div className="grid grid-cols-7 w-[70%]">
          <div className="col-span-5">
            <MainSubscriptions />
          </div>
          <div className="col-span-2">{/* <SidebarSettings /> */}</div>
        </div>
      </div>
    </VerticalLayout>
  );
};

export default Subscriptions;
