//@ts-nocheck
//@ts-ignore
import CristinaImage from "src/assets/images/people2.jpg";
import { teachersArray } from "./main-teachers";
import { ChevronRight, Pin, Play, Send } from "lucide-react";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import RatingStars from "../rating-stars";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const SidebarTeachers = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="h-screen overflow-hidden relative">
      <div className="p-6 flex flex-col items-center space-y-2 ">
        <img
          src={CristinaImage}
          className="h-24 w-24 rounded-full object-cover"
          alt="cristina"
        />
        <p className="text-lg font-semibold">{teachersArray[0].fullName}</p>
        <div className="flex items-center gap-4">
          <Pin className="h-5 w-5 text-gray-400" />
          <p className="text-gray-400">{teachersArray[0].location}</p>
        </div>
        <p className="text-center text-gray-400">
          {teachersArray[0].description}
        </p>
        {/* <React.Fragment>
          <ModalVideo
            allowFullScreen
            channel="youtube"
            youtube={{ mute: 0, autoplay: 1 }}
            isOpen={open}
            onClose={() => setOpen(false)}
            videoId="xzBoWzvMAIw"
          ></ModalVideo>
        </React.Fragment> */}
        {open && (
          <div
            onClick={() => setOpen(false)}
            className="fixed top-0 right-0 bg-black/60 z-20 h-screen w-screen transition"
          >
            <div className="flex items-center justify-center h-full w-full  ">
              <ModalVideo
                allowFullScreen
                channel="youtube"
                youtube={{ mute: 0, autoplay: 1 }}
                isOpen={open}
                onClose={() => setOpen(false)}
                videoId="xzBoWzvMAIw"
              ></ModalVideo>
            </div>
          </div>
        )}

        <Button
          onClick={() => setOpen(true)}
          variant={"outline"}
          className="flex items-center gap-4 pt-2"
        >
          <Play className="h-5 w-5 text-blue-500" />
          <p className="text-blue-500 text-sm">Watch intro video</p>
        </Button>
      </div>
      <div className=" flex flex-col items-center w-full">
        <div className="flex items-center justify-between w-full border-y border-border py-3 px-2 text-gray-400">
          <p className="text-lg">Available Subjects</p>
          <ChevronRight className="h-5 w-5" />
        </div>
        <div className="flex items-center justify-between w-full border-y border-border py-3 px-2 text-gray-400">
          <p className="text-lg">{teachersArray[0].reviews} Reviews</p>
          <ChevronRight className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col items-center w-full h-[500px] overflow-y-auto">
        {teachersArray.map((teacher) => (
          <div className="flex items-center py-3 border-b ">
            <div className="w-[10%]">
              <img
                src={CristinaImage}
                className="w-14 h-14 rounded-full object-cover"
                alt="christina"
              />
            </div>
            <div className="w-[80%] ml-3 flex flex-col space-y-2">
              <div className="flex items-center justify-between">
                {teachersArray[0].fullName}
                <RatingStars activeStar={teachersArray[0].rating} />
              </div>
              <p className="text-gray-400 text-xs">
                My daughter loved working with Elizabeth! She was motivating and
                yet at the same time so understanding and kind.
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center w-full  p-6 border-border border ">
        <Textarea placeholder="Send your first message" />
        <div className="justify-end ml-auto mt-4">
          <Button className="gap-2 bg-green-500">
            <Send />
            <p>Send</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SidebarTeachers;
