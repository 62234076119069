import { ChevronRight } from "lucide-react";

//@ts-ignore
import on1Lessons from "../assets/images/1-on-1lessons.png";
import { seeOffers } from "../data/courses";

const SeeOffers = () => {
  return (
    <div className="py-20 bg-white">
      <p className="text-4xl font-semibold text-center text-[#333]">
        See what Xtralessons offers
      </p>
      <div className="grid grid-cols-3 max-w-5xl mx-auto gap-10 mt-20">
        {seeOffers.map((offer) => (
          <div className="flex flex-col items-center space-y-4">
            <img
              alt={`offer-${offer.title}`}
              src={offer.img}
              className="h-52 w-full object-cover"
            />
            <p className="text-lg font-semibold text-[#333]">{offer.title}</p>
            <p className="text-gray-400 text-sm text-center">{offer.desc}</p>
            <div className="flex items-center text-gray-400 font-semibold pt-6">
              <p className="text-sm">{offer.button}</p>
              <ChevronRight size={18} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeeOffers;
