const Footer = () => {
  return (
    <div className="text-[#333] h-80 bg-white w-full px-10 py-4 mt-10">
      <div className="flex flex-col items-start max-w-5xl mx-auto">
        <div>
          <p className="text-lg font-semibold">
            Our mission is to provide a world-class education to anyone,
            anywhere.
          </p>
        </div>
        <div className="flex items-center space-x-6 mb-10">
          <ul className="flex flex-col space-y-4">
            <li className="text-xs font-medium text-white">Acerca de</li>
            <li className="text-xs">Noticias</li>
            <li className="text-xs">Impacto</li>
            <li className="text-xs">Nuestro equipo</li>
          </ul>
          <ul className="flex flex-col space-y-4">
            <li className="text-xs font-medium text-white">Contacto</li>
            <li className="text-xs">Centro de ayuda</li>
            <li className="text-xs">Comunidad de apoyo</li>
            <li className="text-xs">Comparte tu historia</li>
          </ul>
          <ul className="flex flex-col space-y-4">
            <li className="text-xs font-medium text-white">Cursos</li>
            <li className="text-xs">Centro de ayuda</li>
            <li className="text-xs">Comunidad de apoyo</li>
            <li className="text-xs">Comparte tu historia</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col items-start max-w-5xl mx-auto space-y-4">
        <div className="flex items-center gap-2">
          <p className="text-xs">Idioma</p>
          <p className="text-xs">español</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-xs">País</p>
          <p className="text-xs">EE.UU</p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-xs">© 2023 XtLessons</p>
          <p className="text-xs">Términos de uso</p>
          <p className="text-xs">Política de privacidad</p>
          <p className="text-xs">Aviso de cookies</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
