import { ChevronRight, ChevronUp, TriangleRight } from "lucide-react";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
// import GirlIntroduction from "../assets/images/";

//@ts-ignore
import Math from "../assets/images/math.png";

//@ts-ignore
import English from "../assets/images/Flag-United-Kingdom.webp";

//@ts-ignore
import Spanish from "../assets/images/Bandera_de_España.png";

//@ts-ignore
import Physics from "../assets/images/physics.avif";

//@ts-ignore
import French from "../assets/images/Flag_of_France.webp";
//@ts-ignore
import Music from "../assets/images/music.png";
import { useModal } from "../hooks/use-modal-store";

const cursos = [
  {
    title: "Matemáticas: De preescolar a 8.º",
    channels: [
      "Preescolar(app en inglés)",
      "Jardín de niños",
      "1.er grado",
      "2.er grado",
      "3.er grado",
      "4.er grado",
      "5.er grado",
      "6.er grado",
      "7.er grado",
      "8.er grado",
      "Aritmética",
      "Preálgebra",
    ],
  },
  {
    title: "Matemáticas: Cursos de preparación",
    channels: [
      "Preparación para 3.º",
      "Preparación para 4.º",
      "Preparación para 5.º",
      "Preparación para 6.º",
      "Preparación para 7.º",
      "Preparación para 8.º",
      "Preparación para Álgebra 1",
      "Preparación para Geometría",
      "Preparación para Álgebra 2",
      "Preparación para Precálculo",
    ],
  },
  {
    title: "Matemáticas:De 9º. a la universidad",
    channels: [
      "Álgebra 1",
      "Geometría",
      "Álgebra 2",
      "Trigonometría",
      "Precálculo",
      "Estadística y probabilidad",
      "Cálculo 1",
      "Cálculo 2",
      "Estadística avanzada",
      "Cálculo multivariable",
      "Ecuaciones diferenciales",
      "Álgebra lineal",
    ],
  },
];

const cursos2 = [
  {
    title: "Ciencia",
    channels: [
      "Biología de secundaria",
      "Biología avanzada",
      "Química avanzada",
      "Física avanzada",
      "Todos los cursos",
    ],
  },
  {
    title: "Computación",
    channels: ["Programación", "Animación digital", "Todos los cursos"],
  },
  {
    title: "Economía y finanzas",
    channels: [
      "Microeconomía",
      "Macroeconomía",
      "Mercados financieros y de capitales",
      "Todos los cursos",
    ],
  },
];

const BannerIntroduction = () => {
  const modal = useModal();
  return (
    <main className="max-w-5xl mx-auto py-20">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col space-y-6">
          <p className="font-semibold  text-5xl text-[#333]">
            Become an expert <br /> in any subject
          </p>
          <div className="flex items-center space-x-2">
            <TriangleRight className="h-5 w-5 fill-orange text-orange" />

            <p className="text-sm text-[#333]">
              Take customizable 1-on-1 lessons trusted by millions of users
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <TriangleRight className="h-5 w-5 fill-orange text-orange" />

            <p className="text-sm text-[#333]">
              Learn from certified teachers that fit your budget and schedule
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <TriangleRight className="h-5 w-5 fill-orange text-orange" />

            <p className="text-sm text-[#333]">
              Connect with a global community of learners
            </p>
          </div>
          <Button
            onClick={() => modal.onOpen("login")}
            variant={"orange"}
            className="w-60"
          >
            Start now
          </Button>
        </div>
        <div>
          <img
            src={require("../assets/images/girlBanner.jpg")}
            className="h-72 object-cover"
            alt="girl-studying"
          />
        </div>
      </div>
      <div className="border flex items-center space-x-6 px-10 py-4 mt-20">
        <div className="flex items-center space-x-2">
          <img src={Math} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">Maths</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <img src={English} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">English</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <img src={Spanish} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">Spanish</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <img src={Physics} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">Physics</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <img src={French} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">French</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <img src={Music} className="h-10 w-10 rounded object-cover" />
          <div>
            <p className="font-medium text-[#333] text-sm ">Music</p>
            <p className="text-gray-400 text-xs">7367 teachers</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="h-10 w-10 bg-gray-100 hover:shadow-xl hover:shadow-gray-100 cursor-pointer rounded-full flex flex-col items-center justify-center">
            <ChevronRight color="#333" />
          </div>
        </div>
      </div>
      {/* <div className="bg-[#f5f5f5] my-20 py-10 px-20">
        <div className="grid grid-cols-2 gap-20">
          <div>
            {cursos.map((curso, i) => (
              <div
                key={curso.title}
                className={cn(
                  "border-b border-[#333] pb-5",
                  i === cursos2.length - 1 && "border-transparent"
                )}
              >
                <div className="flex items-center justify-between">
                  <p className="text-[#333] text-xl my-7 font-semibold">
                    {curso.title}
                  </p>
                  <ChevronUp />
                </div>
                <div className="grid grid-cols-2 gap-4 ">
                  {curso.channels.map((channel) => (
                    <p className="text-sm">{channel}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div>
            {cursos2.map((curso, i) => (
              <div
                key={curso.title}
                className={cn(
                  "border-b border-[#333] pb-5",
                  i === cursos2.length - 1 && "border-transparent"
                )}
              >
                <div className="flex items-center justify-between">
                  <p className="text-[#333] text-xl my-7 font-semibold">
                    {curso.title}
                  </p>
                  <ChevronUp />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {curso.channels.map((channel) => (
                    <p className="text-sm">{channel}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default BannerIntroduction;
