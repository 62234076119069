import {
  ChevronDown,
  Clock,
  DollarSign,
  Filter,
  Globe2,
  MapPin,
  MessageCircle,
  Moon,
  Search,
  Sun,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import {
  afternoonEveningHours,
  daysWeek,
  morningHours,
  professorCountries,
  researchAsignaturesfrom12to16,
  speakingLanguages,
  timeRange,
} from "../data/courses";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Calendar } from "../components/ui/calendar";

const Filters = () => {
  return (
    <div className="flex items-center justify-between xl:px-20 mt-10 pb-4">
      <div className="flex items-center space-x-6  px-10 w-[70%] whitespace-nowrap">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <Globe2 className="h-4 w-4 " />
              <p className="text-[#333] font-medium text-sm">English</p>
              <ChevronDown className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4">
              <div className="flex items-center bg-gray-100 p-3 rounded-full">
                <Search className="h-7 w-7" />
                <input
                  placeholder="I want to learn"
                  className="px-4 outline-none font-paragraph bg-transparent text-sm "
                />
              </div>
              <div className="h-80 overflow-auto scrollbar-hide mt-4 no-scrollbar">
                {researchAsignaturesfrom12to16.map((asign) => (
                  <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <p className="text-sm">{asign.title}</p>
                  </div>
                ))}
              </div>
              <div></div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <DollarSign className="h-4 w-4" />
              <p className="text-[#333] font-medium text-sm">Price per hour</p>
              <ChevronDown className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4">
              <p className="text-[#333] font-medium">Price per hour</p>

              <Slider
                styles={{
                  rail: { backgroundColor: "#e5e8ed", height: 4 },
                  track: { backgroundColor: "#f16522", height: 10 },
                }}
                range
                min={4}
                max={60}
                defaultValue={60}
              />
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <Clock className="h-4 w-4 fill-[#333] text-white" />
              <p className="text-[#333] font-medium text-sm">Lesson time</p>
              <ChevronDown className="h-5 w-5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-10 flex items-start gap-4 w-[750px]">
              <div className="flex flex-col items-start ">
                <p className="text-[#333] font-medium">Choose a general time</p>
                <p className="text-gray-500 text-sm my-4 ">Days of the week</p>
                <div className="flex flex-wrap items-center gap-2 ">
                  {daysWeek.map((day) => (
                    <div
                      key={day.val}
                      className="bg-gray-100 px-5 py-3 rounded-full"
                    >
                      <p className="text-gray-600 text-xs">{day.label}</p>
                    </div>
                  ))}
                </div>
                <p className="text-sm text-gray-500 my-4">Time of the day</p>
                <div className="flex flex-wrap items-center gap-3 ">
                  {timeRange.map((time) => (
                    <div
                      key={time.hourTo}
                      className="bg-gray-100 px-4 py-2 rounded-full"
                    >
                      <p className="text-xs font-medium text-gray-600">
                        {time.hourFrom} - {time.hourTo}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-start">
                <p className="text-[#333] font-medium mb-4">
                  Choose a specific time
                </p>
                <div className="flex items-center w-full mb-4">
                  <div className="h-[0.5px] bg-gray-300 w-[40%] " />
                  <div className="mx-4 flex items-center space-x-1 ">
                    <Sun className="h-3 w-3 text-gray-300" />
                    <p className="text-gray-300  text-[10px] ">Morning</p>
                  </div>
                  <div className="h-[0.5px] bg-gray-300 w-[40%] " />
                </div>
                <div className="flex flex-wrap items-center justify-between gap-5">
                  {morningHours.map((hour) => (
                    <div key={hour.hour}>
                      <p className="text-xs font-medium">{hour.hour}</p>
                    </div>
                  ))}
                </div>
                <div className="flex items-center w-full my-4">
                  <div className="h-[0.5px] bg-gray-300 w-[40%] " />
                  <div className="mx-4 flex items-center space-x-1  ">
                    <Moon className="h-3 w-3 text-gray-300" />
                    <p className="text-gray-300  text-[10px] ">
                      Afternoon/Evening
                    </p>
                  </div>
                  <div className="h-[0.5px] bg-gray-300 w-[40%] " />
                </div>
                <div className="flex flex-wrap items-center justify-between gap-5">
                  {afternoonEveningHours.map((hour) => (
                    <div key={hour.hour}>
                      <p className="text-xs font-medium">{hour.hour}</p>
                    </div>
                  ))}
                </div>

                {/* <Datepicker
            mode="single"
            selected={date}
            onSelect={(range) => console.log(range)}
            className="rounded-md border"
          /> */}
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <MessageCircle className="h-5 w-5 text-[#333] fill-[#333]" />
              <p className="text-[#333] font-medium text-sm">Speaks</p>
              <ChevronDown className="h-5 w-5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4 w-96">
              <div className="flex items-center bg-gray-100 p-3 rounded-full">
                <Search className="h-7 w-7" />
                <input
                  className="px-4 outline-none font-paragraph bg-transparent flex-grow text-sm"
                  placeholder="I want my teacher to also speak..."
                />
              </div>

              <div className="h-80 overflow-auto scrollbar-hide no-scrollbar">
                {speakingLanguages.map((section) => (
                  <div>
                    <p className="text-lg font-medium text-[#333] mt-4">
                      {section.title}
                    </p>
                    <div className="flex flex-wrap items-center gap-4 my-4">
                      {section.languages.map((lang) => (
                        <div className="bg-gray-100 hover:bg-gray-200 transition duration-200 ease-in-out cursor-pointer px-5 py-3 rounded-full">
                          <p className="text-xs">{lang}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <MapPin className="h-5 w-5 text-white fill-[#333]" />
              <p className="text-[#333] font-medium text-sm">Teacher is from</p>
              <ChevronDown className="h-5 w-5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4 w-96">
              <div className="flex items-center bg-gray-100 p-3 rounded-full">
                <Search className="h-7 w-7" />
                <input
                  className="px-4 outline-none font-paragraph bg-transparent flex-grow text-sm"
                  placeholder="I want my teacher to be from..."
                />
              </div>

              <div className="h-80 overflow-auto scrollbar-hide no-scrollbar">
                {professorCountries.map((section) => (
                  <div>
                    <p className="text-lg font-medium text-[#333] mt-4">
                      {section.title}
                    </p>
                    <div className="flex flex-wrap items-center gap-4 my-4">
                      {section.countries.map((country) => (
                        <div className="bg-gray-100 hover:bg-gray-200 transition duration-200 ease-in-out cursor-pointer rounded-full px-5 py-3">
                          <p className="text-xs">{country}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-1 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <Filter className="h-5 w-5 text-white fill-[#333]" />
              <p className="text-[#333] font-medium text-sm">More</p>
              <ChevronDown className="h-5 w-5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4">
              <p className="text-[#333] font-semibold mb-6">Teacher types</p>

              <div className=" flex flex-col items-start space-y-4">
                <div className="flex items-center space-x-4">
                  <div className="w-[30px] h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                    <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                      <p className="text-xl">·</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="text-sm text-[#333] font-medium">Both</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="w-[30px] h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                    <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                      <p className="text-xl">·</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="font-medium text-sm text-[#333]">
                      Professional Teacher
                    </p>
                    <p className="text-xs text-gray-500">
                      A highly-skilled and qualified teacher who have experience
                      helping students reach their goals.
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="w-[30px] h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                    <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                      <p className="text-xl">·</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="font-medium text-sm text-[#333]">
                      Community Tutor
                    </p>
                    <p className="text-xs text-gray-500">
                      A native or advanced speaker who helps students through
                      informal tutoring or speaking practice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="px-10 bg-white">
        <div className="flex items-center bg-gray-100 py-2 px-4 rounded-full w-80">
          <Search className="h-7 w-7" />
          <input
            className="px-4 outline-none font-paragraph bg-transparent flex-grow text-sm"
            placeholder="Name/Subjects/Interests"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
