export const timeoptions = ["00:00", "00:15",
    "00:30", "00:45",
    "01:00", "01:15",
    "01:30", "01:45",
    "02:00", "02:15",
    "02:30", "02:45",
    "03:00", "03:15",
    "03:30", "03:45",
    "04:00", "04:15",
    "04:30", "04:45",
    "05:00", "05:15",
    "05:30", "05:45",
    "06:00", "06:15",
    "06:30", "06:45",
    "07:00", "07:15",
    "07:30", "07:45",
    "08:00", "08:15",
    "08:30", "08:45",
    "09:00", "09:15",
    "09:30", "09:45",
    "10:00", "10:15",
    "10:30", "10:45",
    "11:00", "11:15",
    "11:30", "11:45",
    "12:00", "12:15",
    "12:30", "12:45",
    "13:00", "13:15",
    "13:30", "13:45",
    "14:00", "14:15",
    "14:30", "14:45",
    "15:00", "15:15",
    "15:30", "15:45",
    "16:00", "16:15",
    "16:30", "16:45",
    "17:00", "17:15",
    "17:30", "17:45",
    "18:00", "18:15",
    "18:30", "18:45",
    "19:00", "19:15",
    "19:30", "19:45",
    "20:00", "20:15",
    "20:30", "20:45",
    "21:00", "21:15",
    "21:30", "21:45",
    "22:00", "22:15",
    "22:30", "22:45",
    { value: "23:00" }, { value: "23:15", label: "23:15pm" },
    { value: "23:30", label: "23:30pm" }, { value: "23:45", label: "23:45pm" }]

export const hoursInitial = [
    { value: "00:00", label: "00:00am", id: 1, show: true },
    { value: "00:30", label: "00:30am", id: 2, show: true },
    { value: "01:00", label: "01:00am", id: 3, show: true },
    { value: "01:30", label: "01:30am", id: 4, show: true },
    { value: "02:00", label: "02:00am", id: 5, show: true },
    { value: "02:30", label: "02:30am", id: 6, show: true },
    { value: "03:00", label: "03:00am", id: 7, show: true },
    { value: "03:30", label: "03:30am", id: 8, show: true },
    { value: "04:00", label: "04:00am", id: 9, show: true },
    { value: "04:30", label: "04:30am", id: 10, show: true },
    { value: "05:00", label: "05:00am", id: 11, show: true },
    { value: "05:30", label: "05:30am", id: 12, show: true },
    { value: "06:00", label: "06:00am", id: 13, show: true },
    { value: "06:30", label: "06:30am", id: 14, show: true },
    { value: "07:00", label: "07:00am", id: 15, show: true },
    { value: "07:30", label: "07:30am", id: 16, show: true },
    { value: "08:00", label: "08:00am", id: 17, show: true },
    { value: "08:30", label: "08:30am", id: 18, show: true },
    { value: "09:00", label: "09:00am", id: 19, show: true },
    { value: "09:30", label: "09:30am", id: 20, show: true },
    { value: "10:00", label: "10:00am", id: 21, show: true },
    { value: "10:30", label: "10:30am", id: 22, show: true },
    { value: "11:00", label: "11:00am", id: 23, show: true },
    { value: "11:30", label: "11:30am", id: 24, show: true },
    { value: "12:00", label: "12:00pm", id: 25, show: true },
    { value: "12:30", label: "12:30pm", id: 26, show: true },
    { value: "13:00", label: "13:00pm", id: 27, show: true },
    { value: "13:30", label: "13:30pm", id: 28, show: true },
    { value: "14:00", label: "14:00pm", id: 29, show: true },
    { value: "14:30", label: "14:30pm", id: 30, show: true },
    { value: "15:00", label: "15:00pm", id: 31, show: true },
    { value: "15:30", label: "15:30pm", id: 32, show: true },
    { value: "16:00", label: "16:00pm", id: 33, show: true },
    { value: "16:30", label: "16:30pm", id: 34, show: true },
    { value: "17:00", label: "17:00pm", id: 35, show: true },
    { value: "17:30", label: "17:30pm", id: 36, show: true },
    { value: "18:00", label: "18:00pm", id: 37, show: true },
    { value: "18:30", label: "18:30pm", id: 38, show: true },
    { value: "19:00", label: "19:00pm", id: 39, show: true },
    { value: "19:30", label: "19:30pm", id: 40, show: true },
    { value: "20:00", label: "20:00pm", id: 41, show: true },
    { value: "20:30", label: "20:30pm", id: 42, show: true },
    { value: "21:00", label: "21:00pm", id: 43, show: true },
    { value: "21:30", label: "21:30pm", id: 44, show: true },
    { value: "22:00", label: "22:00pm", id: 45, show: true },
    { value: "22:30", label: "22:30pm", id: 46, show: true },
    { value: "23:00", label: "23:00pm", id: 47, show: true },
    { value: "23:30", label: "23:30pm", id: 48, show: true },
    { value: "23:59", label: "23:59pm", id: 49, show: true },
];
