import { atom } from "jotai"

export const helpOpen = atom(false)

export const sidebarNavigation = atom(false)

export const sidebarHome = atom(false)

export const stepsStart = atom("1")

export const chosenSubject = atom("")
export const levelAsignature = atom("")

export const configSetting = atom("")