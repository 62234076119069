import { LogOut, MessageSquare, Mic, Share, Video } from "lucide-react";
//@ts-ignore
import KidStudying from "src/assets/images/kid-studying.jpg";
//@ts-ignore

import TeacherTeaching from "src/assets/images/teacher-teaching.jpg";

const SidebarLivecall = () => {
  return (
    <div className="h-screen overflow-hidden flex flex-col items-center">
      <div className="flex flex-col p-6 space-y-10">
        <div className="h-[250px] w-[250px] overflow-hidden rounded-2xl">
          <img
            src={KidStudying}
            className="h-full w-full object-cover "
            alt="kid-studying"
          />
        </div>
        <div className="h-[250px] w-[250px] overflow-hidden rounded-2xl">
          <img
            src={TeacherTeaching}
            className="h-full w-full object-cover "
            alt="kid-studying"
          />
        </div>
        <div className="w-[180px] grid grid-cols-3 gap-4">
          <div className="flex flex-col items-center">
            <div className="h-12 w-12 rounded bg-input flex items-center justify-center">
              <Video />
            </div>
            <p className="text-sm font-semibold">Cam</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="h-12 w-12 rounded bg-input flex items-center justify-center">
              <Mic />
            </div>
            <p className="text-sm font-semibold">Mic</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="h-12 w-12 rounded bg-input flex items-center justify-center">
              <Share />
            </div>
            <p className="text-sm font-semibold">Share</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="h-12 w-12 rounded bg-input flex items-center justify-center">
              <MessageSquare />
            </div>
            <p className="text-sm font-semibold"> Chat</p>
          </div>
          <div className="flex flex-col items-centerr ">
            <div className="h-12 w-12 rounded bg-input flex items-center justify-center">
              <LogOut />
            </div>
            <p className="text-sm font-semibold">Leave</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarLivecall;
