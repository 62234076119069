import { useState } from "react";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { Coffee } from "lucide-react";
import { cn } from "../lib/utils";

const AboutMe = () => {
  const navigate = useNavigate();
  const [aboutMeInput, setAboutMeInput] = useState("");

  const handleDesc = (e: string) => {
    setAboutMeInput(e);
  };
  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-96 bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333] font-medium">
              Inspire confidence and show your students that you are serious and
              professional.
            </p>
            <br />
            <p>
              This is one of the first things students will read about you. Take
              the time to create an attractive ad where your prospective
              students will see how you work.
            </p>
            <br />
            <div className="flex items-center my-6">
              <Coffee />
              <p className="text-[16px] font-semibold ml-2 uppercase">
                {" "}
                Remember Ismael
              </p>
            </div>{" "}
            <div>
              <p className="text-[#333] font-medium">
                Your contact details or URLs should not appear in the text
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-[#333] font-bold text-5xl">
                About <span className="text-orange">you</span>
              </p>
              <p>(40 words minimum)</p>
            </div>
            <div className="h-[600px] w-full">
              <textarea
                value={aboutMeInput}
                onChange={(e) => handleDesc(e.target.value)}
                cols={5}
                className="bg-[#f8f8f8] h-80 w-full decoration-none outline-none px-6 py-4 transition duration-200 ease-in-out focus:ring-2 rounded-lg focus:ring-orange font-paragraph"
                placeholder="Tell your future students who you are and why you want to share your passion."
              />
            </div>
            <div className=" flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-2")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full "
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-4")}
                className={cn(
                  "px-16 py-4 rounded-full flex items-center justify-center space-x-2",
                  aboutMeInput.trim().split(" ").length >= 40
                    ? "bg-orange text-white"
                    : "bg-gray-200 text-[#333] "
                )}
              >
                {aboutMeInput.trim().split("").length >= 40 && (
                  <p className=" font-medium">Next</p>
                )}
                {aboutMeInput.trim().split(" ").length < 40 && (
                  <p>{aboutMeInput.trim().split(" ").length} / 40</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
