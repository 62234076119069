import { Backpack, ChevronDown, Plus } from "lucide-react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { Progress } from "../ui/progress";

const formSchema = z.object({
  titleClass: z.string().min(1),
  time: z.string().min(1),
  date: z.string().min(1),
  description: z.string().min(1),
});

const ScheduleClass = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      titleClass: "",
      time: "",
      date: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
  }
  return (
    <div className="h-screen overflow-hidden">
      <div className="p-6 flex items-center justify-center">
        <div className="flex items-center gap-2">
          <p className="text-xl font-semibold">Schedule Class</p>
          <ChevronDown className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col  p-6 m-6 mt-3 bg-white rounded-lg border-border border">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="titleClass"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-400">Add name</FormLabel>
                  <FormControl>
                    <Input
                      className="text-lg"
                      placeholder="Biology Class 1"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    This is your public title class
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="time"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-400">Time</FormLabel>
                  <FormControl>
                    <Input
                      className="text-lg"
                      placeholder="11:30 AM"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    This is your public class time
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-400">Date</FormLabel>
                  <FormControl>
                    <Input
                      className="text-lg"
                      placeholder="13 Sept 2023"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    This is your public class date
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-4">
              <div className="h-8 w-8 bg-input rounded-full flex items-center justify-center cursor-pointer">
                <Plus className="h-5 w-5" />
              </div>
              <p className="text-lg">Add Participants</p>
            </div>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      className="text-lg"
                      placeholder="Type description here"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full bg-green-500" type="submit">
              ADD
            </Button>
          </form>
        </Form>
      </div>
      <div className="m-6 p-6 border border-border rounded-lg bg-white">
        <div className="flex flex-col items-start space-y-8 ">
          <div className="flex items-center justify-between w-full">
            <p className="text-xl font-semibold">Academic Progress</p>
            <p className="text-green-500 font-medium text-sm">View All</p>
          </div>
          <div className="flex items-center gap-4 w-full">
            <div className="h-10 w-10 flex items-center justify-center bg-input rounded-full">
              <Backpack />
            </div>
            <div className="flex flex-col items-start flex-grow space-y-1">
              <h1>Module 1</h1>
              <Progress value={100} />
              <p className="text-xs text-gray-400">Completed</p>
            </div>
          </div>

          <div className="flex items-center gap-4 w-full">
            <div className="h-10 w-10 flex items-center justify-center bg-input rounded-full">
              <Backpack />
            </div>
            <div className="flex flex-col items-start flex-grow space-y-1">
              <h1>Module 2</h1>
              <Progress value={20} color="" />
              <p className="text-xs text-gray-400">40/42 pages</p>
            </div>
          </div>
          <div className="flex items-center gap-4 w-full">
            <div className="h-10 w-10 flex items-center justify-center bg-input rounded-full">
              <Backpack />
            </div>
            <div className="flex flex-col items-start flex-grow space-y-1">
              <h1>Module 3</h1>
              <Progress value={0} />
              <p className="text-xs text-gray-400">Yet to start</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleClass;
