//@ts-ignore
import { io } from "socket.io-client";
import Peer from "simple-peer";

//@ts-ignore
import KidStudying from "src/assets/images/kid-studying.jpg";
//@ts-ignore
import TeacherTeaching from "src/assets/images/teacher-teaching.jpg";
import React, { useEffect, useRef, useState } from "react";

// const socket = io("http://192.168.1.42:5000");

const MainLiveCall = () => {
  const [me, setMe] = useState("");
  const [stream, setStream] = useState<any>();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState<any>();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");

  const userVideo = useRef<any>(null);
  const connectionRef = useRef<any>(null);
  const myVideo = useRef<any>(null);

  // useEffect(() => {
  //   navigator.mediaDevices
  //     ?.getUserMedia({ video: true, audio: true })
  //     .then((stream) => {
  //       setStream(stream);
  //       if (myVideo.current) {
  //         myVideo.current.srcObject = stream;
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing media devices: ", error);
  //     });

  //   socket.on("me", (id) => {
  //     setMe(id);
  //   });

  //   socket.on("callUser", (data) => {
  //     setReceivingCall(true);
  //     setCaller(data.from);
  //     setName(data.name);
  //     setCallerSignal(data.signal);
  //   });
  // }, []);

  // const callUser = (id: any) => {
  //   const peer = new Peer({
  //     initiator: true,
  //     trickle: false,
  //     stream: stream,
  //   });

  //   peer.on("signal", (data: any) => {
  //     socket.emit("callUser", {
  //       userToCall: id,
  //       signalData: data,
  //       from: me,
  //       name: name,
  //     });
  //   });

  //   peer.on("stream", (stream: any) => {
  //     userVideo.current.srcObject = stream;
  //   });

  //   socket.on("callAccepted", (signal) => {
  //     setCallAccepted(true);
  //     peer.signal(signal);
  //   });

  //   connectionRef.current = peer;
  // };

  // const answerCall = () => {
  //   setCallAccepted(true);
  //   const peer = new Peer({
  //     initiator: false,
  //     trickle: false,
  //     stream: stream,
  //   });

  //   peer.on("signal", (data: any) => {
  //     socket.emit("answerCall", { signal: data, to: caller });
  //   });

  //   peer.on("stream", (stream: any) => {
  //     userVideo.current.srcObject = stream;
  //   });

  //   peer.signal(callerSignal);
  //   connectionRef.current = peer;
  // };

  const leaveCall = () => {
    setCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
  };

  return (
    <div>
      <div className="flex flex-row w-full justify-center gap-[15%] h-screen ">
        <div>
          <div className="flex-grow flex flex-col items-center justify-center h-[90%]">
            <span className="text-black font-bold text-3xl mb-4">
              Basic React JS video calling
            </span>
            <span className="text-black font-bold text-md mb-4 text-center underline">
              Copy your ID and anyone using the same server can use it to call
              you and vice versa!
            </span>
            <div className="flex flex-row gap-32">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="h-[300px] ">
                  {stream && (
                    <video
                      className="rounded-full"
                      playsInline
                      muted
                      ref={myVideo}
                      autoPlay
                      style={{ width: "300px" }}
                    />
                  )}
                </div>
                <span className="text-black font-bold text-lg mb-4">
                  {caller}
                </span>
                <p className="text-black">{me}</p>
              </div>

              <div className="flex flex-col items-center justify-center w-full">
                {callAccepted && !callEnded ? (
                  <video
                    className="rounded-full"
                    playsInline
                    ref={userVideo}
                    autoPlay
                    style={{ width: "300px" }}
                  />
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={TeacherTeaching}
                      className="rounded-full w-[15rem]"
                      alt="User Avatar"
                    />
                    <span className="text-black font-bold text-lg">
                      {idToCall}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <textarea
              className="text-black border border-black"
              value={idToCall}
              onChange={(e) => {
                setIdToCall(e.target.value);
              }}
            />
            <div>
              {callAccepted && !callEnded ? (
                <button
                  className="text-black hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
                  onClick={leaveCall}
                >
                  End Call
                </button>
              ) : (
                <button
                  className="text-black hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
                  // onClick={() => callUser(idToCall)}
                >
                  Call
                </button>
              )}
            </div>
            <div className="text-black">
              {receivingCall && !callAccepted ? (
                <div className="caller flex flex-col">
                  <h1 className="text-black">{caller} is calling...</h1>
                  <button
                    className="text-black text-xl hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
                    // onClick={answerCall}
                  >
                    Answer
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="h-screen overflow-hidden w-screen flex flex-col items-start">
    //   <div className="flex flex-col p-6 ">
    //     <div className="w-full overflow-hidden h-[700px] relative rounded-2xl">
    //       <div className="h-[700px] z-10 w-full">
    //         {stream && (
    //           <video
    //             className="rounded-full"
    //             playsInline
    //             muted
    //             ref={myVideo}
    //             autoPlay
    //             style={{ width: "300px" }}
    //           />
    //         )}
    //         <span className="text-black font-bold text-lg mb-4">{caller}</span>
    //         <p
    //           className="text-black
    //         "
    //         >
    //           {me}
    //         </p>
    //       </div>
    //       {/* <img
    //         src={TeacherTeaching}
    //         className="w-full h-[700px] h-full -z-10"
    //       /> */}
    //       <div className="flex flex-col items-center justify-center w-full">
    //         {callAccepted && !callEnded ? (
    //           <video
    //             className="rounded-full"
    //             playsInline
    //             ref={userVideo}
    //             autoPlay
    //             style={{ width: "300px" }}
    //           />
    //         ) : (
    //           <img
    //             alt="kid-studying"
    //             src={KidStudying}
    //             className="w-[200px] h-[200px] object-cover rounrded-2xl z-20 absolute top-10 right-10"
    //           />
    //         )}
    //       </div>
    //       <textarea
    //         className=""
    //         defaultValue={idToCall}
    //         onChange={(e) => {
    //           setIdToCall(e.target.value);
    //         }}
    //       />
    //       <div className="flex flex-col items-center justify-center w-full">
    //         {callAccepted && !callEnded ? (
    //           <button
    //             className=" hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
    //             onClick={leaveCall}
    //           >
    //             End Call
    //           </button>
    //         ) : (
    //           <button
    //             className=" hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
    //             onClick={() => callUser(idToCall)}
    //           >
    //             Call
    //           </button>
    //         )}
    //       </div>
    //       <div className="">
    //         {receivingCall && !callAccepted ? (
    //           <div className="caller flex flex-col">
    //             <h1 className="">{caller} is calling...</h1>
    //             <button
    //               className=" text-xl hover:text-gray-400 mr-6 font-bold bg-white rounded-md m-4 px-2"
    //               onClick={answerCall}
    //             >
    //               Answer
    //             </button>
    //           </div>
    //         ) : null}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MainLiveCall;
