import HeaderDashboard from "../components/HeaderDashboard";
import ConversationChat from "../components/chat/conversation-chat";
import SidebarChats from "../components/chat/sidebar-chats";
import Footer from "../components/footer/Footer";
import LeftSidebar from "../components/sidebar-left";

const Chat = () => {
  return (
    <div className=" h-screen w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <main className=" max-w-7xl mx-auto w-full pt-28">
        <div className="grid grid-cols-7">
          <div className="col-span-2">
            <SidebarChats />
          </div>
          <div className="col-span-5">
            <ConversationChat />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Chat;
