import MainPayments from "../components/payments/main-payments";
import LeftSidebar from "../components/sidebar-left";

const Payments = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div className="">
          <div className="p-6 flex items-center border border-border">
            <p className="text-xl font-semibold">Invoices</p>
          </div>
          <div>
            <MainPayments />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Payments;
