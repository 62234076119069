import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import { ThemeProvider } from "./components/theme-provider";
import Chat from "./pages/Chat";
import Classes from "./pages/Classes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Help from "./pages/Help";
import Teachers from "./pages/Teachers";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TeacherDashboard from "./pages/TeacherDashboard";
import Live from "./pages/Live";
import Profile from "./pages/Profile";
import Payments from "./pages/Payments";
import Materials from "./pages/Materials";
import Billing from "./pages/Billing";
import Subscriptions from "./pages/Subscription";
import LiveCall from "./pages/LiveCall";
import Cookies from "./components/cookies";
import FindYourTeacher from "./pages/FindYourTeacher";
import FindYourGroupClass from "./pages/FindYourGroupClass";
import WhatDoYouTeach from "./pages/WhatDoYouTeachCreation";
import Headline from "./pages/Headline";
import AboutClasses from "./pages/AboutClasses";
import AboutMe from "./pages/AboutMe";
import Location from "./pages/Location";
import SpokenLanguages from "./pages/SpokenLanguages";
import Budget from "./pages/Budget";
import PhoneNumber from "./pages/PhoneNumber";
import ProfilePhoto from "./pages/ProfilePhoto";
import SubmitProfile from "./pages/SubmitProfile";
import LoadingScreen from "./pages/LoadingScreen";
import Wallet from "./pages/Wallet";
import TeacherSelected from "./pages/TeacherSelected";
import FindTeacherSigned from "./pages/FindTeachersSigned";
import FindGroupClassSigned from "./pages/FindGroupClassSigned";
import Community from "./pages/Community";
import Payment from "./pages/Payment";
import Referral from "./pages/Referral";
import Support from "./pages/Support";
import ConfigAccount from "./pages/settings/ConfigAccount";
import ConfigPrivacy from "./pages/settings/ConfigPrivacy";
import ConfigNotification from "./pages/settings/ConfigNotification";
import ConfigPayment from "./pages/settings/ConfigPayment";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route index path="/" element={<Home />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="conversations" element={<Chat />} />
      <Route path="classes" element={<Classes />} />
      <Route path="help" element={<Help />} />
      <Route path="teachers" element={<Teachers />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="schedule" element={<TeacherDashboard />} />
      <Route path="live" element={<Live />} />
      <Route path="profile" element={<Profile />} />
      <Route path="payments" element={<Payments />} />
      <Route path="materials" element={<Materials />} />
      <Route path="billing" element={<Billing />} />
      <Route path="subscriptions" element={<Subscriptions />} />
      <Route path="livecall" element={<LiveCall />} />
      <Route path="teachers/:subject" element={<FindYourTeacher />} />
      <Route path="group-class/:subject" element={<FindYourGroupClass />} />
      <Route path="creation-profile/step-0" element={<WhatDoYouTeach />} />
      <Route path="creation-profile/step-1" element={<Headline />} />
      <Route path="creation-profile/step-2" element={<AboutClasses />} />
      <Route path="creation-profile/step-3" element={<AboutMe />} />
      <Route path="creation-profile/step-4" element={<Location />} />
      <Route path="creation-profile/step-5" element={<SpokenLanguages />} />
      <Route path="creation-profile/step-6" element={<Budget />} />
      <Route path="creation-profile/step-7" element={<PhoneNumber />} />
      <Route path="creation-profile/step-8" element={<ProfilePhoto />} />
      <Route path="creation-profile/step-9" element={<SubmitProfile />} />
      <Route path="loading" element={<LoadingScreen />} />
      <Route path="finance/overview" element={<Wallet />} />
      <Route path="/selected/teachers" element={<TeacherSelected />} />
      <Route path="/teacher/:subject" element={<FindTeacherSigned />} />
      <Route
        path="/group-classes/:subject"
        element={<FindGroupClassSigned />}
      />
      <Route path="/community" element={<Community />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/referral" element={<Referral />} />
      <Route path="/support" element={<Support />} />
      <Route path="/configuration/account" element={<ConfigAccount />} />
      <Route path="/configuration/privacy" element={<ConfigPrivacy />} />
      <Route
        path="/configuration/notification"
        element={<ConfigNotification />}
      />
      <Route path="/configuration/payment" element={<ConfigPayment />} />
    </Route>
  )
);

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light" storageKey="ui-theme">
        <Cookies />
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
