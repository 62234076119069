import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from "@fullcalendar/core";
import { useEffect, useState } from "react";
import { INITIAL_EVENTS, createEventId } from "src/lib/events";

const TimeTable = () => {
  const [weekendsVisible, setWeekendsVisible] = useState(false);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);

  const handleEvents = (events: EventApi[]) => {
    setCurrentEvents(events);
  };

  useEffect(() => {
    console.log("currentEvents", currentEvents);
  }, [currentEvents]);

  const handleEventClick = (clickInfo: EventClickArg) => {
    clickInfo.event.remove();
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <b>{eventContent.event.title}</b>
      </>
    );
  }

  //   function renderSidebarEvent(event: EventApi) {
  //     return (
  //       <li key={event.id}>
  //         <b>
  //           {formatDate(event.start!, {
  //             year: "numeric",
  //             month: "short",
  //             day: "numeric",
  //           })}
  //         </b>
  //         <i>{event.title}</i>
  //       </li>
  //     );
  //   }
  return (
    <div className="p-6 font-paragraph text-sm bg-white rounded-lg border border-border">
      <FullCalendar
        height={700}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
      />
    </div>
  );
};

export default TimeTable;
