export const months = [
    {
        name: "ene.",
        value: "01",
    },
    {
        name: "feb.",
        value: "02",
    },
    {
        name: "mar.",
        value: "03",
    },
    {
        name: "apr.",
        value: "04",
    },
    {
        name: "may.",
        value: "05",
    },
    {
        name: "jun.",
        value: "06",
    },
    {
        name: "jul.",
        value: "07",
    },
    {
        name: "aug.",
        value: "08",
    },
    {
        name: "sep.",
        value: "09",
    },
    {
        name: "oct.",
        value: "10",
    },
    {
        name: "nov.",
        value: "11",
    },
    {
        name: "dec.",
        value: "12",
    },
];