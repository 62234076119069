import MainBilling from "../components/billing/main-billing";
import LeftSidebar from "../components/sidebar-left";

const Billing = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div className="">
          <div className="p-6 flex items-center border border-border">
            <p className="text-xl font-semibold">Billing</p>
          </div>
          <div>
            <MainBilling />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Billing;
