import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import {
  Briefcase,
  Check,
  Home,
  MapPin,
  PlusSquare,
  Video,
} from "lucide-react";
import { useEffect, useState } from "react";
import { cn } from "../lib/utils";

const typeLocation = [
  {
    title: "At home",
    id: 0,
  },
  {
    title: "You can move",
    id: 1,
  },
  {
    title: "Online",
    id: 2,
  },
];

const Location = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState([
    { title: "At home", id: 0 },
  ]);
  const [locationInput, setLocationInput] = useState("");

  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-[500px] bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333] font-medium">
              Your address will never appear on the website. It will only be
              communicated to the students you agree to teach.
            </p>
            <br />
            <div className="flex flex-col items-start space-y-8">
              <div className="flex items-start space-x-4">
                <div className="bg-orange h-10 w-10 rounded-lg flex items-center justify-center">
                  <Home className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm font-medium w-[80%] text-[#333]">
                  You can propose your classes at your home at the address given
                  below{" "}
                </p>
              </div>
              <div className="flex items-start space-x-4 mt-4">
                <div className="bg-orange h-10 w-10 rounded-lg flex items-center justify-center">
                  <Briefcase className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm font-medium w-[80%] text-[#333]">
                  Maximum distance you are willing to travel at home from your
                  student's home or other agreed-upon location
                </p>
              </div>
              <div className="flex items-start space-x-4 mt-4">
                <div className="bg-orange h-10 w-10 rounded-lg flex items-center justify-center">
                  <Video className="h-6 w-6 text-white" />
                </div>
                <p className="text-sm font-medium w-[80%] text-[#333]">
                  Let there be no frontiers, give lessons all over the world via
                  webcam
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-5xl font-bold text-[#333]">
                <span className="text-orange">Location</span> of classes
              </p>
            </div>
            <div className="h-[600px] w-full">
              <div className="flex items-center p-3 rounded-full bg-gray-100">
                <MapPin className="h-5 w-5" />
                <input
                  className="bg-transparent px-3 font-paragraph flex-grow outline-none"
                  placeholder={`Indicate the location of your classes`}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <p className="text-xl font-semibold text-[#333] py-10">
                  Where can your classes take place?
                </p>
                {typeLocation.map((location) => (
                  <div
                    onClick={() => {
                      if (
                        selectedType.find((item) => item.id === location.id)
                      ) {
                        setSelectedType(
                          selectedType.filter((item) => item.id !== location.id)
                        );
                      } else {
                        setSelectedType((prev) => [...prev, location]);
                      }
                    }}
                    className={cn(
                      "flex items-center  justify-between py-4 px-4 my-2 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer",
                      selectedType.find((item) => item.id === location.id)
                        ? "bg-orange text-white"
                        : "bg-[#f8f8f8] text-[#333]"
                    )}
                  >
                    <p className="text-sm font-medium ">{location.title}</p>
                    {selectedType.find((item) => item.id === location.id) ? (
                      <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                        <Check className="h-4 w-4 text-orange" />
                      </div>
                    ) : (
                      <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                        <PlusSquare className="h-5 w-5 " />
                      </div>
                    )}
                  </div>
                ))}

                {/* <div className="flex items-center bg-orange justify-between py-4 px-4 my-2 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer">
                <p className="text-sm font-medium text-white">You can move</p>
                <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                  <Check className="h-4 w-4 text-orange" />
                </div>
              </div>
              <div className="flex items-center bg-orange justify-between py-4 px-4 my-2 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer">
                <p className="text-sm font-medium text-white">Online</p>
                <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                  <Check className="h-4 w-4 text-orange" />
                </div>
              </div> */}
              </div>
            </div>

            <div className="flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-3")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full cursor-pointer"
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-5")}
                className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange text-white"
              >
                <p className="font-medium">Next</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
