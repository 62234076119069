import {
  BarChart,
  Check,
  ChevronDown,
  Globe2,
  Search,
  Clock,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { daysWeek, levelsLanguage, timeRange } from "../data/courses";
import { Checkbox } from "./ui/checkbox";

const FiltersGroupClasses = () => {
  const dateTimeObject = new Date();
  return (
    <div className="flex items-center justify-between px-10">
      <div className="flex items-center space-x-6  my-4 px-10">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2  flex items-center space-x-2 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <Globe2 className="h-4 w-4" />
              <p className="text-[#333] font-semibold text-sm">English</p>
              <ChevronDown className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="px-4 py-2 flex items-center justify-between w-60 hover:bg-gray-200 cursor-pointer">
              <p className="text-sm font-medium">English</p>
              <Check className="text-[#333]" />
            </div>
            <div className="px-4 py-2 flex items-center justify-between w-60 hover:bg-gray-200 cursor-pointer">
              <p className="text-sm font-medium">Spanish</p>
            </div>
            <div className="px-4 py-2 flex items-center justify-between w-60 hover:bg-gray-200 cursor-pointer">
              <p className="text-sm font-medium">Japanese</p>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2 flex items-center space-x-2 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <BarChart className="h-4 w-4" />
              <p className="text-sm font-medium">All levels</p>
              <ChevronDown className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="p-4 w-96">
              {levelsLanguage.map((level) => (
                <div className="flex items-start space-x-2 py-2">
                  <Checkbox className="mt-[2px]" />
                  <div className="flex flex-col items-start">
                    <p className="text-[#333] text-sm pb-2 font-medium">
                      {level.title}
                    </p>
                    <p className="text-xs text-gray-400">{level.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="px-4 py-2  flex items-center space-x-2 cursor-pointer hover:bg-gray-200 transition duration-200 ease-in-out rounded-full bg-gray-100">
              <Clock className="h-4 w-4" />
              <p className="text-[#333] font-medium text-sm">Any time</p>
              <ChevronDown className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <div className="px-4 py-2 flex flex-col w-96">
              <p className="font-medium text-[#333] text-sm pb-4">
                Days of the week
              </p>
              <div className="flex flex-wrap items-center gap-4">
                {daysWeek.map((day) => (
                  <div className="bg-gray-100 rounded-full px-6 py-2 hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out">
                    <p className="text-xs">{day.val}</p>
                  </div>
                ))}
              </div>
              <p className="font-medium text-[#333] text-sm pb-4 mt-4">
                Time of the day
              </p>
              <div className="flex flex-wrap items-center gap-4">
                {timeRange.map((time) => (
                  <div className="bg-gray-100 rounded-full px-4 py-2 hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out">
                    <p className="text-xs">
                      {time.hourFrom} - {time.hourTo}
                    </p>
                  </div>
                ))}
              </div>
              <p className="text-xs text-gray-500 pt-4">
                Time is listed in your local time zone:{" "}
                {dateTimeObject.toTimeString()}
                {/* {moment().tz(timezone).format()} */}
              </p>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="px-10 bg-white">
        <div className="flex items-center bg-gray-100 py-2 px-4 rounded-full w-80">
          <Search className="h-7 w-7" />
          <input
            className="px-4 outline-none font-paragraph bg-transparent flex-grow text-sm"
            placeholder="Search topic/teachers"
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersGroupClasses;
