import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { useState } from "react";
import { cn } from "../lib/utils";

const Headline = () => {
  const navigate = useNavigate();
  const [headlineInput, setHeadlineInput] = useState("");

  const handleTitle = (e: string) => {
    setHeadlineInput(e);
  };

  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-96 bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333] text-sm font-medium">
              The title is a key element in the ad! Think about it, it must be
              unique, catchy and contain at least 12 words:
              <br />
              <br />
              <ul className="list-inside list-disc space-y-2">
                <li>The subjects you teach</li>
                <li>Titles, work method, etc.</li>

                <li>
                  The peculiarities and everything that differentiates you.
                </li>
              </ul>
            </p>
            <div className="flex items-center my-6">
              <ThumbsUp className="h-5 w-5" />
              <p className="uppercase text-lg font-semibold ml-2">This works</p>
            </div>
            <div>
              <p>
                <ul className="list-inside list-disc space-y-2">
                  <li className="text-sm text-[#333] font-medium">
                    "Engineering student teaches mathematics and physics at ESO
                    and Bachillerato level in Vigo".
                  </li>
                  <li className="text-sm text-[#333] font-medium">
                    "Pianist with 15 years of experience gives piano and
                    solfeggio lessons at home."
                  </li>
                </ul>
              </p>
            </div>
            <div className="flex items-center my-6">
              <ThumbsDown className="h-5 w-5" />
              <p className="uppercase text-lg font-semibold ml-2">
                This does not work
              </p>
            </div>
            <div>
              <p className="text-sm text-[#333] font-medium">
                <ul className="list-inside list-disc">
                  <li>"I give singing lessons at 25€/hour"</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-[#333] font-bold text-5xl">
                <span className="text-orange">Title</span> of an advertisement
              </p>
              <p>(12 words minimum)</p>
            </div>
            <div className="h-[600px] w-full">
              <textarea
                onChange={(e) => handleTitle(e.target.value)}
                cols={5}
                className="bg-[#f8f8f8] h-40 w-full decoration-none outline-none px-6 py-4 transition duration-200 ease-in-out focus:ring-2 rounded-lg focus:ring-orange font-paragraph"
                placeholder="Ex: Conservatory graduate and artist teaches singing and guitar for all levels, personal and entertaining methodology!"
              />
            </div>
            <div className=" flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-0")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full "
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-2")}
                className={cn(
                  "px-16 py-4 rounded-full flex items-center justify-center space-x-2",
                  headlineInput.trim().split(" ").length >= 12
                    ? "bg-orange text-white"
                    : "bg-gray-200 text-[#333] "
                )}
              >
                {headlineInput.trim().length >= 12 && (
                  <p className=" font-medium">Next</p>
                )}
                {headlineInput.trim().split(" ").length < 12 && (
                  <p>{headlineInput.trim().split(" ").length} / 12</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headline;
