import {
  Bell,
  Grip,
  MessageCircle,
  Pin,
  Plus,
  Quote,
  Search,
  X,
} from "lucide-react";
import { Input } from "../ui/input";
import RatingStars from "../rating-stars";

//@ts-ignore
import CristinaImage from "src/assets/images/people2.jpg";

export const teachersArray = [
  {
    img: CristinaImage,
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
  {
    fullName: "Elizabeth Washington",
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    location: "Denver, CO",
    reviews: "24",
    rating: "4.5",
    detailAspects: ["$45/hr", "biology", "chemistry"],
  },
];

const MainTeachers = () => {
  return (
    <div>
      <div className="p-6 flex items-center border border-border">
        <Search className="h-5 w-5 mr-4" />
        <Input
          className="border-transparent focus-visible:ring-0 focus-visible:ring-transparent"
          placeholder="Search for teachers"
        />
        <div className="flex items-center space-x-3">
          <div className="relative">
            <MessageCircle className="h-5 w-5" />
            <div className="bg-red-600 h-2 w-2 rounded-full absolute -top-1 right-0" />
          </div>
          <div className="relative">
            <Bell className="h-5 w-5" />
            <div className="bg-red-600 h-2 w-2 rounded-full absolute -top-1 right-0" />
          </div>
          <Grip className="h-5 w-5" />
        </div>
      </div>

      <div className="flex items-center justify-between p-6 my-4">
        <div className="flex flex-wrap  items-center space-x-4   ">
          <div className="bg-input px-4 py-2 flex items-center  justify-between">
            <p className="text-sm mr-2">Subject is 'Biology'</p>
            <X className="h-5 w-5 text-gray-400" />
          </div>
          <div className="bg-input px-4 py-2 flex items-center  justify-between">
            <p className="text-sm mr-2">Online</p>
            <X className="h-5 w-5 text-gray-400" />
          </div>
          <div className="bg-input px-4 py-2 flex items-center  justify-between">
            <p className="text-sm mr-2">Pay is &lt; $70/hr</p>
            <X className="h-5 w-5 text-gray-400" />
          </div>
        </div>
        <div className="flex items-center text-blue-400">
          <Plus />
          <p>Add Filter</p>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2  gap-4 m-6 overflow-y-auto h-[80vh]">
        {teachersArray.map((teacher) => (
          <div className="relative flex items-center gap-4 border border-border rounded-2xl ">
            <img
              src={require("../../assets/images/people2.jpg")}
              alt="people-1"
              className="w-40 h-full object-cover"
            />
            <div className="flex flex-col items-start space-y-4 py-4 px-6">
              <div className="flex items-center space-x-2">
                <p className="text-xl font-semibold">{teacher.fullName}</p>
                <RatingStars activeStar={teacher.rating} />
                <p className="text-gray-400 text-sm">
                  {teacher.reviews} Reviews
                </p>
              </div>
              <div className="flex items-start gap-4 text-gray-400">
                <Quote className="h-5 w-5" />
                <p className="text-sm">{teacher.description}</p>
              </div>
              <div className="flex items-center gap-4 text-gray-400">
                <Pin className="h-4 w-4" />
                <p className="text-sm">{teacher.location}</p>
              </div>
              <div className="flex flex-wrap items-center space-x-3">
                {teacher.detailAspects.map((detail) => (
                  <div className="px-6 py-2 bg-input rounded-full">
                    <p className="text-sm">{detail}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="h-8 w-8 bg-input absolute bottom-3 right-3 rounded-full items-center justify-center flex cursor-pointer">
              <Plus className="h-5 w-5" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainTeachers;
