import { useAtom } from "jotai";
import HeaderDashboard from "../HeaderDashboard";
import { configSetting } from "src/atoms/atoms";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import { cn } from "src/lib/utils";

const configNavigation = [
  { title: "General", ref: "/configuration/account" },
  { title: "Privacy", ref: "/configuration/privacy" },
  { title: "Notifications", ref: "/configuration/notification" },
  { title: "Payment", ref: "/configuration/payment" },
];

const ConfigLayout = ({ children }: { children: React.ReactNode }) => {
  const [configType, setConfigType] = useAtom(configSetting);
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="grid grid-cols-7 gap-6 pt-32 px-10 min-h-[calc(100%-370px)] max-w-7xl mx-auto">
        <div className="col-span-2">
          <div className="bg-white p-4 rounded-lg">
            {configNavigation.map((config) => (
              <div
                onClick={() => {
                  navigate(config.ref);
                  setConfigType(config.title);
                }}
                // onClick={() => setConfigType(config.title)}
                className={cn(
                  "px-4 py-4 hover:bg-[#f8f8f8] transition duration-200 ease-in-out cursor-pointer",
                  configType === config.title && "border-l-2 border-orange"
                )}
              >
                <p>{config.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-5">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfigLayout;
