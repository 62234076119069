import { useNavigate } from "react-router-dom";

//@ts-ignore
import People5 from "../assets/images/people5.jpg";

//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { Check, Facebook, Linkedin, Mail, MessageCircle } from "lucide-react";
import { useEffect, useState } from "react";

const SubmitProfile = () => {
  const [inputValue, setInputValue] = useState(
    "https://www.xtlessons.es/ir/24530874-9g23d9"
  );
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/loading");
    }, 3000);
  }, []);
  return (
    <div className="p-10">
      <Logo
        onClick={() => navigate("/")}
        className="h-36 object-cover cursor-pointer"
      />
      <div className="max-w-[700px] mx-auto pt-10">
        <div className="flex flex-col items-center space-y-4">
          <p className="text-2xl font-semibold">
            Congratulations, your ad is online!
          </p>
          <div className="flex items-center space-x-4 w-full justify-between shadow p-4 px-8 shadow-gray-100 rounded-2xl">
            <div className="flex items-center">
              <img
                src={People5}
                alt="people-5"
                className="h-20 w-20 rounded-full object-cover mr-4"
              />
              <div className="flex flex-col items-start">
                <p className="text-lg font-bold">Ismael</p>
                <p className="text-sm font-semibold">English</p>
              </div>
            </div>
            <div className="h-10 w-10 rounded-full bg-orange flex flex-col items-center justify-center">
              <Check className="h-7 w-7 text-white" />
            </div>
          </div>
          <div className="pt-4">
            <p className="text-center font-medium">
              Ask your relatives for{" "}
              <span className="font-semibold">recommendations</span> to improve
              your profile in front of the students and to be better positioned
              in the search engine.
            </p>
            <div className="flex items-center justify-center space-x-10 mt-6 shadow py-4 shadow-gray-100 rounded-2xl">
              <div className="flex flex-col items-center">
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-[#2a66bc]">
                  <Linkedin className="h-6 w-6 text-white " />
                </div>
                <p className="text-gray-500 text-xs font-semibold pt-2">
                  Linkedin
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-[#2a66bc]">
                  <Facebook className="w-6 h-6 text-white" />
                </div>
                <p className="text-gray-500 text-xs font-semibold pt-2">
                  Facebook
                </p>
              </div>
              {/* linear-gradient(225deg,#ec5e5d,#da49b2 32.67%,#615ff5 67.32%,#4099f7 */}
              <div className="flex flex-col items-center">
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-gradient-to-tr from-[#da49b2] via-[#615ff5] to-[#4099f7]">
                  <MessageCircle className="h-6 w-6 text-white" />
                </div>
                <p className="text-gray-500 text-xs font-semibold pt-2">
                  Messenger
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-[#ff6363]">
                  <Mail className="h-6 w-6 text-white" />
                </div>
                <p className="text-gray-500 text-xs font-semibold pt-2">Mail</p>
              </div>
            </div>
            <div className="py-6 px-2">
              <p className="text-sm font-medium text-center">
                Or directly by sharing this link
              </p>
            </div>
            <div className="p-4 flex items-center justify-between shadow-gray-50 shadow rounded-2xl">
              <input
                value={inputValue}
                className="flex-grow outline-none font-paragraph font-medium px-4"
              />

              <div className="bg-gray-200 rounded-2xl px-10 py-4">
                <p className="font-medium">Copiar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitProfile;
