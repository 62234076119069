import { BarChart2, ChevronDown, ExternalLink } from "lucide-react";
import { Input } from "./ui/input";
import { useNavigate } from "react-router-dom";

//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { useEffect, useState } from "react";
import { universitiesDegrees } from "../data/courses";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { useAtom } from "jotai";
import { sidebarHome } from "../atoms/atoms";
import { useModal } from "../hooks/use-modal-store";

interface UniversityDegreesProps {
  title: string;
  topSubjects: string[];
  topMajor: string[];
}

const Header = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState<UniversityDegreesProps[]>([]);
  const [openSidebar, setOpenSidebar] = useAtom(sidebarHome);
  const modal = useModal();
  useEffect(() => {
    console.log();
  }, [window]);

  useEffect(() => {
    setCourses(universitiesDegrees);
  }, []);
  return (
    <header className="py-4 flex items-center justify-between px-10 w-full border-b bg-white">
      <div
        onClick={() => navigate("/")}
        // className="font-bold font-sans text-xl tracking-wider text-[#333] cursor-pointer"
      >
        <Logo onClick={() => navigate("/")} className="h-16 object-cover" />
        {/* XtraLessons */}
      </div>
      {/* <main className="max-w-6xl mx-auto font-paragraph font-semibold flex items-center  justify-between"> */}
      {/* <div className="flex items-center gap-4">
        <button className="flex items-center space-x-4">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <p className="text-orange text-sm">Cursos</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {universitiesDegrees.slice(0, 10).map((degree) => (
                      <li key={degree.title}>
                        <p className="font-semibold pb-2 mb-2 border-b border-border">
                          {degree.title}
                        </p>

                        <div className="flex items-start justify-between px-4">
                          <div className="flex flex-col items-start">
                            <p className="font-medium text-sm pb-2">Degree</p>
                            {degree.topSubjects.map((subjec) => (
                              <div>
                                <p className="text-xs font-medium text-left">
                                  {subjec}
                                </p>
                              </div>
                            ))}
                          </div>
                          <div className="flex flex-col items-end">
                            <p className="font-medium text-sm pb-2">Major</p>
                            {degree.topMajor.map((major) => (
                              <div>
                                <p className="text-xs font-medium text-gray-400 text-right">
                                  {major}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </li>
                    ))}
                    <p className="text-sm font-medium text-blue-500 absolute bottom-4 right-4 hover:underline hover:underline-offset-2">
                      See more
                    </p>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </button>

        <Input
          placeholder="Buscar"
          className="text-orange bg-blue-50 hover:bg-blue-100 transition cursor-pointer ring-0 focus-visible:ring-0 focus-visible:ring-transparent border-0 focus:outline-none"
        />
      </div> */}
      <div className="flex items-center space-x-6  lg:hidden">
        <p className="text-[13px] text-orange">Clase en grupo</p>
        <div
          onClick={() => setOpenSidebar(!openSidebar)}
          className="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-sm hover:bg-gray-200 cursor-pointer transition duration-200 ease-out"
        >
          <BarChart2 className="rotate-90 h-5 w-5" />
        </div>
      </div>

      <div className=" items-center space-x-7 hidden lg:inline-flex">
        {window.location.pathname === "/" && (
          <button className="text-orange text-[13px] font-medium flex items-center gap-1">
            <p>Español · EUR €</p>
            <ChevronDown className="h-4 w-4" />
          </button>
        )}
        <button
          onClick={() => navigate("/teachers/english")}
          className="text-orange text-[13px] font-medium "
        >
          <p>Encontra un profesor</p>
        </button>
        <button
          onClick={() => navigate("/group-class/english")}
          className="text-orange text-[13px] font-medium "
        >
          <p>Clase en grupo</p>
        </button>
        {/* <button className="text-orange text-[13px] font-medium ">
          <p>Comunidad</p>
        </button> */}
        {window.location.pathname === "/" && (
          <button className="text-orange text-[13px] font-medium ">
            <p>Conviértete en profesor</p>
          </button>
        )}
        <button
          onClick={() => modal.onOpen("login")}
          // onClick={() => navigate("/dashboard")}
          className="text-orange text-[13px] font-medium"
        >
          <p>Inicia sesión</p>
        </button>
        <button
          onClick={() => navigate("/login")}
          className="text-orange text-[13px] font-medium"
        >
          <p>Regístrate</p>
        </button>
      </div>
      {/* </main> */}
    </header>
  );
};

export default Header;
