//@ts-ignore

import { ReactComponent as HalfStar } from "../assets/svgs/star-half-filled.svg";
//@ts-ignore

import { ReactComponent as FilledStar } from "../assets/svgs/star-filled.svg";

interface RatingStarsProps {
  activeStar: any;
  showHalf?: boolean;
  size?: string | number;
}

const RatingStars = ({ activeStar, showHalf, size }: RatingStarsProps) => {
  const totalStars = 5;

  return (
    <div>
      <div className="flex items-center text-left">
        {[...new Array(totalStars)].map((arr, index) => {
          return (
            <div className="relative r" key={index}>
              {Number.isInteger(activeStar) ||
              !showHalf ||
              activeStar?.slice(-1) === "0" ? (
                <>
                  <div
                    className={`${
                      index + 1 <= activeStar ? "w-[100%]" : "w-[0%]"
                    } overflow-hidden absolute`}
                  >
                    <FilledStar
                      className={`${!size ? "h-[15px]" : `h-[${size}px]`} ${
                        "fill-[#FF9900]"
                        //   isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                      }`}
                    />
                  </div>
                  <div>
                    <FilledStar
                      className={`${!size ? "h-[15px]" : `h-[${size}px]`}
                     ${
                       "fill-[#FF9900]"
                       // isClicked ? "fill-[#d9d9d9]" : "fill-[#d9d9d9]"
                     }
                    
                    `}
                      // <EmptyStar
                      //   className={`h-[15px] ${
                      //     isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                      //   }`}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`${
                      index + 1 <= activeStar ? "w-[100%]" : "w-[0%]"
                    } overflow-hidden absolute`}
                  >
                    <FilledStar
                      className={`${!size ? "h-[15px]" : `h-[${size}px]`} ${
                        "fill-[#FF9900]" //   isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                      }`}
                    />
                  </div>
                  <div
                    className={`${
                      index <= activeStar ? "w-[100%]" : "w-[0%]"
                    } overflow-hidden absolute`}
                  >
                    <HalfStar
                      className={`${!size ? "h-[15px]" : `h-[${size}px]`} ${
                        "fill-[#FF9900]"
                        //   isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                      }`}
                    />
                  </div>
                  <div>
                    <FilledStar
                      className={`${!size ? "h-[15px]" : `h-[${size}px]`} ${
                        "fill-[#d9d9d9]"
                        //   isClicked ? "fill-[#d9d9d9]" : "fill-[#d9d9d9]"
                      }`}
                      // <EmptyStar
                      //   className={`h-[19px] ${
                      //     isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                      //   }`}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatingStars;
