import { cn } from "src/lib/utils";
import { Folder, MoreVertical } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";

const classes = [
  {
    asignature: "Mathematics",
    professor: "Julia Schuaz",
    theme: "#0096FF",
    time: "8:00 - 9:00 AM",
  },
  {
    asignature: "Biology",
    professor: "Roy Diaz",
    theme: "#355E3B",
    time: "9:00 - 10:00 AM",
  },
  {
    asignature: "Physics",
    professor: "Cynthia Smith",
    theme: "#E97451",
    time: "11:00 - 12:00 PM",
  },
  {
    asignature: "English",
    professor: "Julietta Johnson",
    theme: "#F88379",
    time: "1:00 - 2:00 PM",
  },
];

const MainClasses = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-10 p-6">
      {classes.map((classroom) => (
        <div key={classroom.time} className="rounded-2xl bg-input">
          <div
            className={cn("flex flex-col items-start p-6 rounded-t-2xl")}
            style={{ backgroundColor: classroom.theme }}
          >
            <div className="flex items-center justify-between w-full ">
              <p className="text-3xl text-white">{classroom.asignature}</p>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <MoreVertical className="h-7 w-7" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>{classroom.asignature}</DropdownMenuLabel>

                  <DropdownMenuSeparator />
                  <DropdownMenuItem>View Material</DropdownMenuItem>
                  <DropdownMenuItem>Attend Class</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <p className="text-[16px] mt-2 text-white">{classroom.professor}</p>
          </div>
          <div className="flex w-full justify-end p-6 rounded-b-2xl shadow-xl">
            <Folder className="h-5 w-5" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MainClasses;
