import { Checkbox } from "src/components/ui/checkbox";
import ConfigLayout from "src/components/configuration/ConfigLayout";

const ConfigAccount = () => {
  return (
    <ConfigLayout>
      <div className="bg-white p-4 rounded-lg">
        <p className="text-xl font-semibold pb-4">General</p>
        <div className="border-t border-border py-4">
          <div className="flex items-start ">
            <p className="font-medium text-[#333] w-40">Email address</p>
            <div className="flex flex-col items-start ml-6 w-60">
              <p className="text-gray-400">idiazeliteports@gmail.com</p>
              <p className="text-sm font-medium mt-4">Change email address</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Phone number</p>
            <div className="bg-[#f8f8f8] ml-6 w-60 py-2 rounded-lg ">
              <p className="text-sm text-center font-medium">
                Add a phone number
              </p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Password</p>
            <div className="bg-[#f8f8f8] ml-6 w-60 py-2 rounded-lg">
              <p className="text-sm text-center font-medium">Update password</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Interface language</p>
            <div className="border border-border ml-6 w-60 py-2 rounded-lg px-2">
              <p className="text-sm text-left ">English</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Timezone</p>
            <div className="border border-border ml-6 w-60 py-2 rounded-lg px-2">
              <p className="text-sm text-left ">Europa/Madrid(UTC+2:00)</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Currency</p>
            <div className="border border-border ml-6 w-60 py-2 rounded-lg px-2">
              <p className="text-sm text-left ">EUR €</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <p className="font-medium text-[#333] w-40">Time format</p>
            <div className="flex items-center space-x-4 ml-6">
              <div className="flex items-center space-x-2">
                <Checkbox className="rounded-full" />
                <p>12H</p>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox className="rounded-full" />
                <p>24H</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigLayout>
  );
};

export default ConfigAccount;
