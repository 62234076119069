import {
  Book,
  BookPlus,
  Calculator,
  Calendar,
  ChevronDown,
  MessageCircle,
  MoreHorizontal,
  MoveRight,
  Video,
} from "lucide-react";
import { Button } from "./ui/button";

const SidebarRight = () => {
  return (
    <div className="bg-white dark:bg-input border-border border rounded-lg h-full overflow-hidden mt-4">
      <div className="p-6 flex items-center justify-between ">
        <div className="flex items-center gap-2">
          <p className="text-xl font-semibold">Today's Schedule</p>
          <ChevronDown className="h-5 w-5" />
        </div>
        <div className="flex items-center gap-2 cursor-pointer">
          <p className="text-sm text-blue-500">View all</p>
          <MoveRight className="h-4 w-4 text-blue-500 " />
        </div>
      </div>
      <div className="flex flex-col p-6 ">
        <div className="bg-background rounded-2xl h-40">
          <div className="flex items-start justify-between ">
            <div className="flex items-start  gap-4">
              <div className="h-10 w-10 rounded-full bg-green-600 items-center flex justify-center">
                <Calculator className="h-5 w-5 text-white" />
              </div>
              {/* <div> */}
              <div className="flex flex-col space-y-2">
                <p className="text-lg font-medium">Mathematics</p>
                <p className="text-gray-400 text-sm">12:00PM - 2:00PM</p>
                <div className="flex items-center gap-10">
                  <div className="flex items-center gap-2">
                    <Video className="h-4 w-4 text-gray-400" />
                    <p className="text-sm text-gray-400">3 Videos</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Book className="h-4 w-4 text-gray-400" />
                    <p className="text-sm text-gray-400">2 Assignments</p>
                  </div>
                </div>
              </div>
            </div>
            <Button variant={"orange"}>Start</Button>
            {/* </div> */}
          </div>
        </div>
        <div className="bg-background rounded-2xl h-40 mt-4">
          <div className="flex items-start justify-between ">
            <div className="flex items-start  gap-4">
              <div className="h-10 w-10 rounded-full bg-slate-600 items-center flex justify-center">
                <Calendar className="h-5 w-5 text-white" />
              </div>
              {/* <div> */}
              <div className="flex flex-col space-y-2">
                <p className="text-2xl font-medium">Parent's Meeting</p>
                <p className="text-sm text-gray-400">Starts at 4:45pm</p>
                <div className="flex items-center">
                  <img
                    alt="people"
                    src={require("../assets/images/people1.jpg")}
                    className="h-10 w-10 rounded-full object-cover"
                  />
                  <img
                    alt="people-2"
                    src={require("../assets/images/people2.jpg")}
                    className="h-10 w-10 rounded-full object-cover -ml-4"
                  />
                  <img
                    alt="people-3"
                    src={require("../assets/images/people3.jpg")}
                    className="h-10 w-10 rounded-full object-cover -ml-4"
                  />
                  <div className="h-10 w-10 rounded-full bg-blue-500 items-center justify-center flex -ml-4">
                    <p className="font-medium text-white ">20+</p>
                  </div>
                </div>
              </div>
            </div>
            <Button variant={"orange"}>Start</Button>
            {/* </div> */}
          </div>
        </div>
        <div className="p-6 flex items-center justify-between ">
          <div className="flex items-center gap-2">
            <p className="text-xl font-semibold">Class Activity Feed</p>
          </div>
          <div className="flex items-center cursor-pointer gap-2 bg-input h-10 w-10 rounded-full justify-center">
            {/* <p className="text-sm text-blue-500">View all</p> */}
            <MoreHorizontal className="h-5 w-5  " />
          </div>
        </div>
        <div className="flex items-center justify-between cursor-pointer transition  hover:bg-background rounded-2xl p-6">
          <div className="flex items-start gap-4">
            <div className="bg-background h-16 w-16 items-center justify-center flex rounded-full">
              <BookPlus className="text-blue-200" />
            </div>
            <div>
              <p className="">
                <b>Ismael Diaz</b> submitted her mathematics homework
              </p>
              <p className="text-gray-400 text-sm">about an hour ago</p>
            </div>
          </div>
          <div className="h-2 w-2 bg-green-600 rounded-full -mt-4" />
        </div>
        <div className="flex items-center justify-between cursor-pointer transition  hover:bg-background rounded-2xl p-6">
          <div className="flex items-start gap-4">
            <div className="bg-background h-16 w-16 items-center justify-center flex rounded-full">
              <MessageCircle className="text-green-400" />
            </div>
            <div>
              <p className="">
                <b>Isaiah Johnson </b> sent you a message about his mathematics
              </p>
              <p className="text-gray-400 text-sm">4 days ago</p>
            </div>
          </div>
          <div className="h-2 w-2 bg-green-600 rounded-full -mt-4" />
        </div>
        <div className="flex items-center justify-between cursor-pointer transition  hover:bg-background rounded-2xl p-6">
          <div className="flex items-start gap-4">
            <div className="bg-background h-16 w-16 items-center justify-center flex rounded-full">
              <Calendar className="text-pink-400" />
            </div>
            <div>
              <p className="">
                <b>Isaiah Johnson </b> has booked an appointment for Sept 4,
                2023
              </p>
              <p className="text-gray-400 text-sm">4 days ago</p>
            </div>
          </div>
          <div className="h-2 w-2 bg-green-600 hidden rounded-full -mt-4" />
        </div>
        <div className="flex items-center justify-between cursor-pointer transition  hover:bg-background rounded-2xl p-6">
          <div className="flex items-start gap-4">
            <div className="bg-background h-16 w-16 items-center justify-center flex rounded-full">
              <Calendar className="text-pink-400" />
            </div>
            <div>
              <p className="">
                <b>Ismael Diaz </b> has booked an appointment for Sept 5, 2023
              </p>
              <p className="text-gray-400 text-sm">3 days ago</p>
            </div>
          </div>
          <div className="h-2 w-2 bg-green-600 hidden rounded-full -mt-4" />
        </div>
        <div className="flex items-center justify-between cursor-pointer transition  hover:bg-background rounded-2xl p-6">
          <div className="flex items-start gap-4">
            <div className="bg-background h-16 w-16 items-center justify-center flex rounded-full">
              <Calendar className="text-pink-400" />
            </div>
            <div>
              <p className="">
                <b>Ason Wade </b> has missed more than 2 classes in a row
              </p>
              <p className="text-gray-400 text-sm">10 days ago</p>
            </div>
          </div>
          <div className="h-2 w-2 bg-green-600 hidden rounded-full -mt-4" />
        </div>
        <div className="flex items-center gap-2 justify-end ml-auto mt-4 cursor-pointer">
          <p className="text-sm text-blue-500">View all activities</p>
          <MoveRight className="h-4 w-4 text-blue-500 " />
        </div>
      </div>
    </div>
  );
};

export default SidebarRight;
