import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { PlusSquare } from "lucide-react";

const langaugesSpoken = [
  {
    title: "Catalán",
    id: 0,
  },
  {
    title: "Euskera",
    id: 1,
  },
  {
    title: "Valenciano",
    id: 2,
  },
  {
    title: "Gallego",
    id: 3,
  },
];

const SpokenLanguages = () => {
  const navigate = useNavigate();
  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-[500px] bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333] font-medium">
              Your ad is automatically set to English but you will be able to
              add additional languages in which you also teach.
            </p>
            <br />
            <p className=" font-medium text-[#333]">
              Once you have translated your ad, it will be published in the
              countries where Superprof is present (in that language). This way,
              students from all over the world will be able to contact you.
            </p>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-5xl font-bold text-[#333]">
                <span className="text-orange">Languages</span> spoken (optional)
              </p>
              <p className="text-sm font-semibold py-3">
                Other languages spoken in Spain
              </p>
              <div className="h-[600px] w-full">
                <div>
                  {langaugesSpoken.map((language) => (
                    <div className="flex items-center bg-[#f8f8f8]  justify-between py-4 px-4 my-2 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer">
                      <p className="text-sm font-medium">{language.title}</p>
                      <PlusSquare className="h-5 w-5 " />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-4")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full cursor-pointer"
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-6")}
                className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange text-white"
              >
                <p className="font-medium">Next</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpokenLanguages;
