import { sidebarHome } from "src/atoms/atoms";
import { useAtom } from "jotai";
import {
  Globe,
  GraduationCap,
  Search,
  Smartphone,
  User,
  UserPlus,
  Users2,
  X,
} from "lucide-react";

const SidebarHome = () => {
  const [sidebarOpen, setSidebarOpen] = useAtom(sidebarHome);
  return (
    // <div className="fixed top-0 right-0 left-0 h-screen w-screen overflow-hidden z-20">
    //   <div className="absolute top-0 right-0 bg-black/30 h-full w-full" />

    <div
      className={`fixed right-0 h-screen bg-white transition duration-500 z-20 ${
        !sidebarOpen ? "scale-x-0 translate-x-full" : " scale-x-100 w-80"
      }`}
    >
      <div className="flex flex-col w-full ">
        <div className="ml-auto p-6">
          <div
            onClick={() => setSidebarOpen(false)}
            className="h-7 w-7 hover:bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
          >
            <X className="h-6 w-6 " />
          </div>
        </div>
        <div className="flex items-center  hover:bg-gray-100 space-x-4 p-6 cursor-pointer">
          <Search className="h-6 w-6 " />
          <p className=" ">Find a teacher</p>
        </div>
        <div className="flex items-center  hover:bg-gray-100 space-x-4 p-6 cursor-pointer">
          <Users2 className="h-6 w-6 " />
          <p className=" ">Group Class</p>
        </div>
        <div className="flex items-center  hover:bg-gray-100 space-x-4 p-6 cursor-pointer">
          <GraduationCap className="h-6 w-6 " />
          <p className=" ">Become a teacher</p>
        </div>
        <div className="flex items-center  hover:bg-gray-100 space-x-4 p-6 cursor-pointer">
          <Globe className="h-6 w-6 " />
          <p className=" ">Community</p>
        </div>
        <div className="flex items-center  hover:bg-gray-100 space-x-4 p-6 cursor-pointer">
          <Smartphone className="h-6 w-6 " />
          <p className=" ">Download App</p>
        </div>
      </div>
      <div className="absolute bottom-10 w-full px-6">
        <button className="w-full mx-2 bg-orange flex items-center justify-center space-x-2 py-2 rounded-sm ">
          <UserPlus className="h-5 w-5 text-white" />
          <p className="text-white text-sm font-medium">Sign Up</p>
        </button>
        <button className="w-full mx-2 bg-white py-2 rounded-sm mt-4 flex items-center space-x-2 justify-center border-orange border ">
          <User className="h-5 w-5 text-orange" />
          <p className=" text-sm font-medium text-orange ">Log In</p>
        </button>
      </div>
    </div>
    // </div>
  );
};

export default SidebarHome;
