import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { Coffee } from "lucide-react";
import { cn } from "../lib/utils";
import { useState } from "react";

const AboutClasses = () => {
  const navigate = useNavigate();
  const [aboutClassesInput, setAboutClassesInput] = useState("");

  const handleDesc = (e: string) => {
    setAboutClassesInput(e);
  };
  return (
    <div className="p-10">
      {" "}
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-96 bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333]">
              Explain how you work as a private tutor and how you transmit your
              knowledge.
              <br />
              <br />
              <ul className="list-inside list-disc">
                <li>Your teaching techniques and methodology</li>
                <li>Example of a class syllabus</li>
                <li>Your particularities as a teacher</li>
                <li>
                  To whom your classes are addressed (level, class, specialties,
                  etc.)
                </li>
              </ul>
            </p>
            <br />
            <div className="flex items-center my-6">
              <Coffee />
              <p className="text-[16px] font-semibold ml-2 uppercase">
                {" "}
                Do not forget Ismael
              </p>
            </div>
            <div>
              <p>Your contact details or URLs should not appear in the text</p>
            </div>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-[#333] font-bold text-5xl">
                About <span className="text-orange">your classes</span>
              </p>
              <p>(40 words minimum)</p>
            </div>
            <div className="h-[600px] w-full">
              <textarea
                value={aboutClassesInput}
                onChange={(e) => handleDesc(e.target.value)}
                cols={5}
                className="bg-[#f8f8f8] h-80 w-full decoration-none outline-none px-6 py-4 transition duration-200 ease-in-out focus:ring-2 rounded-lg focus:ring-orange font-paragraph"
                placeholder="Explain your learning method to your future students."
              />
            </div>
            <div className=" flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-1")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full "
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-3")}
                className={cn(
                  "px-16 py-4 rounded-full flex items-center justify-center space-x-2",
                  aboutClassesInput.trim().split(" ").length >= 40
                    ? "bg-orange text-white"
                    : "bg-gray-200 text-[#333] "
                )}
              >
                {aboutClassesInput.trim().split("").length >= 40 && (
                  <p className=" font-medium">Next</p>
                )}
                {aboutClassesInput.trim().split(" ").length < 40 && (
                  <p>{aboutClassesInput.trim().split(" ").length} / 40</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutClasses;
