import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import {
  Calendar,
  ChevronRight,
  ClipboardCheck,
  Cog,
  Gift,
  Globe2,
  GraduationCap,
  HelpCircle,
  LogOut,
  MessageCircle,
  MoreHorizontal,
  PencilLine,
  Presentation,
  Projector,
  Search,
  Settings,
  Settings2,
  ToggleLeft,
  Trash,
  UserCircle2,
  Users,
  Wallet,
} from "lucide-react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { cn } from "../lib/utils";

const headerOptions = [
  {
    title: "Learn",
    ref: "/dashboard",
    icon: Presentation,
  },
  {
    title: "Search a teacher",
    ref: "/teacher/math",
    icon: Search,
  },
  {
    title: "Group Class",
    ref: "/group-classes/math",
    icon: Users,
  },
  {
    title: "Community",
    ref: "/community",
    icon: Globe2,
  },
];

const navigationMore = [
  { title: "My classes", ref: "/classes", icon: Projector },
  { title: "My teachers", ref: "/selected/teachers", icon: GraduationCap },
  { title: "My exams", ref: "/materials", icon: ClipboardCheck },
  { title: "My schedule", ref: "/schedule", icon: Calendar },
  { title: "My wallet", ref: "/finance/overview", icon: Wallet },
];

const navigationProfile = [
  { title: "Recommend to a friend", icon: Gift, ref: "/referral" },
  { title: "Assistance", icon: HelpCircle, ref: "/support" },
  { title: "Configuration", icon: Cog, ref: "/configuration/account" },
  { title: "Become a teacher", icon: ToggleLeft, ref: "/" },
  { title: "Log out", icon: LogOut, ref: "/" },
];
const HeaderDashboard = () => {
  const navigate = useNavigate();
  return (
    <nav className="bg-white fixed top-0  w-full px-10 py-1  h-[80px] z-50 ">
      <div className="flex items-center justify-between">
        <Logo onClick={() => navigate("/")} className="h-16 object-cover" />
        <div className="flex items-center space-x-4">
          {headerOptions.map((option) => (
            <div
              onClick={() => navigate(option.ref)}
              className="flex flex-col items-center hover:bg-[#f8f8f8] rounded-md transition duration-200 ease-in-out cursor-pointer p-2"
            >
              <option.icon
                className={cn(
                  "h-5 w-5 text-gray-400",
                  window.location.pathname === option.ref && "text-orange"
                )}
              />
              <p
                className={cn(
                  "text-gray-400 text-sm",
                  window.location.pathname === option.ref && "text-orange"
                )}
              >
                {option.title}
              </p>
            </div>
          ))}
          <div className="">
            <NavigationMenu className="bg-white">
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>
                    <div className="flex flex-col items-center">
                      <MoreHorizontal className="h-5 w-5 text-gray-400" />
                      <p className="text-gray-400">Más</p>
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid w-[250px] gap-3 p-4 ">
                      {navigationMore.map((option) => (
                        <div
                          className="flex items-center space-x-3 py-2 px-2 hover:bg-[#f8f8f8] cursor-pointer transition duration-200 ease-in-out"
                          onClick={() => navigate(option.ref)}
                        >
                          <option.icon className="h-5 w-5" />
                          <p>{option.title}</p>
                        </div>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div>
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>
                    <div className="bg-[#f8f8f8] hover:bg-gray-200 transition cursor-pointer rounded-full h-9 w-9 flex items-center justify-center">
                      <MessageCircle className="text-gray-400 h-5 w-5" />
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="w-[400px] p-4">
                      <div className="py-2 border-b border-border">
                        <p className="font-medium">Notifications</p>
                      </div>
                      {/* <NavigationMenuItem> */}
                      <div className="flex items-center justify-between w-full py-2 hover:bg-[#f8f8f8] cursor-pointer">
                        <div className="flex items-center gap-2">
                          <img
                            src={require("../assets/images/people2.jpg")}
                            className="h-10 w-10 rounded-full object-cover"
                            alt="people"
                          />
                          <div>
                            <p className="font-medium text-xs">
                              Maigen, North Austin
                            </p>
                            <p className="text-gray-400 text-xs">
                              You didn't send me the biology homework
                            </p>
                          </div>
                        </div>

                        <Trash className="h-5 w-5 cursor-pointer" />
                      </div>
                      {/* </NavigationMenuItem> */}
                      {/* <NavigationMenuItem> */}
                      <div className="flex items-center justify-between w-full py-2 hover:bg-[#f8f8f8] cursor-pointer">
                        <div className="flex items-center gap-2">
                          <img
                            src={require("../assets/images/people1.jpg")}
                            className="h-10 w-10 rounded-full object-cover"
                            alt="people"
                          />
                          <div>
                            <p className="font-medium text-xs">
                              Maigen, North Austin
                            </p>
                            <p className="text-gray-400 text-xs">
                              You didn't send me the biology homework
                            </p>
                          </div>
                        </div>
                        <Trash className="h-5 w-5 cursor-pointer" />
                      </div>
                      {/* </NavigationMenuItem> */}
                      {/* <NavigationMenuItem> */}
                      <div className="flex items-center justify-between w-full py-2 hover:bg-[#f8f8f8] cursor-pointer">
                        <div className="flex items-center gap-2">
                          <img
                            src={require("../assets/images/people3.jpg")}
                            className="h-10 w-10 rounded-full object-cover"
                            alt="people"
                          />
                          <div>
                            <p className="font-medium text-xs">
                              Maigen, North Austin
                            </p>
                            <p className="text-gray-400 text-xs">
                              You didn't send me the biology homework
                            </p>
                          </div>
                        </div>
                        <Trash className="h-5 w-5 cursor-pointer" />
                      </div>
                      <div className="py-2 flex items-center justify-center border-t border-border">
                        <p>Show all</p>
                      </div>
                    </div>

                    {/* </NavigationMenuItem> */}
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
          <div>
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>
                    <UserCircle2 className="h-5 w-5 text-gray-400" />
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="w-[300px] p-4">
                      <div className="flex items-center justify-between px-4 hover:bg-[#f8f8f8] cursor-pointer">
                        <div className="flex items-center space-x-2">
                          <UserCircle2 className="h-8 w-8 text-gray-400" />
                          <div className="ml-2">
                            <div className="flex items-center">
                              <p className="text-sm font-medium text-[#333]">
                                Ismael Diaz
                              </p>
                              <ChevronRight className="h-4 w-4 text-[#333]" />
                            </div>
                            <p className="text-xs">Learning Math</p>
                          </div>
                        </div>
                        <div>
                          <PencilLine className="h-4 w-4 fill-gray-500 text-gray-500" />
                        </div>
                      </div>
                      <div className="h-6" />
                      {navigationProfile.map((nav) => (
                        <div
                          onClick={() => navigate(nav.ref)}
                          className="flex items-center py-4 px-4 hover:bg-[#f8f8f8] cursor-pointer "
                        >
                          <nav.icon className="h-5 w-5 mr-4" />
                          <p className="text-sm">{nav.title}</p>
                        </div>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderDashboard;
