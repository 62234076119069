import {
  CircleDollarSign,
  Expand,
  FileText,
  Loader2,
  Send,
  ServerCrash,
} from "lucide-react";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { Fragment, useRef, ElementRef } from "react";
import { useChatScroll } from "src/hooks/use-chat-scroll";
import { useChatQuery } from "src/hooks/use-chat-query";
import { ChatItem } from "./chat-item";
import { format } from "date-fns";

interface MainConversationProps {
  name: string;
  chatId: string;
  paramKey: "conversationId";
  paramValue: string;
  type: "conversation";
}

const DATE_FORMAT = "d MMM yyyy, HH:mm";

const MainConversation = ({
  chatId,
  paramKey,
  paramValue,
}: MainConversationProps) => {
  const chatRef = useRef<ElementRef<"div">>(null);
  const bottomRef = useRef<ElementRef<"div">>(null);

  const queryKey = `chat:${chatId}`;
  const addKey = `chat:${chatId}:messages`;
  const updateKey = `chat:${chatId}:messages:update`;
  const apiUrl = `http://192.168.1.42:3000/labtest/elite-api-xtralessons/v1/${queryKey}`;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useChatQuery({
      queryKey,
      apiUrl,
      paramKey,
      paramValue,
    });
  // useChatScroll({
  //   chatRef,
  //   bottomRef,
  //   loadMore: fetchNextPage,
  //   shouldLoadMore: !isFetchingNextPage && !!hasNextPage,
  //   count: data?.pages?.[0]?.items?.length ?? 0,
  // });

  // if (status === "loading") {
  //   return (
  //     <div className="flex flex-col flex-1 justify-center items-center">
  //       <Loader2 className="h-7 w-7 text-zinc-500 animate-spin my-4" />
  //       <p className="text-xs text-zinc-500 dark:text-zinc-400">
  //         Loading messages...
  //       </p>
  //     </div>
  //   );
  // }

  // if (status === "error") {
  //   return (
  //     <div className="flex flex-col flex-1 justify-center items-center">
  //       <ServerCrash className="h-7 w-7 text-zinc-500 my-4" />
  //       <p className="text-xs text-zinc-500 dark:text-zinc-400">
  //         Something went wrong!
  //       </p>
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col  ">
      <div className="h-[50vh]">
        {status === "error" && (
          <div className="flex flex-col flex-1 justify-center items-center">
            <ServerCrash className="h-7 w-7 text-zinc-500 my-4" />
            <p className="text-xs text-zinc-500 dark:text-zinc-400">
              Something went wrong!
            </p>
          </div>
        )}
        {/* Chat Messages */}
        {!hasNextPage && <div className="flex-1" />}
        {/* {!hasNextPage && <ChatWelcome type={type} name={name} />} */}
        {hasNextPage && (
          <div className="flex justify-center">
            {isFetchingNextPage ? (
              <Loader2 className="h-6 w-6 text-zinc-500 animate-spin my-4" />
            ) : (
              <button
                onClick={() => fetchNextPage()}
                className="text-zinc-500 hover:text-zinc-600 dark:text-zinc-400 text-xs my-4 dark:hover:text-zinc-300 transition"
              >
                Load previous messages
              </button>
            )}
          </div>
        )}

        <div className="flex flex-col-reverse mt-auto">
          {data?.pages?.map((group, i) => (
            <Fragment key={i}>
              {group.items.map((message: any) => (
                <ChatItem
                  key={message.id}
                  id={message.id}
                  //   currentMember={member}
                  //   member={message.member}
                  content={message.content}
                  fileUrl={message.fileUrl}
                  deleted={message.deleted}
                  timestamp={format(new Date(message.createdAt), DATE_FORMAT)}
                  isUpdated={message.updatedAt !== message.createdAt}
                />
              ))}
            </Fragment>
          ))}
        </div>
        <div ref={bottomRef} />
      </div>
      {/* Chat Input */}
      <div className="flex flex-col px-6 py-4 border-t">
        <div className="flex items-center justify-between pb-4">
          <div className="flex items-center space-x-4 ">
            <p>SMS</p>
            <p>Email</p>
          </div>
          <div>
            <Expand />
          </div>
        </div>
        <div>
          <Textarea placeholder="Type a message" />
          <div className="flex items-center justify-between  mt-6">
            <div className="flex items-center gap-3">
              <FileText />
              <CircleDollarSign />
            </div>
            <div className="flex items-center gap-4">
              <Button variant={"outline"}>Clear</Button>
              <Button variant={"orange"}>
                <Send className="h-5 w-5 mr-2" />
                <p className="font-medium ">Send</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainConversation;
