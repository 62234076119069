import { ChevronRight } from "lucide-react";
import { questions } from "../data/courses";
import QuestionCard from "./question-card";

const FAQs = () => {
  return (
    <div className="` pt-40 pb-40 bg-[#f8f8f8]">
      <p className="text-[#333] text-3xl font-semibold text-center">
        Frequently asked questions
      </p>
      <div className="max-w-4xl mx-auto mt-10">
        {questions.map((quest) => (
          <QuestionCard quest={quest} />
        ))}
        <div className="flex items-center gap-2 justify-center bg-white py-4">
          <p className="text-[#333] font-semibold text-sm">View all</p>
          <ChevronRight className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
};

export default FAQs;
