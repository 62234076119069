import { Cog, Plus } from "lucide-react";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";

//@ts-ignore
import StripeLogo from "src/assets/images/stripe-logo.png";

const invoices = [
  {
    invoice: "INV001",
    paymentStatus: "Paid",
    totalAmount: "$250.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV002",
    paymentStatus: "Pending",
    totalAmount: "$150.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV003",
    paymentStatus: "Unpaid",
    totalAmount: "$350.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV004",
    paymentStatus: "Paid",
    totalAmount: "$450.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV005",
    paymentStatus: "Paid",
    totalAmount: "$550.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV006",
    paymentStatus: "Pending",
    totalAmount: "$200.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV007",
    paymentStatus: "Unpaid",
    totalAmount: "$300.00",
    paymentMethod: "Credit Card",
  },
];

const MainPayments = () => {
  return (
    <div className="p-6">
      <div className="flex flex-col  gap-4 mb-10 justify-between">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start">
            <p className="text-xl font-semibold">Invoices</p>
            <p className="text-sm text-gray-400">
              Create and manage all invoices
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <Button variant={"outline"}>Send Feedback</Button>
            <Button variant={"outline"}>
              <Cog />
            </Button>
            <Button variant={"orange"} className="flex items-center gap-1 ">
              <Plus className="h-4 w-4" />
              New
            </Button>
          </div>
        </div>
        <div className="flex items-center p-6 justify-between">
          <div className="flex items-center gap-6">
            <img
              src={StripeLogo}
              className="w-32 object-cover"
              alt="stripe-logo"
            />
            <div>
              <p className="text-sm">
                Connet your stripe account to start receiving payments
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button variant={"outline"}>Connect Stripe</Button>
            <Button variant={"outline"}>Do it later</Button>
          </div>
        </div>
        <div
          className="flex items-center justify-between gap-2 w-full
        "
        >
          <div className="border border-border px-6 py-3 rounded w-full">
            <p className="text-xs text-gray-400 font-medium pb-2">
              0 Payment(s) in Draft
            </p>
            <p className="text-3xl font-semibold">€0.00</p>
          </div>
          <div className="border border-border px-6 py-3 rounded w-full">
            <p className="text-xs text-gray-400 font-medium pb-2">
              0 Payment(s) in Due
            </p>
            <p className="text-3xl font-semibold">€0.00</p>
          </div>
          <div className="border border-border px-6 py-3 rounded w-full">
            <p className="text-xs text-gray-400 font-medium pb-2">
              0 Payment(s) received
            </p>
            <p className="text-3xl font-semibold">€0.00</p>
          </div>
          <div className="border border-border px-6 py-3 rounded w-full">
            <p className="text-xs text-gray-400 font-medium pb-2">
              0 Unpaid/Overdue
            </p>
            <p className="text-3xl font-semibold">€0.00</p>
          </div>
        </div>
        <Table className="border border-border ">
          <TableCaption>A list of your recent invoices.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Invoice</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Method</TableHead>
              <TableHead className="text-right">Amount</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.invoice}>
                <TableCell className="font-medium">{invoice.invoice}</TableCell>
                <TableCell>{invoice.paymentStatus}</TableCell>
                <TableCell>{invoice.paymentMethod}</TableCell>
                <TableCell className="text-right">
                  {invoice.totalAmount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default MainPayments;
