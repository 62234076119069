//@ts-ignore
import Teacher from "../assets/images/teacher-home.png";
import { levels } from "../data/courses";
//@ts-ignore
import People1 from "../assets/images/people1.jpg";
//@ts-ignore
import People2 from "../assets/images/people2.jpg";
//@ts-ignore
import People3 from "../assets/images/people3.jpg";

const FindTeacher = () => {
  const asignatures = [
    "Maths",
    "English",
    "Spanish",
    "Physics",
    "French",
    "Music",
  ];
  return (
    <div className="py-20 bg-[#f8f8f8]">
      <div className="max-w-5xl mx-auto">
        <div className="grid grid-cols-2 ">
          <img src={Teacher} alt="teacher" className=" w-full object-cover " />
          <div className="rounded-lg bg-white p-10 ">
            <p className="font-semibold text-4xl text-[#333] pb-4">
              Find my teacher
            </p>
            <p className="text-[#222] font-medium text-sm pb-4">
              I'm learning...
            </p>
            <div className="flex flex-wrap items-center gap-3">
              {asignatures.map((asign) => (
                <div className="bg-gray-50 px-4 py-2 rounded-full">
                  <p className="text-gray-400 text-sm">{asign}</p>
                </div>
              ))}
              <div className="bg-gray-50 px-4 py-2 rounded-full">
                <p className="text-gray-400">More</p>
              </div>
            </div>
            <div className="pt-4">
              <p className="text-[#333] text-sm font-medium">
                My current level is...
              </p>
            </div>
            <div className="flex items-center justify-center h-[32px] z-20 my-4">
              <div className="w-[12px] h-[12px] hover:scale-125 hover:h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                  <p className="text-[10px]">1º</p>
                </div>
              </div>
              <div className="flex-1 bg-gray-50 h-[2px]"></div>
              <div className="w-[12px] h-[12px] hover:scale-125 hover:h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                  <p className="text-[10px]">2º</p>
                </div>
              </div>
              <div className="flex-1 bg-gray-50 h-[2px]"></div>
              <div className="w-[12px] h-[12px] hover:scale-125 hover:h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                  <p className="text-[10px]">Bº</p>
                </div>
              </div>
              <div className="flex-1 bg-gray-50 h-[2px]"></div>
              <div className="w-[12px] h-[12px] hover:scale-125 hover:h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                  <p className="text-[10px]">Uº</p>
                </div>
              </div>
              <div className="flex-1 bg-gray-50 h-[2px]"></div>
              <div className="w-[12px] h-[12px] hover:scale-125 hover:h-[30px] bg-[#e5e8ed]  flex justify-center items-center flex-none hover:w-8  rounded-full transition-all duration-300 cursor-pointer group">
                <div className="w-[20px] h-[20px] group-hover:opacity-100 opacity-0 flex items-center justify-center rounded-full text-white transition-all duration-300 bg-[#333]">
                  <p className="text-[10px]">Mº</p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-2 rounded-lg">
              <p className="text-xs text-gray-400">
                I´ve just started learning or I know a few basic concepts.
              </p>
            </div>
            <div className=" my-4">
              <p className="text-[#333] text-sm font-medium">
                I'm learning math for...
              </p>
              <div className="flex flex-wrap items-center gap-3 my-4">
                {levels.map((level) => (
                  <div className="bg-gray-50 px-4 py-2 rounded-full">
                    <p className="text-gray-400 text-sm">{level}</p>
                  </div>
                ))}
              </div>
              <div className="my-4 w-full">
                <button className="bg-orange transition duration-200 ease-in-out hover:bg-redLogo rounded w-full py-2 flex items-center space-x-2 justify-center">
                  <div className="flex items-center -space-x-2">
                    <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center">
                      <img
                        src={People1}
                        className="h-7 w-7 rounded-full object-cover"
                        alt="people"
                      />
                    </div>
                    <div className="h-8 w-8  rounded-full bg-white flex items-center justify-center">
                      <img
                        src={People2}
                        className="h-7 w-7 rounded-full object-cover"
                        alt="people"
                      />
                    </div>
                    <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center">
                      <img
                        src={People3}
                        className="h-7 w-7 rounded-full object-cover"
                        alt="people"
                      />
                    </div>
                  </div>
                  <p className="text-sm font-medium text-white">
                    {" "}
                    Find my teacher
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindTeacher;
