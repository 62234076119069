import { create } from "zustand";
import { combine } from "zustand/middleware";

const accessToken = undefined;
const authToken = undefined;

const accessTokenKey = "userToken";

export const useTokenStore = create(
  combine({ accessToken, authToken }, (set) => ({
    setTokens: async (x: any) => {
      try {
        await localStorage.setItem(accessTokenKey, x.accessToken);
      } catch {
        console.log(x);
      }
      set(x);
    },
    loadTokens: async () => {
      try {
        let accessToken: any = await localStorage.getItem(accessTokenKey);
        accessToken = accessToken || "";

        set({ accessToken });
      } catch {}
    },
  }))
);
