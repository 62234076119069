import { useParams } from "react-router-dom";
import Filters from "../components/Filters";

//@ts-ignore
import LearningToday from "../assets/images/learning-today.png";
//@ts-ignore

import GroupOnline from "../assets/images/group-online-classes.jpg";
//@ts-ignore
import SearchSupport from "../assets/images/search-support.png";
import { professorsExample, questions } from "../data/courses";
import HeaderDashboard from "../components/HeaderDashboard";
import {
  CheckCircle,
  CheckCircle2,
  ChevronRight,
  Heart,
  Shield,
  ShieldCheck,
  Star,
} from "lucide-react";
import QuestionCard from "../components/question-card";
import Articles from "../components/articles/Articles";
import Footer from "../components/footer/Footer";
import TeacherCard from "../components/findTeacher/TeacherCard";

const FindTeacherSigned = () => {
  const params = useParams();
  return (
    <div>
      <HeaderDashboard />
      <div>
        <div className="max-w-7xl mx-auto mt-10 px-10">
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-start w-[50%]">
              <p className="text-4xl font-semibold text-[#333] ">
                Find your{" "}
                <span className="text-orange">
                  {typeof params.subject === "string" &&
                    params.subject.slice(0, 1).toUpperCase() +
                      params.subject.slice(1).toLowerCase()}{" "}
                </span>
                teacher online{" "}
              </p>
              <p className="text-gray-800">
                At Xtlessons, we offer a variety of experienced {params.subject}{" "}
                and tutors to help you achieve your subject goals. Our online
                platform allos you to chooose from a wide range of{" "}
                {params.subject}, courses, and lessons to suid your learning
                needs and schedule.
              </p>
            </div>
            <div className="w-[50%]">
              <img
                className="w-72 object-cover justify-end ml-auto"
                src={SearchSupport}
                alt="search-support"
              />
            </div>
          </div>
        </div>
        <Filters />
        <div className="h-full w-full bg-[#f8f8f8] py-20">
          <div className="max-w-4xl mx-auto ">
            <div>
              {professorsExample.map((professor) => (
                <TeacherCard professor={professor} />
              ))}
            </div>
            <div className="flex items-center gap-10 bg-white rounded-lg">
              <img src={GroupOnline} className="h-40" />
              <div>
                {typeof params.subject === "string" && (
                  <p className="font-semibold mb-4">
                    {params.subject.slice(0, 1).toUpperCase() +
                      params.subject.slice(1).toLowerCase()}{" "}
                    Group Class
                  </p>
                )}
                <div className="flex flex-col items-start space-y-2">
                  <div className="flex items-center space-x-2">
                    <CheckCircle2 className="h-4 w-4 text-white fill-green-500" />
                    <p className="text-gray-600 font-medium text-sm">
                      Meet and practice with 2-6 other students
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <CheckCircle2 className="h-4 w-4 text-white fill-green-500" />
                    <p className="text-gray-600 font-medium text-sm">
                      Choose from 300+ useful topics{" "}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <CheckCircle2 className="h-4 w-4 text-white fill-green-500" />
                    <p className="text-gray-600 font-medium text-sm">
                      Start learning from $5
                    </p>
                  </div>
                </div>
              </div>
              <div className="justify-end items-end ml-auto mt-20 mr-4 bg-[#333] text-white px-4 py-2 rounded-full cursor-pointer hover:bg-black transition duration-200 ease-in-out">
                <p className="text-sm font-semibold">Try now</p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-10">
              <div className="bg-white  px-16 py-2 rounded-lg cursor-pointer">
                <p className="text-[#333] font-medium text-sm">Show more</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 mt-10">
                  Your final payment will be made in EUR
                </p>
              </div>
            </div>
            <div className="py-20">
              <p className="text-[#333] text-3xl font-semibold ">
                Frequently asked questions
              </p>
              <div className="mt-10">
                {questions.map((quest) => (
                  <QuestionCard quest={quest} />
                ))}
              </div>
            </div>
            <div className="bg-white px-6 py-4 rounded-lg flex items-center">
              <div className="flex flex-col items-start">
                <p className="text-[15px] font-semibold text-gray-600">
                  Ready to start your {params.subject} learning journey? Book a
                  trial lesson with one of our experienced {params.subject}{" "}
                  tutors today and take your first step towards fluency.
                </p>
                <div className="bg-orange/10 w-56 py-2 rounded-lg mt-5">
                  <p className="text-redLogo text-center font-semibold text-sm">
                    Start Learning Today
                  </p>
                </div>
              </div>
              <img
                src={LearningToday}
                className="w-56 object-cover"
                alt="learning-"
              />
            </div>
            <div className="py-10">
              <p className="text-[#333] text-2xl font-semibold mb-6">
                {typeof params.subject === "string" &&
                  params.subject?.slice(0, 1).toUpperCase() +
                    params.subject?.slice(1).toLowerCase()}{" "}
                learning articles you may like
              </p>
              <Articles />
            </div>
            <div className="pb-10">
              <div className="bg-white w-40 flex items-center justify-center space-x-2 py-2 px-4 rounded-full cursor-pointer">
                <p className="text-[#333] font-semibold text-sm">View more</p>
                <ChevronRight className="h-4 w-4 text-[#333]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FindTeacherSigned;
