import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { useRef, useState } from "react";

const Budget = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [budgetInput, setBudgetInput] = useState("");
  const handleBudget = (e: string) => {
    setBudgetInput(e);
  };
  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />

      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-[500px] bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To Know</p>
            <p className="text-[#333] font-medium">
              You are free to choose the hourly rate that suits you and you can
              change it at any time.
            </p>
            <br />
            <p className="text-[#333] font-medium">
              If you are just starting to teach, it may be better not to set
              your rate too high (don't forget to ask for recommendations to
              improve your ad).
            </p>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-5xl font-bold text-[#333]">
                Hourly <span className="text-orange">budget</span>
              </p>
              <p className="text-sm font-medium w-[80%] text-[#333] py-5 pb-7">
                Info: The average price of English classes in Madrid is 10 €.
              </p>
              <div className="h-[600px] w-full">
                <div className="flex items-center p-3 rounded-full bg-gray-100">
                  <input
                    ref={inputRef}
                    value={budgetInput}
                    onChange={(e) => setBudgetInput(e.target.value)}
                    className="bg-transparent px-3 font-paragraph flex-grow outline-none"
                    placeholder="10"
                  />
                  <p>€ /h</p>
                </div>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div
                  onClick={() => navigate("/creation-profile/step-5")}
                  className="bg-[#f8f8f8] px-16 py-4 rounded-full cursor-pointer"
                >
                  <p className="font-medium">Back</p>
                </div>
                <div
                  onClick={() => navigate("/creation-profile/step-7")}
                  className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange text-white"
                >
                  <p className="font-medium">Next</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;
