//@ts-ignore
import {
  Clipboard,
  Clock,
  MapPin,
  Paperclip,
  Text,
  Users,
  Video,
} from "lucide-react"; //@ts-ignore
import CristinaImage from "../../assets/images/people2.jpg";
//@ts-ignore
import People1 from "../../assets/images/people1.jpg";
//@ts-ignore
import People2 from "../../assets/images/people2.jpg";
//@ts-ignore
import People3 from "../../assets/images/people3.jpg";
//@ts-ignore
import People4 from "../../assets/images/people4.jpg";
import { Button } from "../ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { hoursInitial } from "src/data/hours";
import moment from "moment";
import { useEffect, useState } from "react";

const UpcomingClass = () => {
  const [startTime, setStartTime] = useState<any>("02:00");
  const [endTime, setEndTime] = useState<any>("04:00");
  const [startShiftTime, setStartShiftTime] = useState<any>("02:00");
  const [formatedToday, setFormattedToday] = useState("");
  const [duration, setDuration] = useState();
  const [endingHours, setEndingHours] = useState<any>([]);
  const [startingHours, setStartingHours] = useState<any>([]);

  useEffect(() => {
    console.log(new Date());
  }, [startTime]);

  useEffect(() => {
    setStartShiftTime(
      moment(
        `${formatedToday} ${startTime}:00 ${
          startTime.slice(-2) === "am" ? "AM" : "PM"
        }`,
        "DD-MM-YYYY hh:mm:ss A"
      )
    );
    console.log(startTime);
  }, [startTime]);

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "-" + mm + "-" + yyyy;
    setFormattedToday(formattedToday);
  }, []);

  var today = new Date();
  var today2 = new Date();
  console.log(startTime.slice(0, 2));
  console.log(startTime.slice(-2));
  // today.setHours
  today.setHours(Number(startTime.slice(0, 2)));
  today.setMinutes(Number(startTime.slice(-2)));

  // today2.setHours(17);
  // today2.setMinutes(30);

  // console.log(today.getHours(), today.getMinutes());
  // console.log(today2.getHours(), today2.getMinutes());
  var num6 = today2.getTime() - today.getTime();
  var num7 = num6 / 1000 / 60;
  // console.log(num6 / 1000 / 60);
  if (num7 > 60) {
    num7 = num7 / 60;
    console.log(num7);
    // }else if (num7 >60) {
  }
  const getHours = (hourEnd: string) => {
    today2.setHours(Number(hourEnd.slice(0, 2)));
    today2.setMinutes(Number(hourEnd.slice(-2)));

    var num6 = today2.getTime() - today.getTime();
    var num7 = num6 / 1000 / 60;

    if (num7 > 60) {
      num7 = num7 / 60;
      console.log(num7);

      return `${num7} h`;
    }

    return `${num7} min`;
  };

  // useEffect(() => {
  //   const startShiftTime = moment(
  //     `${formatedToday} 09:00:00 AM`,
  //     "DD-MM-YYYY hh:mm:ss A"
  //   );
  //   const endShiftTime = moment(
  //     `${formatedToday} 09:30:00 AM`,
  //     "DD-MM-YYYY hh:mm:ss A"
  //   );
  //   const duration = moment
  //     .duration(endShiftTime.diff(startShiftTime))
  //     .minutes();
  // }, [startShiftTime]);

  function decimalAHora(decimal: number) {
    let horas = Math.floor(decimal), // Obtenemos la parte entera
      restoHoras = Math.floor((decimal % 1) * 100), // Obtenemos la parde decimal
      decimalMinutos = (restoHoras * 60) / 100, // Obtenemos los minutos expresado en decimal
      minutos = Math.floor(decimalMinutos), // Obtenemos la parte entera
      restoMins = Math.floor((decimalMinutos % 1) * 100), // Obtenemos la parde decimal
      segundos = Math.floor((restoMins * 60) / 100); // Obtenemos los segundos expresado en entero

    return `${("00" + horas).slice(-2)}:${("00" + minutos).slice(-2)}:${(
      "00" + segundos
    ).slice(-2)}`;
  }

  let number = num7;
  console.log(number, "==", decimalAHora(number));

  useEffect(() => {
    setStartingHours(hoursInitial);
  }, []);

  const handleStartingHourLimit = () => {
    const append: any = hoursInitial?.map(function (row) {
      if (Number(row.value) < Number(endTime)) {
        return {
          label: row.label,
          value: row.value,
          // id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          // id: "",
        };
      }
    });
    setStartingHours(append.filter((item: any) => item.value !== ""));
  };

  const handleEndingHourLimit = () => {
    const append = hoursInitial?.map(function (row) {
      if (Number(row.value) > Number(startTime)) {
        return {
          label: row.label,
          value: row.value,
          // id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          // id: "",
        };
      }
    });
    setEndingHours(append.filter((item) => item.value !== ""));
  };
  useEffect(() => {
    handleStartingHourLimit();
  }, [endTime]);
  useEffect(() => {
    handleEndingHourLimit();
  }, [startTime]);

  useEffect(() => {
    console.log("startingHours", startingHours);
  }, [startingHours]);

  return (
    <div className=" p-6 m-6 rounded-2xl border border-border bg-white">
      <div className="flex flex-col items-start space-y-8">
        <p className="text-lg font-semibold">Upcoming class</p>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-4">
            <img
              src={CristinaImage}
              className="h-10 w-10 rounded-full object-cover"
              alt="professor"
            />
            <div>
              <p className="text-lg">Biology Class 0.5</p>
              <p className="text-sm text-gray-400">10:00 - 11:45 am</p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <img
              src={People1}
              className="h-10 w-10 rounded-full object-cover"
              alt="people-1"
            />
            <img
              src={People2}
              className="h-10 w-10 rounded-full object-cover"
              alt="people-2"
            />
            <img
              src={People3}
              className="h-10 w-10 rounded-full object-cover"
              alt="people-3"
            />
            <img
              src={People4}
              className="h-10 w-10 rounded-full object-cover"
              alt="people-4"
            />
            <div className="h-10 w-10 rounded-full flex items-center justify-center bg-input">
              <p className="text-green-500">11+</p>
            </div>
          </div>
        </div>
        <p className="text-xs text-gray-400">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
          voluptate, reprehenderit voluptates repellendus eos suscipit
          accusantium ipsum quas molestiae labore numquam, tenetur ipsa nihil
          totam sed dolor maxime laborum adipisci?
        </p>
        <div className="flex items-center gap-4 w-full justify-end">
          <Button size={"lg"} variant={"orange"}>
            Start class
          </Button>
          {/* <Button size={"lg"} variant={"outline"}> */}
          <Popover>
            <PopoverTrigger>Reschedule</PopoverTrigger>
            <PopoverContent className="w-full">
              <div className="flex flex-col items-start px-6">
                <input
                  className="border-b text-lg bg-none outline-none w-full mb-4"
                  placeholder="Añade un título"
                />
                <div className="flex items-center">
                  <Button variant={"ghost"}>Evento</Button>
                  <Button variant={"ghost"}>Tarea</Button>
                  <Button variant={"ghost"}>Calendario de citas</Button>
                </div>
              </div>
              <div className=" flex items-center gap-4 ">
                <Clock className="h-4 w-4 text-gray-500 " />
                <div className="hover:bg-gray-100 px-2 py-1 transition rounded cursor-pointer">
                  <div className="flex items-center">
                    <p className="text-[14px]">Lunes, 9 de septiembre </p>
                    <div className="flex items-center gap-1">
                      <div className="hover:bg-gray-100 px-2 py-1  transition">
                        <Select onValueChange={(val: any) => setStartTime(val)}>
                          <SelectTrigger className="bg-transparent border-0">
                            <p className="relative after:bg-black after:absolute after:h-[1px] after:w-0 after:bottom-0 after:left-0 text-[14px] hover:after:w-full after:transition-all after:duration-300 cursor-pointer">
                              <SelectValue placeholder="2:00 am" />
                            </p>
                          </SelectTrigger>
                          <SelectContent className="h-[200px] ">
                            {hoursInitial.map((hour: any) => (
                              <SelectItem
                                value={hour.value}
                                className="text-left justify-start"
                              >
                                {hour.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <span>-</span>
                      <div className="hover:bg-gray-100 px-2 py-1 transition">
                        <Select onValueChange={(val: any) => setEndTime(val)}>
                          <SelectTrigger className="bg-transparent border-0">
                            <p className="relative after:bg-black after:absolute after:h-[1px] after:w-0 after:bottom-0 after:left-0 text-[14px] hover:after:w-full after:transition-all after:duration-300 cursor-pointer">
                              <SelectValue placeholder="2:00 am" />
                            </p>
                          </SelectTrigger>
                          <SelectContent className="h-[200px] ">
                            {hoursInitial
                              .filter(
                                (hour) =>
                                  getHours(hour.value).slice(0, 1) !== "-"
                              )
                              .map((hour: any) => (
                                <SelectItem
                                  value={hour.value}
                                  className="text-left justify-start"
                                >
                                  {hour.label} ({getHours(hour.value)})
                                  {/* {today2.setHours(Number(hour.value))} */}
                                  {/* (
                                {moment
                                  .duration(
                                    moment(
                                      `${formatedToday} ${hour.value}:00 ${
                                        hour.label.slice(-2) === "pm"
                                          ? "PM"
                                          : "AM"
                                      }`,
                                      "DD-MM-YYYY hh:mm:ss A"
                                    ).diff(startShiftTime)
                                  )
                                  .minutes()}
                                ) */}
                                  {/* {moment.duration(startTime.diff(hour.value))} */}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs text-gray-400">
                    Zona horaria - No se repite
                  </p>
                </div>
              </div>
              <div className=" flex items-center gap-4 my-2">
                <Users className="h-4 w-4 text-gray-500 " />
                <input
                  className="hover:bg-gray-100 text-[13px] px-2 py-2 transition rounded outline-none focus-within:bg-gray-100  w-full  cursor-pointer font-paragraph"
                  placeholder="Añade invitados"
                />
              </div>
              <div className=" flex items-center gap-4 my-2">
                <Video className="h-4 w-4 text-gray-500 " />
                <button className=" text-[14px] px-4 py-2 transition rounded outline-none hover:bg-blue-700  bg-blue-500  text-white cursor-pointer font-medium">
                  Añadir videollamada
                </button>
              </div>
              <div className=" border-y border-border py-2 flex items-center gap-4 my-2">
                <MapPin className="h-4 w-4 text-gray-500 " />
                <input
                  className="hover:bg-gray-100 text-[13px] px-2 py-2 transition rounded outline-none focus-within:bg-gray-100  w-full  cursor-pointer font-paragraph"
                  placeholder="Añade una ubicación"
                />
              </div>
              <div className="  flex items-center gap-4 my-2">
                <Text className="h-4 w-4 text-gray-500 " />
                <input
                  className="hover:bg-gray-100 text-[13px] px-2 py-2 transition rounded outline-none focus-within:bg-gray-100  w-full  cursor-pointer font-paragraph"
                  placeholder="Añade una descripción"
                />
              </div>
              <div className="  flex items-center gap-4 my-2">
                <Paperclip className="h-4 w-4 text-gray-500 " />
                <input
                  className="hover:bg-gray-100 text-[13px] px-2 py-2 transition rounded outline-none focus-within:bg-gray-100  w-full  cursor-pointer font-paragraph"
                  placeholder="Adjuntar archivo"
                />
              </div>
            </PopoverContent>
          </Popover>
          {/* </Button> */}
        </div>
      </div>
    </div>
  );
};

export default UpcomingClass;
