import { Checkbox } from "src/components/ui/checkbox";
import ConfigLayout from "src/components/configuration/ConfigLayout";

const configurationOptions = [
  { id: 0, description: "Allowing others to see who I follow", active: true },
  {
    id: 1,
    description: "Allowing people to see my comments on classes",
    active: true,
  },
  { id: 2, description: "Allowing others to see my age", active: true },
  {
    id: 3,
    description: "Allow teachers to see if I have visited your profile",
    active: false,
  },
];

const ConfigPrivacy = () => {
  return (
    <ConfigLayout>
      <div className="bg-white p-4 rounded-lg">
        <p className="text-xl font-semibold pb-4">Privacy</p>
        <div className="border-t border-border py-4">
          <div className="flex items-start">
            <p className=" font-medium w-40 ">Contact permissions</p>
            <div className="flex flex-col items-start ml-6">
              <p>Who can send me messages</p>
              <div className="border border-border px-4 py-2 rounded-md w-60 mt-4">
                <p className="text-sm">People who I follow</p>
              </div>
            </div>
          </div>
          <div className="flex items-center mt-6">
            <p className=" font-medium w-40">Block list</p>
            <div className="flex flex-col items-start ml-6">
              <p className="text-sm">No one is currently blocked</p>
            </div>
          </div>
          <div className="flex items-start mt-6">
            <p className=" font-medium w-40">Other privacy settings</p>
            <div className="flex flex-col items-start ml-6">
              {configurationOptions.map((option) => (
                <div className="flex items-center  space-x-2 py-2">
                  <Checkbox checked={option.active} />
                  <p>{option.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-start mt-6">
            <p className=" font-medium w-40">My data</p>
            <div className="flex flex-col items-start ml-6 ">
              <p className="text-sm">
                Download my public information from italki.
              </p>
              <div className="bg-[#f8f8f8] w-60 py-2 mt-6 hover:bg-gray-200 transition duration-200 ease-in-out cursor-pointer">
                <p className="font-medium text-[#333] text-sm text-center">
                  Download
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-start mt-6">
            <p className=" font-medium w-40">Account deactivation</p>
            <div className="flex flex-col items-start ml-6 ">
              <div className="bg-[#f8f8f8] w-60 py-2 mt-6 hover:bg-gray-200 transition duration-200 ease-in-out cursor-pointer">
                <p className="font-medium text-[#333] text-sm text-center">
                  Deactivate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigLayout>
  );
};

export default ConfigPrivacy;
