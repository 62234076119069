import { useEffect, useState } from "react";
import Header from "../components/header";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { daysNumber } from "../data/days";
import { months } from "../data/months";
import { years } from "../data/years";

//@ts-ignore
import ShadowBackground from "../assets/images/shadowlogin.png";

import {
  researchAsignatures6to12,
  researchAsignaturesFrom16To18,
  researchAsignaturesfrom12to16,
  universitiesDegrees,
} from "../data/courses";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { X } from "lucide-react";

const Register = () => {
  const navigate = useNavigate();
  const [activeType, setActiveType] = useState("student");
  const [animateText, setAnimateText] = useState(false);
  const [birthDetails, setBirthDetails] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [birthDate, setBirthDate] = useState("");
  const [yearsBirth, setYearsBirth] = useState<any>(null);
  const [monthsBirth, setMonthsBirth] = useState<any>(null);
  const [daysBirth, setDaysBirth] = useState<any>(null);
  const [asignatures, setAssignatures] = useState<{ title: string }[]>();
  const [asignatureSelected, setAsignatureSelected] = useState<any>("");
  const [asignaturesLeastLikedSelected, setAsignatureLeastLikedSelected] =
    useState<any>("");

  useEffect(() => {
    if (yearsBirth < 12) {
      setAssignatures(researchAsignatures6to12);
    } else if (yearsBirth >= 12 && yearsBirth > 16) {
      setAssignatures(researchAsignaturesfrom12to16);
    } else if (yearsBirth >= 16 && yearsBirth <= 18) {
      setAssignatures(researchAsignaturesFrom16To18);
    }
  }, [yearsBirth]);

  const calculateAge = (birthDate: any) => {
    if (!birthDate) return;

    const currentDate: any = new Date();
    if (new Date(birthDate) > currentDate) {
      setBirthDate("");
      setYearsBirth(null);
      setMonthsBirth(null);
      setDaysBirth(null);
      alert("Invalid Date of Birth");
      return;
    }
    //@ts-ignore
    const diffTime = currentDate - new Date(birthDate);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    setYearsBirth(Math.floor(totalDays / 365.25));
    setMonthsBirth(Math.floor((totalDays % 365.25) / 30.4375));
    setDaysBirth(Math.floor((totalDays % 365.25) % 30.4375));
  };

  useEffect(() => {
    setAnimateText(true);
  }, []);

  useEffect(() => {
    if (
      birthDetails.day !== "" &&
      birthDetails.month !== "" &&
      birthDetails.year !== ""
    ) {
      calculateAge(
        `${birthDetails.year}-${birthDetails.month}-${birthDetails.day}`
      );
    }
  }, [birthDetails]);

  useEffect(() => {
    console.log("years", yearsBirth);
    console.log(monthsBirth);
    console.log(daysBirth);
  }, [yearsBirth, monthsBirth, daysBirth]);

  const handleChangeSelection = (asignature: { title: string }) => {
    if (asignatureSelected.includes(asignature)) {
      setAsignatureSelected(
        asignatureSelected.filter(
          (asign: { title: string }) => asign.title !== asignature.title
        )
      );
    } else {
      setAsignatureSelected((prev: any) => [...prev, asignature]);
    }
  };

  const handleChangeSelectionLeastLiked = (asignature: { title: string }) => {
    if (asignaturesLeastLikedSelected.includes(asignature)) {
      setAsignatureLeastLikedSelected(
        asignaturesLeastLikedSelected.filter(
          (asign: { title: string }) => asign.title !== asignature.title
        )
      );
    } else {
      setAsignatureLeastLikedSelected((prev: any) => [...prev, asignature]);
    }
  };

  // useEffect(() => {
  //   console.log(asignatureSelected, "asignatures Selected");
  //   console.log(asignatures, "asignatures");
  // }, [asignatureSelected, asignatures]);

  return (
    <div className="h-screen w-screen bg-blue-300/60 ">
      <Header />
      <main className="flex flex-col items-center">
        <img
          src={ShadowBackground}
          className="h-full w-full opacity-10 top-0 left-0 absolute object-cover -z-10"
          alt="background"
        />
        <div className="max-w-4xl mx-auto flex mt-20">
          <div className="grid grid-cols-2 min-h-[800px]">
            <div className="bg-[#0b2149] p-16 space-y-4 text-white rounded-l">
              <p className="text-2xl font-medium">Regístrate</p>
              {activeType === "student" && (
                <div
                  className={`opacity-0 ${
                    animateText && "opacity-100"
                  } transition flex flex-col items-start space-y-4`}
                >
                  <p>
                    Una educación gratuita de clase mundial <br /> para
                    cualquier persona en cualquier lugar.
                  </p>
                  <p className="">
                    Únete a XtLessons para obtener ayuda personalizada con lo
                    que estudias, o para aprender algo totalmente nuevo.
                    Guardaremos el registro de todos tus avances.
                  </p>
                  <p className="">
                    Al registrarte en XtLessons, aceptas nuestros{" "}
                    <span className="underline">términos de uso</span> y{" "}
                    <span className="underline">política de privacidad</span>
                  </p>
                </div>
              )}

              {activeType === "teacher" && (
                <div
                  className={`opacity-0 ${
                    animateText && "opacity-100"
                  } transition  flex flex-col items-start space-y-4`}
                >
                  <p>
                    Ayuda a cada estudiante a tener éxito con práctica
                    personalizada. 100% gratis.
                  </p>
                  <ul className="list-inside list-disc">
                    <li className="font-paragraph text-[15px]">
                      Asigna ejercicios de práctica, video y llamadas.
                    </li>
                    <li className="font-paragraph text-[15px]">
                      Da seguimiento al avance de tus estudiantes.
                    </li>
                    <li className="font-paragraph text-[15px]">
                      Únete a millones de profesores y estudiantes.
                    </li>
                  </ul>
                  <p>
                    Al registrarte en XtLessons, aceptas nuestros{" "}
                    <span className="underline">términos de uso</span> y{" "}
                    <span className="underline">política de privacidad</span>
                  </p>
                </div>
              )}
              {activeType === "father" && (
                <div
                  className={`opacity-0 ${
                    animateText && "opacity-100"
                  } transition  flex flex-col items-start space-y-4`}
                >
                  <p>Primero creo tu cuenta como padre</p>
                  <p>
                    Después de eso, te guiaremos en la creación de una cuenta
                    para tu hijo.
                  </p>

                  <p className="">
                    XtLessons es una manera excelente para que tu hijo obtenga
                    ayuda con lo que está aprendiendo en la escuela, o para
                    aprender algo totalmente nuevo.
                  </p>
                  <p>
                    Al registrarte en XtLessons, aceptas nuestros{" "}
                    <span className="underline">términos de uso</span> y{" "}
                    <span className="underline">política de privacidad</span>
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start p-16 bg-white rounded-r">
              <div className="flex flex-col w-full">
                <p className="text-sm font-semibold pb-1">
                  Únase a XtLessons como
                </p>
                <div className="grid grid-cols-3">
                  <button
                    onClick={() => {
                      setActiveType("student");
                      setAnimateText(false);
                      setTimeout(() => {
                        setAnimateText(true);
                      }, 300);
                    }}
                    className={cn(
                      "border border-border rounded-l py-3 text-text hover:text-white hover:bg-text group",
                      activeType === "student" && "bg-text text-white "
                    )}
                  >
                    <p className="font-semibold text-sm e">Estudiante</p>
                  </button>
                  <button
                    onClick={() => {
                      setActiveType("teacher");
                      setAnimateText(false);

                      setTimeout(() => {
                        setAnimateText(true);
                      }, 300);
                    }}
                    className={cn(
                      "border border-border rounded-r py-3 text-text hover:text-white hover:bg-text group",
                      activeType === "teacher" && "bg-text text-white "
                    )}
                  >
                    <p className="font-semibold text-sm ">Maestro</p>
                  </button>
                  <button
                    onClick={() => {
                      setActiveType("father");
                      setAnimateText(false);
                      setTimeout(() => {
                        setAnimateText(true);
                      }, 300);
                    }}
                    className={cn(
                      "border border-border rounded-r py-3 text-text hover:text-white hover:bg-text group",
                      activeType === "father" && "bg-text text-white "
                    )}
                  >
                    <p className="font-semibold text-sm ">Padre</p>
                  </button>
                </div>
                {activeType === "student" && (
                  <div className="flex flex-col space-y-1 w-full mt-4">
                    <p className="text-sm font-medium">
                      What's your birthdate?
                    </p>
                    <div className="flex items-center space-x-4">
                      <Select
                        onValueChange={(val) =>
                          setBirthDetails((prev) => ({
                            ...prev,
                            month: val,
                          }))
                        }
                      >
                        <SelectTrigger className="w-[100px]">
                          <SelectValue placeholder="Mes" />
                        </SelectTrigger>
                        <SelectContent className="h-[300px]">
                          {months.map((month) => (
                            <SelectItem value={month.value}>
                              {month.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Select
                        onValueChange={(val) =>
                          setBirthDetails((prev) => ({
                            ...prev,
                            day: val,
                          }))
                        }
                      >
                        <SelectTrigger className="w-[100px]">
                          <SelectValue placeholder="Día" />
                        </SelectTrigger>
                        <SelectContent className="h-[300px]">
                          {daysNumber.map((day) => (
                            <SelectItem value={day}>{day}</SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Select
                        onValueChange={(val) =>
                          setBirthDetails((prev) => ({
                            ...prev,
                            year: val,
                          }))
                        }
                      >
                        <SelectTrigger className="w-[100px]">
                          <SelectValue placeholder="Año" />
                        </SelectTrigger>
                        <SelectContent className="h-[300px]">
                          {years.map((year) => (
                            <SelectItem value={year.value}>
                              {year.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    {asignatures?.length && (
                      <div className="pt-4">
                        <p className="text-sm font-medium pb-3">
                          What is your favorite asignature?
                        </p>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant={"outline"}>
                              Select Asignatures
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56 h-[300px] overflow-y-auto">
                            <DropdownMenuLabel>Assignatures</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {/* {asignatures} */}
                            {asignatures.map((asign) => (
                              <DropdownMenuCheckboxItem
                                checked={asignatureSelected?.includes(asign)}
                                onCheckedChange={() =>
                                  handleChangeSelection(asign)
                                }
                              >
                                {asign.title}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                        {asignatureSelected.length > 0 && (
                          <div className="flex flex-wrap items-center gap-3 mt-4 max-h-[150px] overflow-y-auto scrollbar-hide">
                            {asignatureSelected.map(
                              (asign: { title: string }) => (
                                <div
                                  onClick={() =>
                                    setAsignatureSelected(
                                      asignatureSelected.filter(
                                        (assign: { title: string }) =>
                                          assign.title !== asign.title
                                      )
                                    )
                                  }
                                  className="bg-gray-200 px-2 py-1 rounded flex items-center gap-3 cursor-pointer hover:bg-gray-100 transition"
                                >
                                  <p className="text-xs font-medium">
                                    {asign.title}
                                  </p>
                                  <X className="h-4 w-4" />
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {asignatures?.length && (
                      <div className="pt-4">
                        <p className="text-sm font-medium pb-3">
                          What asignatures do you like the least?
                        </p>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant={"outline"}>
                              Select Asignatures
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56 h-[300px] overflow-y-auto">
                            <DropdownMenuLabel>Assignatures</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {/* {asignatures} */}
                            {asignatures.map((asign) => (
                              <DropdownMenuCheckboxItem
                                checked={asignaturesLeastLikedSelected?.includes(
                                  asign
                                )}
                                onCheckedChange={() =>
                                  handleChangeSelectionLeastLiked(asign)
                                }
                              >
                                {asign.title}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                        {asignaturesLeastLikedSelected?.length > 0 && (
                          <div className="flex flex-wrap items-center gap-3 mt-4 max-h-[150px] overflow-y-auto scrollbar-hide">
                            {asignaturesLeastLikedSelected.map(
                              (asign: { title: string }) => (
                                <div
                                  onClick={() =>
                                    setAsignatureLeastLikedSelected(
                                      asignaturesLeastLikedSelected.filter(
                                        (assign: { title: string }) =>
                                          assign.title !== asign.title
                                      )
                                    )
                                  }
                                  className="bg-gray-200 px-2 py-1 rounded flex items-center gap-3 cursor-pointer hover:bg-gray-100 transition"
                                >
                                  <p className="text-xs font-medium">
                                    {asign.title}
                                  </p>
                                  <X className="h-4 w-4" />
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {/* {birthDate.day !== "" &&
                      birthDate.month !== "" &&
                      birthDate.year !== "" && (
                        <div className="flex flex-col space-y-1 pt-10">
                          <button className="border rounded border-black px-3 py-3 w-full hover:bg-black/10 dark:text-black">
                            <p className="font-medium text-sm">
                              Continúa con Google
                            </p>
                          </button>
                          <button className="border rounded border-black px-3 py-3 w-full hover:bg-black/10 dark:text-black">
                            <p className="font-medium text-sm">
                              Continúa con Facebook
                            </p>
                          </button>
                          <button className=" border rounded border-black px-3 py-3 w-full hover:bg-black/10 dark:text-black">
                            <p className="font-medium text-sm">
                              Continúa con Apple
                            </p>
                          </button>
                          <button className=" border rounded border-black px-3 py-3 w-full hover:bg-black/10 dark:text-black">
                            <p className="font-medium text-sm">
                              Regístrate con tu correo electrónico
                            </p>
                          </button>
                        </div>
                      )}
                       */}
                  </div>
                )}
                {activeType !== "student" && (
                  <div className="flex flex-col space-y-1 w-full mt-4">
                    <button className="border rounded border-black px-3 py-3 w-full hover:bg-black/10">
                      <p className="font-medium text-sm">Continúa con Google</p>
                    </button>
                    <button className="border rounded border-black px-3 py-3 w-full hover:bg-black/10">
                      <p className="font-medium text-sm">
                        Continúa con Facebook
                      </p>
                    </button>
                    <button className=" border rounded border-black px-3 py-3 w-full hover:bg-black/10">
                      <p className="font-medium text-sm">Continúa con Apple</p>
                    </button>
                    <button className=" border rounded border-black px-3 py-3 w-full hover:bg-black/10">
                      <p className="font-medium text-sm">
                        Regístrate con tu correo electrónico
                      </p>
                    </button>
                  </div>
                )}
                <div className="mt-4 w-full">
                  <Button
                    onClick={() => navigate("/login")}
                    variant={"ghost"}
                    className="w-full"
                  >
                    <p className="font-medium text-text ">
                      ¿Ya tienes una cuenta?
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Register;
