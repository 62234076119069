import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { useRef, useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const ProfilePhoto = () => {
  const navigate = useNavigate();
  const [preview, setPreview] = useState<any>();
  const [file, setFile] = useState<any>();
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    const file = new FileReader();

    file.onload = function () {
      setPreview(file.result);
    };

    file.readAsDataURL(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    };

    setFile(target.files[0]);

    const file = new FileReader();

    file.onload = function () {
      setPreview(file.result);
    };

    file.readAsDataURL(target.files[0]);
  }

  return (
    <div className="p-10">
      <Logo
        onClick={() => navigate("/")}
        className="h-36 object-cover cursor-pointer"
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-[500px] bg-orange/10 rounded-2xl p-10">
            <p className="text-4xl font-bold text-[#333]">To know</p>
            <p className="text-[#333] font-medium">
              Your photo will be the same in all your ads, a professional
              quality will help you build trust with your future students and
              maximize applications.
            </p>

            <br />
            <br />
            <br />
            <p className="text-[#333] font-medium">
              <ul className="list-disc list-inside space-y-2">
                <li> Show yourself alone and smiling!</li>

                <li> Face and look in front of the lens</li>

                <li>
                  {" "}
                  Avoid sunglasses, logos, blurry, pixelated or too dark photos.
                </li>

                <li>Min. 600x600px, .PNG or .JPEG</li>
              </ul>
            </p>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <p className="text-5xl font-bold text-[#333]">
              Profile <span className="text-orange">photo</span>
            </p>
            <div
              className="flex items-start h-[600px] space-x-4"
              {...getRootProps()}
            >
              <input
                id="image"
                type="file"
                name="image"
                accept="image/png, image/jpg"
                className="hidden"
                onChange={handleOnChange}
              />
              <div className="flex flex-col items-center">
                <div className="h-64 w-64 relative border-2 border-dashed border-gray-200 flex items-center justify-center rounded-2xl">
                  <div className="h-60 w-60  bg-gray-100 rounded-2xl flex items-center justify-center">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p className="text-xs text-gray-400 text-center">
                        Drop the files here ...
                      </p>
                    ) : (
                      <p className="text-xs text-gray-400 text-center">
                        Drag 'n' drop some files here or click to select files
                      </p>
                    )}
                  </div>
                  {preview && (
                    <div className="absolute top-1 right-1">
                      <img
                        src={preview as string}
                        alt="Upload preview"
                        className="h-60 w-60 rounded-2xl object-cover"
                      />
                    </div>
                  )}
                </div>
                {preview ? (
                  <div className="bg-orange text-white mt-10 rounded-full px-10 py-4 cursor-pointer">
                    <p className="text-sm font-medium">Edit the photo</p>
                  </div>
                ) : (
                  <div className="bg-orange text-white mt-10 rounded-full px-10 py-4 cursor-pointer">
                    <p className="text-sm font-medium">Upload a photo</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              <div
                onClick={() => navigate("/creation-profile/step-7")}
                className="bg-[#f8f8f8] px-16 py-4 rounded-full cursor-pointer"
              >
                <p className="font-medium">Back</p>
              </div>
              <div
                onClick={() => navigate("/creation-profile/step-9")}
                className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange text-white"
              >
                <p className="font-medium">Next</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePhoto;
