import {
  PlusCircle,
  Calendar as CalendarIcon,
  Clock,
  UserPlus,
  Plus,
} from "lucide-react";
import React from "react";
import { Calendar } from "src/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const classLessons = [
  { title: "English Lesson 1", date: "May 9, 2022", time: "9:00 - 10:30" },
  { title: "Biology Mutation", date: "May 9, 2022", time: "10:30 - 12:00" },
  { title: "Chemistry Lesson 3", date: "May 9, 2022", time: "12:00 - 12:30" },
  { title: "English Lesson 2", date: "May 9, 2022", time: "12:30 - 13:30" },
  { title: "Biology Lesson 2", date: "May 9, 2022", time: "13:30 - 14:30" },
];

const SidebarClasses = () => {
  const [date, setDate] = React.useState<Date | undefined>(new Date());

  return (
    <div className="h-screen ">
      <div className="flex flex-col items-center justify-center my-6 ">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          className="rounded-md border bg-white border-border"
        />

        <div className="flex flex-col mt-10 p-6 w-full ">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-xl font-semibold">Upcoming Schedule</p>
              <p className="text-lg font-medium">{new Date().toDateString()}</p>
            </div>
            <Popover>
              <div className="h-8 w-8 rounded-full flex items-center justify-center">
                <PopoverTrigger>
                  <PlusCircle />
                </PopoverTrigger>
              </div>
              <PopoverContent className="w-80">
                <div className="grid gap-4">
                  <div className="space-y-2">
                    <h4 className="font-medium leading-none">New Class</h4>
                  </div>
                  <div className="grid gap-2">
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="width">Title</Label>
                      <Input
                        id="width"
                        defaultValue="100%"
                        className="col-span-2 h-8"
                      />
                    </div>
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="maxWidth">Time</Label>
                      <Input
                        id="maxWidth"
                        defaultValue="300px"
                        className="col-span-2 h-8"
                      />
                    </div>
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="height">Date</Label>
                      <Input
                        id="height"
                        defaultValue="25px"
                        className="col-span-2 h-8"
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="h-6 w-6 bg-input rounded-full flex items-center justify-center cursor-pointer">
                        <Plus className="h-4 w-4" />
                      </div>
                      <p className="text-sm">Add students</p>
                      {/* <Label htmlFor="maxHeight">Max. height</Label>
                      <Input
                        id="maxHeight"
                        defaultValue="none"
                        className="col-span-2 h-8"
                      /> */}
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex flex-col mt-4 w-full space-y-5">
            {classLessons.map((classroom) => (
              <div className="flex items-center bg-white rounded-lg justify-between cursor-pointer transition  hover:bg-background  p-6 border border-border px-6 py-3">
                <div className="flex items-start gap-4  ">
                  <div className="flex flex-col space-y-2">
                    <p className="font-medium">{classroom.title}</p>
                    <div className="flex items-center space-x-10">
                      <div className="flex items-center space-x-2">
                        <CalendarIcon className="h-4 w-4 text-gray-400" />

                        <p className="text-gray-400 text-sm">
                          {classroom.date}
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Clock className="h-5 w-5 text-gray-400" />
                        <p className="text-gray-400 text-sm">
                          {classroom.time}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-2 w-2 bg-green-600 hidden rounded-full -mt-4" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarClasses;
