import { cn } from "src/lib/utils";
import { useState } from "react";
import { Check, ChevronDown } from "lucide-react";

const MainSubscriptions = () => {
  const [paymentType, setPaymentType] = useState("annually");
  return (
    <div className="p-6">
      <div className="flex items-center px-2 py-1 bg-input justify-center rounded-full">
        <div
          onClick={() => setPaymentType("monthly")}
          className={cn(
            "px-4 py-2 bg-input rounded-full cursor-pointer",
            paymentType === "monthly" && "bg-white"
          )}
        >
          <p className="font-medium">Pay monthly</p>
        </div>
        <div
          onClick={() => setPaymentType("annually")}
          className={cn(
            "px-4 py-2 bg-input rounded-full cursor-pointer",
            paymentType === "annually" && "bg-white"
          )}
        >
          <p className="font-medium">Pay annually</p>
        </div>
      </div>
      <div className="flex items-center mt-10">
        <div className="flex flex-col items-center border border-border p-6">
          <div className="border border-border bg-white px-4 py-1 -mt-10 flex items-center justify-center rounded-full">
            <p className="">Current Plan</p>
          </div>
          <div className="flex flex-col items-start gap-1 mt-4">
            <p className="text-xl font-semibold ">Starter</p>
            <p className="">
              <span className="font-medium">97€</span> /month
            </p>
            <p className="text-sm text-gray-400 ">Billed Annually</p>
          </div>
          <div className="my-10">
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>Unlimited Users</p>
              </div>
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>1 Student Account</p>
              </div>
            </div>
          </div>
          <div className="w-full mb-4 h-10 opacity-0">
            <button className="bg-blue-500 py-2 rounded w-full text-white">
              Upgrade
            </button>
          </div>
          <div className="py-2">
            <p className="font-semibold">Features</p>
            <div className="flex flex-col items-start gap-1">
              <p className="text-gray-400">Workflow Builder</p>
              <p className="text-gray-400">Campaingn Builder</p>
              <p className="text-gray-400">2 way SMS</p>
              <p className="text-gray-400">2 Way Email</p>
              <p className="text-gray-400">Professor Accountability</p>
              <p className="text-gray-400">Landing Page Builder</p>
              <div className="flex items-center gap-1">
                <p className="text-text">View more (+18)</p>
                <ChevronDown className="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center border border-border p-6">
          {/* <div className="border border-border px-4 py-1 -mt-10 flex items-center justify-center rounded-full">
            <p className="">Intermediate Plan</p>
          </div> */}
          <div className="flex flex-col items-start gap-1 mt-4">
            <p className="text-xl font-semibold ">Intermediate</p>
            <p className="">
              <span className="font-medium">297€</span> /month
            </p>
            <p className="text-sm text-gray-400 ">Billed Annually</p>
          </div>
          <div className="my-10">
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>Unlimited Users</p>
              </div>
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>1 Student Account</p>
              </div>
            </div>
          </div>
          <div className="w-full mb-4 h-10">
            <button className="bg-blue-500 py-2 rounded w-full text-white">
              Upgrade
            </button>
          </div>
          <div className="py-1">
            <p className="font-semibold">Features</p>
            <div className="flex flex-col items-start gap-1">
              <p className="text-gray-400">Workflow Builder</p>
              <p className="text-gray-400">Campaingn Builder</p>
              <p className="text-gray-400">2 way SMS</p>
              <p className="text-gray-400">2 Way Email</p>
              <p className="text-gray-400">Professor Accountability</p>
              <p className="text-gray-400">Landing Page Builder</p>
              <div className="flex items-center gap-1">
                <p className="text-text">View more (+18)</p>
                <ChevronDown className="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center border border-border p-6">
          <div className="border bg-green-500 text-white border-border px-4 py-1 -mt-10 flex items-center justify-center rounded-full">
            <p className="">Recommended Plan</p>
          </div>
          <div className="flex flex-col items-start gap-1 mt-4">
            <p className="text-xl font-semibold ">Advanced</p>
            <p className="">
              <span className="font-medium">497€</span> /month
            </p>
            <p className="text-sm text-gray-400 ">Billed Annually</p>
          </div>
          <div className="my-10">
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>Unlimited Users</p>
              </div>
              <div className="flex items-center gap-3">
                <Check className="text-green-500" />
                <p>1 Student Account</p>
              </div>
            </div>
          </div>
          <div className="w-full mb-4 h-10">
            <button className="bg-green-500 py-2 rounded w-full text-white">
              Upgrade
            </button>
          </div>
          <div className="py-2">
            <p className="font-semibold">Features</p>
            <div className="flex flex-col items-start gap-1">
              <p className="text-gray-400">Workflow Builder</p>
              <p className="text-gray-400">Campaingn Builder</p>
              <p className="text-gray-400">2 way SMS</p>
              <p className="text-gray-400">2 Way Email</p>
              <p className="text-gray-400">Professor Accountability</p>
              <p className="text-gray-400">Landing Page Builder</p>
              <div className="flex items-center gap-1">
                <p className="text-text">View more (+18)</p>
                <ChevronDown className="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSubscriptions;
