import { MailOpen, Trash } from "lucide-react";
import SidebarConversation from "./sidebar-conversation";
import MainConversation from "./main-conversation";

const ConversationChat = () => {
  return (
    <div className=" h-[75vh] border-b flex flex-col items-center border-border border-t border-r w-full bg-[#f8f8f8] rounded-r-lg">
      <div className="flex items-center justify-between w-full bg-white py-4 px-4">
        <p className="text-2xl font-semibold">Ismael Diaz</p>
        <div className="flex items-center  gap-5">
          <div className="flex items-center gap-1">
            <MailOpen className="h-5 w-5 text-gray-400" />
            <p className="text-sm text-gray-400">Mark as Read</p>
          </div>
          <div className="flex items-center gap-1">
            <Trash className="h-5 w-5 text-gray-400" />
            <p className="text-sm text-gray-400">Delete</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6 w-full  p-6 mt-4">
        <div className="col-span-5">
          <MainConversation
            chatId="123"
            name="Ismael Diaz"
            type="conversation"
            paramKey="conversationId"
            paramValue="123"
          />
        </div>
        {/* <SidebarConversation /> */}
      </div>
    </div>
  );
};

export default ConversationChat;
