import { X } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { helpOpen } from "../atoms/atoms";
import { useAtom } from "jotai";

const helpActions = [
  {
    image: "",
    title: "Visit our Help Center ",
    description: "Everything you need, anyime you want",
  },
  {
    image: "",
    title: "Join our Facebook Group ",
    description: "Be part of the fastest growing community",
  },
  {
    image: "",
    title: "Announcements ",
    description: "Keep up to date with all the latest news & releases!",
  },
  {
    image: "",
    title: "Support Ticket",
    description: "Stuck somewhere? Feel free to create a new support ticket",
  },
  {
    image: "",
    title: "Live Chat ",
    description: "Everything you need, anyime you want",
  },
  {
    image: "",
    title: "1-on-1 Support Call ",
    description: "Schedule a 1-on1 live support on zoom",
  },
  {
    image: "",
    title: "Priority support ",
    description: "Get dedicated priority support",
  },
];

const SidebarHelp = () => {
  const [openHelp, setOpenHelp] = useAtom(helpOpen);

  return (
    <div
      className={`fixed right-0 h-screen bg-input transition duration-500  ${
        !openHelp ? "scale-x-0 translate-x-full" : " scale-x-100"
      }`}
    >
      <div className="flex flex-col w-full p-6">
        <div className="flex items-center justify-between">
          <p>Help Center</p>
          <X
            onClick={() => setOpenHelp(!openHelp)}
            className="h-5 w-5 text-gray-400 hover:text-gray-300 transition cursor-pointer"
          />
        </div>
        <p className="text-xs text-gray-400">
          Whether you want to talk to us, the community, or learn on <br /> your
          own, we've got you covered!
        </p>
      </div>
      <div className="flex flex-col space-y-6 p-6">
        {helpActions.map((helpAction) => (
          <div className="flex items-center space-x-4 border-t border-border py-2 px-1">
            <img
              src={helpAction.image}
              className="h-10 w-10 "
              alt={`help-action-${helpAction.title}`}
            />
            <div className="flex flex-col items-start">
              <p className="text-sm">{helpAction.title}</p>
              <p className="text-xs text-gray-400">{helpAction.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarHelp;
