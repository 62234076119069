import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { useRef, useState } from "react";

const PhoneNumber = () => {
  const navigate = useNavigate();
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const inputRef = useRef<any>(null);
  return (
    <div className="p-10">
      <Logo
        className="h-36 object-cover cursor-pointer"
        onClick={() => navigate("/")}
      />

      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-[500px] bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            <p className="text-[#333] font-medium">
              Tell us your mobile number, we will send you an SMS when you
              receive a class request.
            </p>
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            <div className="flex flex-col space-y-2">
              <p className="text-5xl font-bold text-[#333]">
                Phone <span className="text-orange">number</span>
              </p>
              <p className="py-4 font-medium">
                Don't worry, we will never share your phone number on the
                website. It will only be sent to students you agree to teach.
              </p>
              <div className="h-[600px] w-full">
                <p className="uppercase font-semibold text-gray-400 text-xs py-4">
                  Your phone number
                </p>
                <div className="flex items-center p-3 rounded-full bg-gray-100">
                  <input
                    ref={inputRef}
                    value={phoneNumberInput}
                    onChange={(e) => setPhoneNumberInput(e.target.value)}
                    className="bg-transparent px-3 font-paragraph flex-grow outline-none font-medium"
                    placeholder="623 23 14 78"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div
                  onClick={() => navigate("/creation-profile/step-6")}
                  className="bg-[#f8f8f8] px-16 py-4 rounded-full cursor-pointer"
                >
                  <p className="font-medium">Back</p>
                </div>
                <div
                  onClick={() => navigate("/creation-profile/step-8")}
                  className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange text-white"
                >
                  <p className="font-medium">Next</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
