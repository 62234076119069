//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { CreditCard, DollarSign, HelpCircle, PlusCircle } from "lucide-react";
import { Button } from "../components/ui/button";
import { useModal } from "../hooks/use-modal-store";
import ModalAddPaymentCard from "../components/modals/ModalPaymentCard";

const pricePoints = ["20", "30", "50", "100", "200", "500"];

const Payment = () => {
  const modal = useModal();
  return (
    <div className="h-screen w-screen bg-[#f8f8f8]">
      <nav className="bg-white fixed top-0 w-full px-10 py-1 h-[80px] z-50">
        <Logo className="h-16 object-cover" />
      </nav>
      <div className="max-w-7xl mx-auto pt-28">
        <div className="grid grid-cols-7 gap-6">
          <div className="col-span-5">
            <div className="flex flex-col bg-white p-4 ">
              <p className="text-xl font-medium text-[#333]">
                How many Xtlessons credits do you want to buy?
              </p>
              <div className="grid grid-cols-4 gap-4 mt-4">
                {pricePoints.map((price) => (
                  <div className="border border-border flex items-center justify-center py-4 rounded-lg cursor-pointer">
                    <p className="text-3xl font-semibold">
                      <span className="text-xs">€</span>
                      {price}
                    </p>
                  </div>
                ))}
              </div>
              <div className="my-4">
                <p className="text-sm">
                  If you want to charge more than 1000€ in Xtlessons credits,
                  use <span className="text-blue-500">Wire Transfer.</span>
                </p>
              </div>
            </div>
            <div className="flex flex-col bg-white mt-4">
              <div className="flex items-center justify-between p-4">
                <p className="text-xl font-semibold text-[#333]">
                  Promotion code
                </p>
                <div className="border border-border rounded-lg px-2 py-2 flex items-center justify-between">
                  <input
                    className="text-sm bg-transparent outline-none px-2 font-paragraph"
                    placeholder="Introduce your code"
                  />
                  <div className="cursor-pointer">
                    <p className="text-sm font-medium">Redeem</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between pt-6 pb-10 px-4 border-t border-border">
                <p className="text-xl font-semibold text-[#333]">Coupon</p>
              </div>
            </div>
            <div className="flex flex-col bg-white mt-4">
              <div className="flex items-center space-x-2 p-4">
                <p className="text-xl font-semibold text-[#333]">
                  Payment methods
                </p>
                <HelpCircle />
              </div>
              <div className="flex items-center justify-between py-4 border-t border-border my-2 hover:bg-[#f8f8f8] cursor-pointer transition duration-200 ease-in-out px-4">
                <div className="flex items-center space-x-2">
                  <CreditCard className="w-5 h-5" />
                  <p>Credit/Debit Card</p>
                </div>
                <div
                  onClick={() => modal.onOpen("paymentCard")}
                  className="flex items-center space-x-2"
                >
                  <PlusCircle className="h-5 w-5" />
                  <p className="uppercase text-[#333]">Add a new card</p>
                </div>
              </div>
              <div className="flex items-center justify-between py-4 my-2 border-t border-border hover:bg-[#f8f8f8] cursor-pointer transition duration-200 ease-in-out px-4">
                <div className="flex items-center space-x-2">
                  <CreditCard className="w-5 h-5" />
                  <p>Wire Transfer</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="bg-white p-4">
              <div className="flex items-start space-x-2 pb-4">
                <DollarSign className="h-8 w-8" />
                <div>
                  <p>Xtlessons Credits</p>
                  <p>-</p>
                  <p>100.00€</p>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-border pt-4 pb-4">
                <p className="text-[#333]">Subtotal</p>
                <p className="text-[#333]">100€ EUR</p>
              </div>
              <div className="flex items-center justify-between border-t border-border pt-4">
                <p className="text-[#333]">Total</p>
                <p className="text-[#333]">100€ EUR</p>
              </div>
              <div className="mt-4">
                <Button variant={"orange"} className="w-full">
                  <p>PAY 100.00 EUR</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddPaymentCard />
      {/* <HeaderDashboard/> */}
    </div>
  );
};

export default Payment;
