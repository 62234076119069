//@ts-ignore
import { ReactComponent as AppStoreDownload } from "../assets/images/AppStoreDownload.svg";
//@ts-ignore
import { ReactComponent as GoogleDownload } from "../assets/images/GoogleDownload.svg";

//@ts-ignore
import TextureBanner from "../assets/images/texture-banner.jpg";

const BannerAvailable = () => {
  return (
    <div className="bg-orange/80 h-72 w-full py-10 z-10 relative">
      <img
        src={TextureBanner}
        alt="texture"
        className="w-full opacity-10 h-full absolute top-0 left-0 object-cover right-0 -z-10"
      />
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col w-[40%]">
          <p className="text-4xl text-white font-semibold">
            Xtlessons is available on Android and iOS
          </p>
          <div className="flex items-center space-x-4 ">
            <a href="">
              <AppStoreDownload className="h-36 w-36" />
            </a>
            <a href="">
              <GoogleDownload className="h-36 w-36" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAvailable;
