import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
//@ts-ignore
import ShadowBackground from "../assets/images/shadowlogin.png";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { auth } from "../firebase";
import { useTokenStore } from "../hooks/useTokenStore";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";

const formSchema = z.object({
  email: z.string().min(1),
  password: z.string().min(1),
});

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setToken = useTokenStore((state) => state.setTokens);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential: any) => {
          const user: any = userCredential.user;
          console.log(user);
          setToken(user.accessToken);
          navigate("/creation-profile/step-0");
        })
        .catch((error: any) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    } catch (error) {}
  };
  // const onSubmit: SubmitHandler<FormValues> = (data) => {
  //   signInWithEmailAndPassword(auth, data.email, data.password)
  //     .then((userCredential: any) => {
  //       const user: any = userCredential.user;
  //       console.log(user);
  //       setToken(user.accessToken);
  //     })
  //     .catch((error: any) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(errorCode, errorMessage);
  //     });
  // };

  // const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
  //   return console.log(errors);
  // };

  // const { ...methods } = useForm();

  return (
    <div className="h-screen w-screen bg-redLogo/70">
      <Header />
      <main className="flex flex-col items-center">
        <img
          src={ShadowBackground}
          className="h-full w-full opacity-10 top-0 left-0 absolute object-cover -z-10"
          alt="background"
        />
        <div className="max-w-4xl mx-auto flex mt-20">
          <div className="grid grid-cols-2 min-h-[800px]">
            <div className="bg-[#0b2149] p-16 space-y-4 text-white rounded-l">
              <p className="text-3xl font-medium">Inicia sesión</p>
              <p>!Qué gusto tenerte de regreso!</p>
              <p>
                Al iniciar sesión en XtLessons, aceptas
                <br /> nuestros{" "}
                <span className="underline">términos de uso</span> y{" "}
                <span className="underline">
                  política de
                  <br /> privacidad
                </span>
                .
              </p>
            </div>
            <div className="flex flex-col items-start p-16 bg-white rounded-r ">
              <div className="flex flex-col space-y-1 w-full">
                <button className="border rounded border-black px-3 py-3 w-full dark:text-black">
                  <p className="font-medium text-sm">Continúa con Google</p>
                </button>
                <button className="border rounded border-black px-3 py-3 w-full dark:text-black">
                  <p className="font-medium text-sm">Continúa con Facebook</p>
                </button>
                <button className=" border rounded border-black px-3 py-3 w-full dark:text-black">
                  <p className="font-medium text-sm">Continúa con Apple</p>
                </button>
              </div>
              <div className="my-4">
                <p className="font-medium text-red-400 text-sm">
                  * indica un campo obligatorio
                </p>
              </div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="w-full flex flex-col"
                >
                  <div className="w-full mb-4">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-sm pb-3 dark:text-black">
                            Correo electrónico o nombre de usuario *
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="email"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <div className="w-full mb-4">
                          <FormLabel className="text-sm pb-3 dark:text-black">
                            Contraseña *
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="password"
                              {...field}
                              name="password"
                              className="w-full dark:bg-transparent "
                            />
                          </FormControl>
                        </div>
                      </FormItem>
                    )}
                  />
                  <div className="w-full mb-4">
                    <p className="text-text text-sm font-medium">
                      ¿Olvidaste tu contraseña?
                    </p>
                  </div>
                  <div className="w-full">
                    <Button
                      type="submit"
                      // onClick={methods.handleSubmit(onSubmit)}
                      variant={"orange"}
                      className="w-full"
                    >
                      <p>Inicia sesión</p>
                    </Button>
                  </div>
                </form>
              </Form>
              <div className="w-full mt-3">
                <Button
                  onClick={() => navigate("/register")}
                  variant={"ghost"}
                  className="w-full "
                >
                  <p>Crear una cuenta</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
