import { FilterIcon, HelpCircle, Ticket } from "lucide-react";
import Footer from "../components/footer/Footer";
import { Button } from "../components/ui/button";
import HeaderDashboard from "../components/HeaderDashboard";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="max-w-7xl mx-auto pt-28 min-h-[calc(100%-370px)]">
        <div className="grid grid-cols-7 gap-6">
          <div className="  col-span-5 ">
            <div className="w-full bg-white p-4 py-10 rounded-lg">
              <div className="flex items-center justify-between py-2 border-b border-gray-100">
                <p className="text-xl font-medium text-[#333]">My wallet</p>
                <Button onClick={() => navigate("/payment")} variant={"orange"}>
                  <p className="text-[15px]">Buy credits</p>
                </Button>
              </div>
              <div className="flex items-center space-x-2">
                <p className="text-lg text-gray-500">Available balance</p>
                <HelpCircle className="h-4 w-4" />
              </div>
              <p className="text-2xl py-2 font-medium">$ 0.00 USD</p>
              <p className="text-sm">EUR 0.00</p>

              <div className="w-4 my-4 h-[1px] bg-[#333]" />
              <div className="flex items-center space-x-10">
                <div className="flex flex-col items-start">
                  <div className="flex items-center space-x-2">
                    <h1 className="text-lg text-gray-600 font-medium">
                      Total Balance
                    </h1>
                    <HelpCircle className="h-4 w-4 text-gray-300" />
                  </div>
                  <p className="text-xl py-2 font-medium">$ 0.00 USD</p>
                  <p className="text-sm">EUR 0.00</p>
                </div>
                <div className="flex flex-col items-start">
                  <div className="flex items-center space-x-2">
                    <h1 className="text-lg text-gray-600 font-medium">
                      Not Available Balance
                    </h1>
                    <HelpCircle className="h-4 w-4 text-gray-300" />
                  </div>
                  <p className="text-xl py-2 font-medium">$ 0.00 USD</p>
                  <p className="text-sm">EUR 0.00</p>
                </div>
              </div>
            </div>
            <div className="w-full bg-white p-4 pt-10 rounded-lg mt-6">
              <div className="flex items-center justify-between">
                <p className="text-xl font-medium">Transaction Details</p>
                <FilterIcon className="h-6 w-6 cursor-pointer " />
              </div>
              <div className="flex items-center justify-between mt-10">
                <p className="text-sm font-medium">Time</p>
                <p className="text-sm font-medium">Description</p>
                <p className="text-sm font-medium">Amount (USD)</p>
                <p className="text-sm font-medium">Status</p>
                <p className="text-sm font-medium">Actions</p>
              </div>
              <div className="flex items-center  justify-center">
                <p className="text-center py-10 text-xl font-medium">
                  No transactions
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="bg-white p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <p className="text-lg font-semibold">0 coupons</p>
                <Ticket className="h-5 w-5 " />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg mt-6">
              <div className="flex flex-col ">
                <p className="text-lg font-medium">Redeem</p>
                <p className="text-sm text-gray-600 ">
                  Redeem your gift card / Promotion Code / Recomendation code
                </p>
                <div className="flex items-center border mt-4 justify-between px-4 py-1 rounded-sm">
                  <input
                    className="bg-transparent outline-none flex-grow py-1"
                    placeholder="Introduce your code"
                  />
                  <p className="text-sm font-medium">Redeem</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wallet;
