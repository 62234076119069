//@ts-ignore
import StudentStudying from "../../assets/images/student-studying.avif";

const articles = [
  {
    image: StudentStudying,
    title: "Top reasons why you need and English teacher online",
    subtitle:
      " A good English teacher online can help you overcome all your fears of learning and speaking...",
  },
  {
    image: StudentStudying,
    title: "Top reasons why you need and English teacher online",
    subtitle:
      " A good English teacher online can help you overcome all your fears of learning and speaking...",
  },
  {
    image: StudentStudying,
    title: "Top reasons why you need and English teacher online",
    subtitle:
      " A good English teacher online can help you overcome all your fears of learning and speaking...",
  },
  {
    image: StudentStudying,
    title: "Top reasons why you need and English teacher online",
    subtitle:
      " A good English teacher online can help you overcome all your fears of learning and speaking...",
  },
];

const Articles = () => {
  return (
    <div className="grid grid-cols-2 gap-10">
      {articles.map((article) => (
        <div className="h-80 rounded-lg bg-white">
          <img
            src={StudentStudying}
            className="h-48 object-cover w-full rounded-t-lg"
          />
          <div className="flex flex-col p-4 ">
            <p className="text-[16px] text-[#333] font-semibold">
              {article.title}
            </p>
            <p className="text-gray-500 text-sm">{article.subtitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Articles;
