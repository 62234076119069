// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
import { getFirestore, initializeFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import ReactNativeAsyncStorage from "@react-native-async-storage/async-storage";
import { initializeAuth } from "firebase/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCaO0R9SsylUqNmIYk6-3PpX_Ge5fmqaZo",
    authDomain: "clasroom-d0f9e.firebaseapp.com",
    projectId: "clasroom-d0f9e",
    storageBucket: "clasroom-d0f9e.appspot.com",
    messagingSenderId: "433950550091",
    appId: "1:433950550091:web:834614065b4f5d7fc5c5ce"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const auth = getAuth(app)
// export const auth = getAuth(app)
export const auth = initializeAuth(app)
export const storage = getStorage(app)

export const db = initializeFirestore(app, {
    experimentalForceLongPolling: true
})

