import { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const Cookies = () => {
  const [cookiesStatus, setCookiesStatus] = useLocalStorage("cookies", false);
  const [closeCookies, setCloseCookies] = useState(false);

  const handleClick = () => {
    setCookiesStatus(true);
    setCloseCookies(!closeCookies);
  };

  if (!cookiesStatus && !closeCookies)
    return (
      <div className="fixed bottom-0 w-full bg-white z-50">
        <div className="max-w-5xl mx-auto flex items-center justify-between py-6">
          <p className="text-[#333] text-sm pb-2">
            We use cookies to ensure that we provide you the best experience on
            our website. By clicking on "Accept" you agree to our privacy
            policy.{" "}
            <span className="underline underlined-offset-2">
              Learn more here.
            </span>
          </p>
          <button
            onClick={handleClick}
            className="px-4 bg-orange rounded-lg py-2 "
          >
            <p className="text-white font-semibold text-sm">Accept</p>
          </button>
        </div>
      </div>
    );
  else {
    return null;
  }
};

export default Cookies;
