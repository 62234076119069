import { Heart, ShieldCheck, Star } from "lucide-react";

interface TeacherCardProps {
  professor: {
    type: string;
    speaks: string;
    title: string;
    fullName: string;
    image: any;
    numberOfClasses: number;
    rating: string;
    description: string;
  };
}

const TeacherCard = ({ professor }: TeacherCardProps) => {
  return (
    <div className="flex items-start gap-8 bg-white rounded-lg p-6 mb-6">
      <div className="flex flex-col items-center ">
        <img
          alt={`professor-${professor.fullName}`}
          src={professor.image}
          className="h-28 w-28 rounded-full object-cover"
        />
        <div>
          <div className="flex items-center space-x-1 mt-3">
            <Star className="fill-[#FDCC0D] h-5 w-5 text-white" />
            <p className="text-sm text-[#FDCC0D]">{professor.rating}</p>
          </div>
          <div>
            <p className="text-xs text-gray-800 font-medium">
              {professor.numberOfClasses} lessons
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          <p>{professor.fullName}</p>
          <ShieldCheck className="fill-orange text-white" />
        </div>
        <p className="text-sm text-gray-500 font-medium">{professor.type}</p>
        <p className="text-gray-500 text-sm my-3">
          SPEAKS: <span className="text-[#333]">{professor.speaks}</span>
        </p>
        <div>
          <p className="text-sm text-[#333] font-medium">{professor.title}</p>
          <p className="text-gray-500 font-medium text-sm">
            {professor.description}
          </p>
        </div>
        <div className="mt-4 flex items-center justify-between w-full">
          <p className="text-gray-500 text-sm">
            <span className="font-semibold text-[#333] text-[16px]">
              USD 10.00
            </span>{" "}
            / Hour
          </p>
          <div className="flex items-center space-x-2">
            <div className="bg-gray-100 h-8 w-8 rounded-full flex items-center justify-center">
              <Heart className="h-5 w-5 text-[#333]" />
            </div>
            <div className="bg-gray-100 px-4 py-2 rounded-full flex items-center justify-center">
              <p className="text-[#333] font-medium text-sm">Book lesson</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
