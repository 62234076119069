import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useModal } from "src/hooks/use-modal-store";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { HelpCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";

const formSchema = z.object({
  numberCard: z.string().min(1),
  expireDate: z.string().min(1),
  securityCode: z.string().min(1),
  saveNext: z.boolean().default(false).optional(),
});

const ModalAddPaymentCard = () => {
  const navigate = useNavigate();
  const modal = useModal();
  const [saveNext, setSaveNext] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      numberCard: "",
      expireDate: "",
      securityCode: "",
      saveNext: true,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
  };

  const isLoading = form.formState.isSubmitting;

  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div>
            <p className="text-lg font-medium">Add a new card</p>
            <p className="text-sm my-2">
              We accept VISA, Mastercard, American Express and UnionPay cards.
            </p>
          </div>
        </DialogHeader>
        <div className="flex flex-col items-center w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="numberCard"
                render={({ field }) => (
                  <FormItem className="flex-1 w-full">
                    <FormLabel>
                      <p className=" text-gray-600">Card number</p>
                    </FormLabel>
                    <FormControl>
                      {/* <div className="relative w-full"> */}
                      <Input
                        className="p-2 bg-transparent border w-[460px] border-border focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200 font-paragraph"
                        placeholder="Enter the card number"
                        {...field}
                      />
                      {/* </div> */}
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="flex items-center w-full space-x-4 mt-6">
                <FormField
                  control={form.control}
                  name="numberCard"
                  render={({ field }) => (
                    <FormItem className="flex-1 w-full">
                      <FormLabel>
                        <p className=" text-gray-600">Expiration date</p>
                      </FormLabel>
                      <FormControl>
                        {/* <div className="relative w-full"> */}
                        <Input
                          className="p-2 bg-transparent border w-[220px] border-border focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200 font-paragraph"
                          placeholder="03/22"
                          {...field}
                        />
                        {/* </div> */}
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="numberCard"
                  render={({ field }) => (
                    <FormItem className="flex-1 w-full">
                      <FormLabel>
                        <div className="flex items-center space-x-1">
                          <p className=" text-gray-600">Security code</p>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <HelpCircle className="h-4 w-4 text-gray-600" />
                              </TooltipTrigger>
                              <TooltipContent className="w-[200px] bg-black text-white">
                                The security code is a 3-digit number that
                                appears on the back of the card.
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </FormLabel>
                      <FormControl>
                        {/* <div className="relative w-full"> */}
                        <Input
                          className="p-2 bg-transparent border w-[220px] border-border focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200 font-paragraph"
                          placeholder="CVC"
                          {...field}
                        />
                        {/* </div> */}
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="saveNext"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 mt-6 p-4">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        <p className="text-gray-700">Save for next time</p>
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <div className=" w-full justify-end items-end flex">
                <Button
                  disabled
                  variant={"orange"}
                  type="submit"
                  className="ml-auto"
                >
                  Confirm
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddPaymentCard;
