import { useNavigate } from "react-router-dom";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { Check, ChevronRight, PlusSquare, Search } from "lucide-react";
import { relatedSkills, researchAsignatures6to12 } from "../data/courses";
import { useEffect, useState } from "react";

const WhatDoYouTeach = () => {
  const navigate = useNavigate();
  const [selectedAsignature, setSelectAsignature] = useState<{ title: string }>(
    { title: "" }
  );
  const [originalRelatedSkills, setOriginalRelatedSkills] = useState<
    {
      title: string;
    }[]
  >([]);

  const [selectedRelatedSkills, setSelectedRelatedSkills] = useState<
    { title: string }[]
  >([]);

  useEffect(() => {
    setOriginalRelatedSkills(relatedSkills);
  }, []);

  useEffect(() => {
    console.log("selectedRelatedSkills", selectedRelatedSkills);
  }, [selectedRelatedSkills]);
  return (
    <div className="p-10">
      <Logo
        onClick={() => navigate("/")}
        className="h-36 object-cover cursor-pointer"
      />
      <div className="max-w-7xl mx-auto pt-10">
        <div className="flex items-start gap-28">
          <div className="w-80 bg-orange/10 p-10 rounded-2xl">
            <p className="text-4xl font-bold pb-4">To know</p>
            {selectedAsignature.title === "" && (
              <p className="text-[#333] font-medium">
                Xtlessons te ofrece la posibilidad de enseñar y compartir tus
                conocimientos en más de 1000 materias.
                <br />
                <br />
                Utiliza el motor de búsqueda para seleccionar la materia
                principal
              </p>
            )}
            {selectedAsignature.title !== "" && (
              <p className="text-[#333] font-medium">
                Puedes agregar hasta 4 materias suplementarias para el mismo
                anuncio. Elige las materias que enseñas de manera simultánea
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-10 w-[60%]">
            {selectedAsignature.title !== "" ? (
              <p className="text-[#333] font-bold text-4xl">
                Give classes of{" "}
                <span className="text-orange">{selectedAsignature.title}</span>
              </p>
            ) : (
              <p className="text-[#333] font-bold text-5xl ">
                What <span className="text-orange">subjects</span> do you teach?
              </p>
            )}

            {selectedAsignature.title !== "" && (
              <div
                onClick={() => setSelectAsignature({ title: "" })}
                className="bg-orange flex items-center justify-between my-2 py-4 px-4 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer "
              >
                <p className="font-semibold text-white">
                  {selectedAsignature.title}
                </p>
                <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                  <Check className="h-4 w-4" />
                </div>
              </div>
            )}
            {selectedAsignature.title !== "" &&
              selectedRelatedSkills.length > 0 && (
                <div className=" ">
                  {selectedRelatedSkills.map((skill) => (
                    <div
                      onClick={() => {
                        setSelectedRelatedSkills(
                          selectedRelatedSkills.filter(
                            (relatedSkill) => relatedSkill.title !== skill.title
                          )
                        );
                        setOriginalRelatedSkills((prev) => [...prev, skill]);
                      }}
                      className="bg-orange flex items-center justify-between my-2 py-4 px-4 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer"
                    >
                      <p className="font-medium text-white">{skill.title}</p>
                      <div className="h-6 w-6 bg-[#f8f8f8] rounded flex flex-col items-center justify-center">
                        <Check className="h-4 w-4 text-orange" />
                      </div>
                    </div>
                  ))}
                </div>
              )}

            {selectedAsignature.title === "" && (
              <div className="h-[600px] overflow-y-auto px-4">
                <div className="flex items-center bg-gray-100 p-3 rounded-full">
                  <Search className="h-7 w-7" />
                  <input
                    className="bg-transparent px-3 font-paragraph flex-grow outline-none"
                    placeholder={`Try "maths"`}
                  />
                </div>
                <div className="flex flex-col mt-4">
                  {researchAsignatures6to12.map((asign) => (
                    <div
                      onClick={() => setSelectAsignature(asign)}
                      className="flex items-center bg-[#f8f8f8] justify-between my-2 py-4 px-4 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer"
                    >
                      <p className="font-semibold text-[#333]">{asign.title}</p>
                      <ChevronRight />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedAsignature.title !== "" && (
              <div className="h-[600px] overflow-y-auto px-4">
                <p className="text-xl font-semibold pb-4">
                  Agregar habilidades relacionadas{" "}
                  <span className="text-sm font-normal">(Opcional)</span>
                </p>
                {originalRelatedSkills
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((skill) => (
                    <div
                      onClick={() => {
                        if (selectedRelatedSkills.includes(skill)) {
                          setSelectedRelatedSkills(
                            selectedRelatedSkills.filter(
                              (selectedSkill) =>
                                selectedSkill.title !== skill.title
                            )
                          );
                        } else {
                          setSelectedRelatedSkills((prev) => [...prev, skill]);
                          setOriginalRelatedSkills(
                            originalRelatedSkills.filter(
                              (originalSkill) =>
                                originalSkill.title !== skill.title
                            )
                          );
                        }
                      }}
                      className="flex items-center bg-[#f8f8f8] justify-between my-2 py-4 px-4 rounded-lg hover:scale-105 transition duration-200 ease-in-out cursor-pointer"
                    >
                      <p className="text-sm font-medium">{skill.title}</p>
                      <PlusSquare className="h-5 w-5 " />
                    </div>
                  ))}
              </div>
            )}
            {selectedAsignature.title !== "" && (
              <div className="flex items-center justify-center gap-3">
                <div className="bg-[#f8f8f8] px-16 py-4 rounded-full">
                  <p className="font-medium">Back</p>
                </div>
                <div
                  onClick={() => navigate("/creation-profile/step-1")}
                  className="px-16 py-4 rounded-full flex items-center justify-center space-x-2 bg-orange"
                >
                  <p className="text-white font-medium">Next</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatDoYouTeach;
