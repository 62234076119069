interface TeacherGroupClassCardProps {
  groupClass: {
    image: any;
    time: string;
    date: string;
    title: string;
    presenter: string;
    price: string;
    category: string;
    theme: string;
  };
}

const TeacherGroupClassCard = ({ groupClass }: TeacherGroupClassCardProps) => {
  return (
    <div className="bg-white  relative h-[500px] rounded-lg">
      <img
        alt={`group-class-${groupClass.title}`}
        src={groupClass.image}
        className="h-52 w-full object-cover rounded-t-lg"
      />
      <div className="flex flex-col items-start p-4 -mt-7">
        <div className="flex items-center space-x-3">
          <div className="bg-gray-100 rounded border-2 border-orange px-2">
            <p className="text-orange font-medium text-sm">
              {groupClass.category}
            </p>
          </div>
          <div className="bg-white rounded border-2 border-orange px-2">
            <p className="text-orange font-medium text-sm">
              {groupClass.theme}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-2 py-4">
          <p className="text-gray-500 text-sm">{groupClass.time}</p>
          <p className="text-gray-500 text-sm">{groupClass.date}</p>
        </div>
        <div className="">
          <p className="text-[#333] font-medium">{groupClass.title}</p>
        </div>
        <p className="text-xs text-gray-400 py-4">
          Group class with {groupClass.presenter}
        </p>
        <img
          alt={`class-group-${groupClass.title}`}
          className="h-10 w-10 rounded-full object-cover"
          src={groupClass.image}
        />
        <div className="absolute bottom-3 w-full flex items-center justify-between">
          <div className="flex items-center ">
            <p className="text-[#333] font-semibold">USD {groupClass.price}</p>
          </div>
          <div className="mr-8">
            <p className="text-gray-400 text-sm font-medium">Be the first!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherGroupClassCard;
