import React from "react";
import BannerIntroduction from "../components/banner-introduction";
import BannerProcess from "../components/banner-process";
import BannerStudents from "../components/banner-students";
import BannerTeachers from "../components/banner-teachers";
import Footer from "../components/footer/Footer";
import Header from "../components/header";
import SidebarHome from "../components/sidebar/SidebarHome";
import ProfessorSelection from "../components/banner-profesorselection";
import SeeOffers from "../components/banner-seeoffers";
import FindTeacher from "../components/banner-findteacher";
import FAQs from "../components/banner-faq";
import BannerAvailable from "../components/banner-appavailable";
import ModalLogin from "../components/modals/ModalLogin";

const Home = () => {
  return (
    <div className="bg-white text-black h-full w-full">
      <SidebarHome />
      <Header />
      <BannerIntroduction />
      <ProfessorSelection />
      <SeeOffers />
      <FindTeacher />
      <FAQs />
      <BannerAvailable />
      <ModalLogin />
      {/* <BannerProcess />
      <BannerTeachers />
      <BannerStudents /> */}
      <Footer />
    </div>
  );
};

export default Home;
