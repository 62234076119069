import { Dialog, DialogContent, DialogHeader } from "src/components/ui/dialog";
//@ts-ignore
import { ReactComponent as Logo } from "src/assets/images/XT Logo Lessons-01.svg";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useModal } from "src/hooks/use-modal-store";

const ModalLogin = () => {
  const navigate = useNavigate();
  const modal = useModal();
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <Logo className="h-16 object-cover" onClick={() => navigate("/")} />
          <div>
            <p className="text-lg font-medium">Welcome to Xtlessons!</p>
            <p className="text-xs mt-2 text-gray-400">
              By logging in or creating an account, you agree to the Xtlessons{" "}
              <span className="text-blue-500 hover:underline transition cursor-pointer">
                Terms of Service
              </span>{" "}
              and{" "}
              <span className="text-blue-500 hover:underline transition cursor-pointer">
                Privacy Policy
              </span>{" "}
            </p>
          </div>
        </DialogHeader>
        <div className="flex flex-col items-center w-full space-y-4">
          <button className="border-[0.5px] hover:border-black rounded border-gray-200 transition duration-300 ease-in-out  px-3 py-3 w-full ">
            <p className="font-medium text-sm">Continue with Google</p>
          </button>
          <button className="border-[0.5px] hover:border-black rounded border-gray-200 transition duration-300 ease-in-out  px-3 py-3 w-full ">
            <p className="font-medium text-sm">Continue with Facebook</p>
          </button>
          <button className="border-[0.5px] hover:border-black rounded border-gray-200 transition duration-300 ease-in-out  px-3 py-3 w-full ">
            <p className="font-medium text-sm">
              Continue with Email/Phone number
            </p>
          </button>
          <div className="text-center">
            <p className="text-sm text-gray-400">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-[#333] cursor-pointer"
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalLogin;
