import { FileEdit, Filter, Search } from "lucide-react";
import { useState } from "react";

const peopleChats = [
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hi Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Teacher, I send you the homework",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
  {
    fullName: "Ismael Diaz",
    lastMessage: "Hey Sweety, you",
    date: "Sept 4",
  },
];

const SidebarChats = () => {
  const [openInput, setOpenInput] = useState(false);
  return (
    <div className="bg-background h-[75vh] overflow-hidden border border-border">
      <div className="p-6 flex items-center justify-between w-full">
        <p className="text-xl font-semibold">Chats</p>
        <div className="flex items-center gap-1">
          <div onClick={() => setOpenInput(!openInput)}>
            <Search className="h-4 w-4" />
          </div>
          <Filter className="h-4 w-4" />
          <FileEdit className="h-4 w-4" />
        </div>
      </div>
      <div className="px-4 py-3">
        {openInput && (
          <div className="border border-border flex items-center">
            <Search className="h-4 w-4" />
            <input
              className="bg-transparent outline-none  font-paragraph text-sm py-2 px-4"
              placeholder="Search by name"
            />
          </div>
        )}
      </div>

      {/* Chats */}
      <div className="overflow-y-auto">
        {peopleChats.slice(0, 10).map((chat) => (
          <div className="flex items-center justify-between px-2 py-2 hover:bg-input cursor-pointer border border-border">
            <div className="flex items-center gap-3">
              <div className="h-10 w-10 bg-input rounded-full flex items-center justify-center">
                <p className="text-lg font-semibold">
                  {chat.fullName.split(" ")[0].slice(0, 1).toUpperCase()}
                  {chat.fullName.split(" ")[1].slice(0, 1).toUpperCase()}
                </p>
              </div>
              <div className="flex flex-col space-y-2">
                <p className="font-medium text-gray-400">{chat.fullName}</p>
                <p className="font-medium text-sm">{chat.lastMessage}...</p>
              </div>
            </div>
            <div className="mr-4">
              <p className="text-sm text-gray-400">{chat.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarChats;
