import MainContainer from "../components/container-main";
import SidebarHelp from "../components/sidebar-help";
import LeftSidebar from "../components/sidebar-left";
import SidebarRight from "../components/sidebar-right";
import { helpOpen } from "../atoms/atoms";
import { useAtom } from "jotai";
import ModalStartSteps from "../components/modals/ModalStartSteps";
import { useEffect } from "react";
import { useModal } from "../hooks/use-modal-store";
import HeaderDashboard from "../components/HeaderDashboard";
import Footer from "../components/footer/Footer";

const Dashboard = () => {
  const [openHelp, setOpenHelp] = useAtom(helpOpen);
  const modal = useModal();

  useEffect(() => {
    modal.onOpen("startingAccount");
  }, []);

  return (
    <div className=" h-screen w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      {/* <div className="">
        <LeftSidebar />
      </div> */}
      <div className="max-w-7xl mx-auto grid grid-cols-6 w-full mt-20 gap-4">
        <div className="col-span-4">
          <MainContainer />
        </div>
        <div className="col-span-2">
          <SidebarRight />
        </div>
      </div>
      <div>
        <SidebarHelp />
      </div>
      <ModalStartSteps />
      <Footer />
    </div>
  );
};

export default Dashboard;
