import { useState } from "react";
import { professorSelection, researchAsignatures6to12 } from "../data/courses";
import { cn } from "../lib/utils";

import YouTube, { YouTubeProps } from "react-youtube";

//@ts-ignore
import Teacher from "../assets/images/teacher-teaching.jpg";

import ReactPlayer from "react-player";
import { ChevronRight } from "lucide-react";
// import { Player, ControlBar } from "video-react";
// import ModalVideo from "react-modal-video";

const ProfessorSelection = () => {
  const [selectedSubject, setSelectedSubject] = useState("");

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "190",
    width: "330",

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div className="py-20 bg-[#f8f8f8] ">
      <div className="max-w-5xl mx-auto font-title">
        <p className="text-4xl font-semibold text-center text-[#333]">
          Pick from 30+ subjects
        </p>
        <div className="flex items-center space-x-2 mt-10">
          {researchAsignatures6to12.slice(0, 7).map((asign) => (
            <div
              onClick={() => setSelectedSubject(asign.title)}
              className={cn(
                "bg-white px-5 py-2 rounded-full cursor-pointer transition duration-200 ease-in-out",
                selectedSubject === asign.title && "bg-[#333] "
              )}
            >
              <p
                className={cn(
                  "text-sm font-semibold text-[#333]",
                  selectedSubject === asign.title && "text-white"
                )}
              >
                {" "}
                {asign.title}
              </p>
            </div>
          ))}
          <div className="bg-white px-4 py-1 rounded-full">
            <p className="text-sm font-semibold text-[#333]">More</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-10 gap-4">
          {professorSelection.map((prof) => (
            <div className="h-[340px]  flex flex-col items-start bg-white relative rounded-lg cursor-pointer">
              {/* <div className="absolute w-full h-40">

              <Player
                // allowFullScreen
                // channel="youtube"
                // isOpen
                // youtube={{ mute: 0, autoplay: 1 }}
                // isOpen={open}
                // onClose={() => setOpen(false)}
                poster={Teacher}
                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                playsInline
                // videoId="xzBoWzvMAIw"
              />
              <ControlBar autoHide={false} />
            </div> */}
              <YouTube
                opts={opts}
                iframeClassName="rounded-t-lg"
                // className="h-48 w-full object-cover rounded-t-lg"
                // poster={Teacher}
                onReady={onPlayerReady}
                videoId={prof.videoId}
              />

              <div className=" w-full group">
                <div className="flex items-start justify-between w-full px-4 pt-3">
                  <div className="flex flex-col items-start">
                    <p className="font-medium text-sm">{prof.fullName}</p>
                    <p className=" text-gray-400 text-xs">{prof.title}</p>
                  </div>
                  <ChevronRight className="h-4 w-4 " />
                </div>
                {prof.asignatures.map((asign) => (
                  <div className="py-2 px-4">
                    <p className="font-medium text-[13px] text-[#333]">
                      {asign.title}
                    </p>
                  </div>
                ))}
                <div className="px-4">
                  <p className=" text-gray-400 text-xs">Lessons start from</p>
                  <p className="font-semibold text-lg">EUR {prof.price}</p>
                </div>
                <div
                  className={cn(
                    "absolute bottom-5 w-full  group-hover:opacity-100 opacity-0"
                  )}
                >
                  <button className="py-3  bg-orange w-full hover:bg-redLogo transition duration-200 ease-in-out rounded-lg cursor-pointer">
                    <p className="font-semibold text-white text-sm">Try now</p>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10">
          <div className="bg-white py-2 flex items-center space-x-3 px-10 w-[33%] mx-auto rounded-lg justify-center cursor-pointer">
            <p className="text-sm font-semibold text-[#333]">
              Find more teachers
            </p>
            <ChevronRight size={18} color="#333" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessorSelection;
