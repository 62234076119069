import HeaderDashboard from "../components/HeaderDashboard";
import Footer from "../components/footer/Footer";
// import LeftSidebar from "../components/sidebar-left";
import ScheduleClass from "../components/teacher-dashboard/ScheduleClass";
import TimeTable from "../components/teacher-dashboard/TimeTable";
import UpcomingClass from "../components/teacher-dashboard/banner-upcomingclass";

const TeacherDashboard = () => {
  return (
    <div className=" w-screen h-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="min-h-[calc(100vh-370px)]">
        <main className="w-full max-w-7xl mx-auto">
          <div>
            <div className="p-6 flex items-center border border-border">
              <p>Schedule</p>
            </div>
            <div className="grid grid-cols-7  mt-10">
              <div className="col-span-5">
                <TimeTable />
                <div className="flex items-center gap-4">
                  <UpcomingClass />
                  <UpcomingClass />
                </div>
              </div>
              <div className="col-span-2">
                <ScheduleClass />
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherDashboard;
