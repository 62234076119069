import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useEffect } from "react";
import { useAtom } from "jotai";
import {
  Bell,
  ChevronDown,
  Grip,
  MessageCircle,
  ArrowDown,
  Search,
  MoreHorizontal,
  ArrowUp,
  Trash,
  HelpCircle,
} from "lucide-react";
import ApexCharts from "apexcharts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { helpOpen } from "../atoms/atoms";

const leaderboardStats = [
  {
    image: "",
    name: "Claudia Diaz",
    percentageDirection: "12",
    percentageTotal: "97",
  },
  {
    image: "",
    name: "Ismael Diaz",
    percentageDirection: "10",
    percentageTotal: "97",
  },
  {
    image: "",
    name: "Beampe Adams",
    percentageDirection: "0",
    percentageTotal: "97",
  },
  {
    image: "",
    name: "James Allen",
    percentageDirection: "6",
    percentageTotal: "97",
  },
  {
    image: "",
    name: "Gloria Smith",
    percentageDirection: "-4",
    percentageTotal: "97",
  },
  {
    image: "",
    name: "Happy boy",
    percentageDirection: "-1",
    percentageTotal: "97",
  },
];

const MainContainer = () => {
  const [openHelp, setOpenHelp] = useAtom(helpOpen);
  let added = 0;
  useEffect(() => {
    const handler = () => {
      const getChartOptions = () => {
        return {
          series: [125, 75, 100],
          colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694"],
          chart: {
            height: 180,
            width: "100%",
            type: "donut",
          },

          stroke: {
            colors: ["transparent"],
            lineCap: "",
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  name: {
                    show: true,
                    fontFamily: "Poppins, sans-serif",
                    offsetY: 20,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    label: "Videos",
                    fontFamily: "Poppins, sans-serif",
                    formatter: function (w: any) {
                      const sum = w.globals.seriesTotals.reduce(
                        (a: any, b: any) => {
                          return a + b;
                        },
                        0
                      );
                      return `${sum}`;
                    },
                  },
                  value: {
                    show: true,
                    fontFamily: "Poppins, sans-serif",
                    offsetY: -20,
                    formatter: function (value: any) {
                      return value;
                    },
                  },
                },
                size: "70%",
              },
            },
          },
          grid: {
            padding: {
              top: -2,
            },
          },
          labels: ["Not Started", "Completed", "In Progress"],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "bottom",
            fontFamily: "Inter, sans-serif",
          },
          yaxis: {
            labels: {
              formatter: function (value: any) {
                return value;
              },
            },
          },
          xaxis: {
            labels: {
              formatter: function (value: any) {
                return value;
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
        };
      };
      if (
        document.getElementById("donut-chart-1") &&
        typeof ApexCharts !== "undefined"
      ) {
        const chart = new ApexCharts(
          document.getElementById("donut-chart-1"),
          getChartOptions()
        );
        if (added === 0) {
          chart.render();
        }

        added += 1;

        // Get all the checkboxes by their class name
        // const checkboxes = document.querySelectorAll(
        //   '#devices input[type="checkbox"]'
        // );

        // // Function to handle the checkbox change event
        // function handleCheckboxChange(event: any, chart: any) {
        //   const checkbox = event.target;
        //   if (checkbox.checked) {
        //     switch (checkbox.value) {
        //       case "desktop":
        //         chart.updateSeries([15.1, 22.5, 4.4, 8.4]);
        //         break;
        //       case "tablet":
        //         chart.updateSeries([25.1, 26.5, 1.4, 3.4]);
        //         break;
        //       case "mobile":
        //         chart.updateSeries([45.1, 27.5, 8.4, 2.4]);
        //         break;
        //       default:
        //         chart.updateSeries([55.1, 28.5, 1.4, 5.4]);
        //     }
        //   } else {
        //     chart.updateSeries([35.1, 23.5, 2.4, 5.4]);
        //   }
        // }

        // // Attach the event listener to each checkbox
        // checkboxes.forEach((checkbox) => {
        //   checkbox.addEventListener("change", (event) =>
        //     handleCheckboxChange(event, chart)
        //   );
        // });
      }
    };

    if (document.readyState === "complete") {
      //   handler();
      console.log(1);
    } else {
      window.addEventListener("load", handler);
      return () => document.removeEventListener("load", handler);
    }
    // window.addEventListener("load", function () {});

    // return () => {
    //   window.removeEventListener("load", () => console.log("listener removed"));
    // };
  }, []);

  // useEffect(() => {
  //   const handler = () => {
  //     let options = {
  //       chart: {
  //         height: "100%",
  //         maxWidth: "100%",
  //         type: "area",
  //         fontFamily: "Poppins, sans-serif",
  //         dropShadow: {
  //           enabled: false,
  //         },
  //         toolbar: {
  //           show: false,
  //         },
  //       },
  //       tooltip: {
  //         enabled: true,
  //         x: {
  //           show: false,
  //         },
  //       },
  //       fill: {
  //         type: "gradient",
  //         gradient: {
  //           opacityFrom: 0.55,
  //           opacityTo: 0,
  //           shade: "#1C64F2",
  //           gradientToColors: ["#1C64F2"],
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         width: 6,
  //       },
  //       grid: {
  //         show: false,
  //         strokeDashArray: 4,
  //         padding: {
  //           left: 2,
  //           right: 2,
  //           top: 0,
  //         },
  //       },
  //       series: [
  //         {
  //           name: "New users",
  //           data: [6500, 6418, 6456, 6526, 6356, 6456],
  //           color: "#1A56DB",
  //         },
  //       ],
  //       xaxis: {
  //         categories: [
  //           "01 February",
  //           "02 February",
  //           "03 February",
  //           "04 February",
  //           "05 February",
  //           "06 February",
  //           "07 February",
  //         ],
  //         labels: {
  //           show: false,
  //         },
  //         axisBorder: {
  //           show: false,
  //         },
  //         axisTicks: {
  //           show: false,
  //         },
  //       },
  //       yaxis: {
  //         show: false,
  //       },
  //     };

  //     if (
  //       document.getElementById("area-chart") &&
  //       typeof ApexCharts !== "undefined"
  //     ) {
  //       const chart = new ApexCharts(
  //         document.getElementById("area-chart"),
  //         options
  //       );
  //       chart.render();
  //     }
  //   };
  //   if (document.readyState === "complete") {
  //     //   handler();
  //     console.log(1);
  //   } else {
  //     window.addEventListener("load", handler);
  //     return () => document.removeEventListener("load", handler);
  //   }
  // }, []);
  return (
    <div>
      <div className="p-6 flex items-center border border-border bg-white rounded-lg mt-4">
        <Search className="h-5 w-5 mr-4" />
        <Input
          className="border-transparent focus-visible:ring-0 focus-visible:ring-transparent font-paragraph"
          placeholder="Search for students, classes, jobs..."
        />
        <HelpCircle
          onClick={() => setOpenHelp(!openHelp)}
          className="h-5 w-5 cursor-pointer mr-2"
        />
        <Grip className="h-5 w-5" />
      </div>
      <div className="p-6">
        <div className="p-6 bg-redLogo rounded-xl">
          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col items-start space-y-5">
              <p className="text-4xl text-white font-semibold">
                Welcome Ismael
              </p>
              <div className="flex flex-col items-start ">
                <p className="text-lg text-white">
                  Your Mathematics class was completed by
                </p>
                <p className="text-lg font-semibold">20 Students</p>
              </div>
              <Button size={"lg"} className="text-[16px]">
                View analytics
              </Button>
              <div>
                <div className="flex items-center space-x-2">
                  <p className="h-[2px] w-10 bg-white rounded-full"></p>
                  <p className="h-[2px] w-10 bg-white/30"></p>
                  <p className="h-[2px] w-10 bg-white/30"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Attendance, help and assignments */}
      <div className="p-6 grid grid-cols-4 gap-8">
        {/* <div className="col-span-2 "> */}
        <div className="flex flex-col items-start justify-between p-6 h-48 border border-border bg-white dark:bg-input rounded-2xl">
          <p className="text-xl font-semibold">Attendance</p>
          <div className="flex items-center">
            <p className="text-sm text-gray-400">Today</p>
            <ChevronDown className="h-4 w-4 text-gray-400" />
          </div>
          <div className="flex items-center w-full justify-between">
            <div>
              <p className="text-xl font-semibold">89</p>
              <p className="text-gray-400 text-sm">Present</p>
            </div>
            <div>
              <p className="text-xl font-semibold">23</p>
              <p className="text-gray-400 text-sm">Absent</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between p-6 h-48 border border-border bg-white dark:bg-input rounded-2xl">
          <p className="text-xl font-semibold">Help</p>

          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <p className="text-xl font-medium">348</p>
                <div className="flex items-center ml-3">
                  <p className="">60%</p> <ArrowDown className="h-4 w-4" />
                </div>
              </div>
              <p className="text-gray-400 text-sm">230 new</p>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="col-span-2">
          <div className="p-6 h-48 border border-border dark:bg-input rounded-lg bg-white flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <p className="text-xl font-semibold">Assignment</p>
              <MoreHorizontal />
            </div>
            <div className="flex flex-col space-y-3">
              <div className="flex items-center">
                <p className="text-gray-400 text-sm w-[80%]">Mathematics</p>
                <div className="flex items-center w-full gap-4">
                  <div className="w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-green-600 h-2.5 rounded-full"
                      style={{ width: "45%" }}
                    ></div>
                  </div>
                  <p className="text-sm"> 45%</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 text-sm w-[80%]">English</p>
                <div className="flex items-center w-full gap-4">
                  <div className="w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-red-600 h-2.5 rounded-full"
                      style={{ width: "75%" }}
                    ></div>
                  </div>
                  <p className="text-sm"> 75%</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 text-sm w-[80%]">Geography</p>
                <div className="flex items-center w-full gap-4">
                  <div className="w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: "35%" }}
                    ></div>
                  </div>
                  <p className="text-sm"> 75%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-4 gap-8 m-6">
        <div className="col-span-2 flex flex-col items-start justify-between h-full bg-white border border-border dark:bg-input rounded-2xl">
          <div className="flex items-center justify-between w-full p-6">
            <p className="text-xl font-semibold">Leaderboard</p>
            <MoreHorizontal className="h-4 w-4 text-gray-400" />
          </div>

          <div className="fle flex-col items-center w-full justify-between p-6">
            {leaderboardStats.map((people) => (
              <div className="flex items-center justify-between py-3">
                <div className="w-[50%]">
                  <div>
                    {people.image}
                    <p className="text-[15px]">{people.name}</p>
                  </div>
                </div>
                <div className="">
                  {people.percentageDirection.includes("-") ? (
                    <div className="flex items-center gap-1 w-20  justify-end">
                      <p className="text-sm text-red-600">
                        {people.percentageDirection}%{" "}
                      </p>
                      <ArrowDown className="h-4 w-4 text-red-600" />
                    </div>
                  ) : (
                    <div className="flex items-center gap-1 w-20  justify-end">
                      <p className="text-sm text-green-600">
                        {people.percentageDirection}%{" "}
                      </p>
                      <ArrowUp className="h-4 w-4 text-green-600" />
                    </div>
                  )}
                </div>

                <div>
                  <p className="font-medium">{people.percentageTotal}%</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center w-full border-t-2 py-4 border-white">
            <p className="uppercase text-lg font-medium">View all students</p>
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-8">
          <div className="border border-border bg-white rounded-lg dark:bg-input p-6 flex items-start">
            <div className="flex flex-col items-start w-[50%] ">
              <p className="text-xl font-semibold mb-4">Lesson Activities</p>
              <div className="flex items-center space-x-2 justify-between w-full">
                <div className="w-10 bg-gray-300 rounded-full h-1.5 dark:bg-gray-700"></div>
                <p className="text-sm text-gray-400">Not Started</p>
                <p className="font-semibold">125</p>
              </div>
              <div className="flex items-center space-x-2 justify-between w-full">
                <div className="w-10 bg-orange-300 rounded-full h-1.5 dark:bg-gray-700"></div>
                <p className="text-sm text-gray-400">Completed</p>
                <p className="font-semibold">75</p>
              </div>
              <div className="flex items-center space-x-2 justify-between w-full">
                <div className="w-10 bg-green-300 rounded-full h-1.5 dark:bg-gray-700"></div>
                <p className="text-sm text-gray-400">In Progress</p>
                <p className="font-semibold">100</p>
              </div>
            </div>
            <div id="donut-chart-1" className="w-[50%]" />
          </div>
          <div className="rounded-lg  bg-white border border-border dark:bg-input p-6 flex items-start ">
            <div className="flex flex-col items-start w-[50%] ">
              <p className="text-xl font-semibold mb-4">Performance</p>
              <div id="area-chart"></div>
            </div>
            <div id="donut-chart-1" className="w-[50%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
