import { HelpCircle } from "lucide-react";
import ConfigLayout from "src/components/configuration/ConfigLayout";

//@ts-ignore
import NoPayment from "src/assets/images/no_payments.png";

const ConfigPayment = () => {
  return (
    <ConfigLayout>
      <div className="bg-white p-4 rounded-lg">
        <p className="text-xl font-semibold pb-4">Payment information</p>
        <div className=" py-4">
          <div className="flex items-center space-x-10 border-t border-b py-4 border-[#f8f8f8]">
            <p className=" font-medium">Country/billing region</p>
            <HelpCircle className="h-6 w-6 " />
            <div className="border border-border px-2 py-2 rounded w-[400px]">
              <p>Spain</p>
            </div>
          </div>
          <div className="h-60 flex flex-col items-center justify-center">
            <img
              src={NoPayment}
              className="h-40 w-40 object-contain"
              alt="no payment"
            />
            <p className="text-gray-700 font-medium ">No payment methods</p>
          </div>
        </div>
      </div>
    </ConfigLayout>
  );
};

export default ConfigPayment;
