import { useModal } from "src/hooks/use-modal-store";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { levelsAsignatures, researchAsignatures6to12 } from "src/data/courses";
import { ChevronLeft, ChevronUp, Search } from "lucide-react";
import { useEffect, useState } from "react";
import { cn } from "src/lib/utils";
import { CommandDialog, CommandInput, CommandList } from "../ui/command";
import { chosenSubject, levelAsignature, stepsStart } from "src/atoms/atoms";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

const ModalStartSteps = () => {
  const navigate = useNavigate();
  const modal = useModal();
  const [stepProcess, setStepProcess] = useAtom(stepsStart);
  const [open, setOpen] = useState(false);
  const [selectSubject, setSelectSubject] = useAtom(chosenSubject);
  const [levelSubject, setLevelSubject] = useAtom(levelAsignature);

  useEffect(() => {
    if (levelSubject !== "" && stepProcess === "") {
      modal.onClose();
    }
  }, [levelSubject, stepProcess]);

  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent className="bg-[#f8f8f8]">
        {stepProcess === "1" && (
          <>
            <DialogHeader>
              <div className="flex items-center space-x-1 justify-center mb-10">
                <div
                  className={cn(
                    "w-10 h-1 bg-gray-200 rounded-full",
                    stepProcess === "1" && "bg-gray-400"
                  )}
                />
                <div className={cn("w-10 h-1 bg-gray-200 rounded-full")} />
              </div>
              <div>
                <p className="text-lg text-[#333]"> I wanna learn...</p>
                <p className="text-xs text-gray-400">
                  Learn in a way that works for you depending on the subject you
                  choose.
                </p>
              </div>
            </DialogHeader>
            <div className="grid grid-cols-2 gap-4">
              {researchAsignatures6to12.slice(0, 10).map((asignature) => (
                <div className="w-52 bg-white rounded-lg py-3">
                  <p className="ml-4 truncate">{asignature.title}</p>
                </div>
              ))}
            </div>
            <div>
              <div
                onClick={() => setOpen(true)}
                className="w-full bg-white py-4 flex items-center justify-center"
              >
                <div className="flex items-center space-x-2">
                  <p className="text-sm text-[#333] font-medium">
                    Choose another subject
                  </p>
                  <ChevronUp className="h-4 w-4 text-center text-[#333]" />
                </div>
              </div>
              <CommandDialog open={open} onOpenChange={setOpen}>
                <div className="flex items-center space-x-2 border rounded-lg h-11 px-2">
                  <Search className="h-5 w-5 text-gray-200" />
                  <CommandInput
                    // className="bg-transparent outline-none px-2"
                    placeholder="Search"
                  />
                  {/* <Input /> */}
                </div>
                <CommandList className="h-60 ">
                  <div className="p-2">
                    {researchAsignatures6to12.map((researh) => (
                      <div
                        onClick={() => {
                          setSelectSubject(researh.title);

                          setStepProcess("2");
                          setOpen(false);
                        }}
                        className="py-2 cursor-pointer hover:bg-[#f8f8f8] px-2"
                      >
                        <p className="text-[13px]"> {researh.title}</p>
                      </div>
                    ))}
                  </div>
                </CommandList>
              </CommandDialog>
            </div>
          </>
        )}
        {stepProcess === "2" && (
          <>
            <DialogHeader>
              <div>
                <div onClick={() => setStepProcess("1")}>
                  <ChevronLeft className="h-6 w-6" />
                </div>
                <div className="flex items-center space-x-1 justify-center mb-10">
                  <div
                    className={cn(
                      "w-10 h-1 bg-gray-200 rounded-full",
                      stepProcess === "2" && "bg-gray-400"
                    )}
                  />
                  <div
                    className={cn(
                      "w-10 h-1 bg-gray-200 rounded-full",
                      stepProcess === "2" && "bg-gray-500"
                    )}
                  />
                </div>
              </div>

              <div>
                <p className="text-lg text-[#333] font-medium">
                  {selectSubject}
                </p>
                <p className="text-xs text-gray-400">
                  Choosing the right level helps your teacher customize the
                  course fo ryou
                </p>
              </div>
            </DialogHeader>
            <div className="flex flex-col space-y-4">
              {levelsAsignatures.map((level) => (
                <div
                  onClick={() => {
                    setLevelSubject(level.levelId);
                    setTimeout(() => {
                      navigate("/loading");
                    }, 2000);
                  }}
                  className="py-3 bg-white px-3 rounded-lg"
                >
                  <p className="text-sm font-medium text-[#333]">
                    {level.title}
                  </p>
                  <p className="text-xs font-medium text-gray-400">
                    {level.subtitle}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalStartSteps;
