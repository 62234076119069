import { Loader2 } from "lucide-react";
//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoadingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 3000);
  }, []);
  return (
    <div className="h-screen  w-screen flex flex-col items-center justify-center">
      <Logo className="h-20 object-cover" />
      <div className="my-10">
        <Loader2 className="h-7 w-7 animate-spin" />
      </div>
      <p className="text-lg font-medium">Matching your suggested teachers...</p>
    </div>
  );
};

export default LoadingScreen;
