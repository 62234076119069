import { Copy, MoreHorizontal, Paperclip } from "lucide-react";
import HeaderDashboard from "../components/HeaderDashboard";
import { generateRandomString } from "../lib/utils";
//@ts-ignore
import Referrals from "src/assets/images/referrals.png";
//@ts-ignore
import { ReactComponent as Whatsapp } from "src/assets/svgs/icons8-whatsapp.svg";
//@ts-ignore
import { ReactComponent as Facebook } from "src/assets/svgs/icons8-facebook.svg";
//@ts-ignore
import { ReactComponent as Google } from "src/assets/svgs/icons8-google.svg";
//@ts-ignore
import Invite from "src/assets/images/icons8-invite-64.png";
//@ts-ignore
import Money from "src/assets/images/icons8-diamond-care-64.png";
//@ts-ignore
import Purchase from "src/assets/images/icons8-bonds-64.png";
import Footer from "../components/footer/Footer";
//@ts-ignore

import NoReferrals from "src/assets/images/no_referrals-nobg.png";
import { Button } from "../components/ui/button";

const referralActions = [
  {
    image: Invite,
    title: "Invite friends",
    description:
      "Send an invitation to a friend via email, social media or your personal referral link.",
  },
  {
    image: Purchase,
    title: "Purchase",
    description:
      "When your friend buys $20 or more in Xtlessons credits, you will both receive a reward.",
  },
  {
    image: Money,
    title: "Win",
    description:
      "You will get $10 in xtlessons credits, and your friend will get $5!.",
  },
];

const Referral = () => {
  return (
    <div className="h-screen w-screen bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="bg-white">
        <div className=" w-full max-w-7xl mx-auto mt-20 flex items-start px-10">
          <div className="flex flex-col items-start py-10 space-y-6">
            <p className="text-3xl font-semibold">
              It's better to learn in company!
            </p>
            <p className="text-sm my-2">
              Earn rewards by recommending italki to your friends! When they buy
              $20 you get $10 and they get $5!
            </p>
            <p className="font-medium pt-6 pb-2">Copy and share your code</p>
            <div className="flex items-center space-x-2 py-2">
              <p>{generateRandomString(7)}</p>
              <Copy className="h-6 w-6" />
            </div>
            <p className="text-sm text-gray-400">
              To earn rewards, remind your friend to go to "My Profile", choose
              "Redeem" and enter the referral code within 24 hours of
              registering.
            </p>
            <div className="flex items-center mt-6 w-full">
              <div className="w-1/3 h-[0.5px] bg-gray-200" />
              <p className="px-2 text-sm text-gray-400">or invite via</p>
              <div className="w-1/3 h-[0.5px] bg-gray-200" />
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex cursor-pointer items-center h-10 space-x-2 hover:bg-gray-200 transition duration-200 py-3 ease-in-out bg-[#f8f8f8] w-60 rounded-md justify-center">
                <Paperclip className="h-5 w-5" />
                <p className="font-medium">Copy link</p>
              </div>
              <div className="h-10 cursor-pointer w-10 flex rounded-md items-center justify-center transition duration-200 ease-in-out bg-[#f8f8f8] hover:bg-gray-200">
                <Whatsapp className="h-7 w-7" />
              </div>
              <div className="h-10 cursor-pointer w-10 flex rounded-md items-center justify-center transition duration-200 ease-in-out bg-[#f8f8f8] hover:bg-gray-200">
                <Facebook className="h-7 w-7" />
              </div>
              <div className="h-10 cursor-pointer w-10 flex rounded-md items-center justify-center transition duration-200 ease-in-out bg-[#f8f8f8] hover:bg-gray-200">
                <Google className="h-7 w-7" />
              </div>
              <div className="h-10 group cursor-pointer w-10 flex rounded-md items-center justify-center transition duration-200 ease-in-out bg-[#f8f8f8] hover:bg-gray-200">
                <MoreHorizontal className="h-6 w-6 group-hover:rotate-90 transition duration-200 ease-in-out" />
              </div>
            </div>
          </div>
          <div>
            <img src={Referrals} />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto flex flex-col items-center mt-14 py-10">
        <p className="text-3xl font-semibold pb-6">
          Bring your friends and earn rewards!
        </p>
        <div className="flex items-center space-x-4">
          {referralActions.map((action) => (
            <div className="bg-white h-72 rounded-lg flex flex-col space-y-5 items-center p-4 w-60 ">
              <img
                src={action.image}
                className="h-16 w-20 object-contain"
                alt={`${action.title}`}
              />
              <p className="text-lg font-semibold"> {action.title}</p>
              <p className="text-[15px] font-medium text-gray-400 text-center">
                {action.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full bg-white h-80 my-14"></div>

      <div className="bg-[#f8f8f8] w-full">
        <div className="max-w-7xl mx-auto flex flex-col items-center mt-14 py-10">
          <p className="text-3xl font-semibold">
            Keep track of your invitations
          </p>
          <p className="text-gray-500 text-sm py-4">
            See how your credits multiply as more of your friends register on
            italki!
          </p>
          <img src={NoReferrals} className="h-40 w-40 object-cover" />
          <p className="text-sm font-medium text-[#333]">
            What are you waiting for, invite your friends now!
          </p>
          <Button variant={"orange"} className="mt-4">
            <p>Invite now</p>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Referral;
