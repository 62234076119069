import MainSettings from "../components/profile/main-settings";
import SidebarSettings from "../components/profile/sidebar-settings";
import LeftSidebar from "../components/sidebar-left";

const Profile = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div>
          <div className="p-6 flex items-center border border-border">
            <p className="text-xl font-semibold">Profile</p>
          </div>
          <div className="grid grid-cols-7 w-[70%]">
            <div className="col-span-5">
              <MainSettings />
            </div>
            <div className="col-span-2">
              <SidebarSettings />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;
