import ConfigLayout from "src/components/configuration/ConfigLayout";

const ConfigNotification = () => {
  return (
    <ConfigLayout>
      <div className="bg-white p-4 rounded-lg">
        <p className="text-xl font-semibold pb-4">Notifications</p>
        <div className="border-t border-border py-4">
          <div className="flex items-start w-full">
            <p className="font-medium text-[#333] w-60">
              Browser Notifications
            </p>
            <div className="flex flex-col items-start ml-6 w-2/3">
              <p className="text-[13px]">
                Xtlessons browser notifications let you know when you have new
                notifications or important messages. We strongly recommend that
                you enable them.
              </p>
              <br />
              <p className="text-[13px]">
                To enable browser notifications, open your browser settings and
                grant notification permissions to italki.
              </p>
            </div>
          </div>
          <div className="flex items-start mt-6 w-full">
            <p className="font-medium text-[#333] w-60">E-mail notifications</p>

            <div className="flex items-center space-x-4 ml-6 w-2/3">
              <p className="text-[13px]"> Language Email Address</p>
              <div className="border border-border px-4 py-2 rounded">
                <p className="text-[13px]">Spanish</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigLayout>
  );
};

export default ConfigNotification;
