import React from "react";
import LeftSidebar from "../components/sidebar-left";

interface VerticalLayoutProps {
  children: React.ReactNode;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">{children}</main>
    </div>
  );
};

export default VerticalLayout;
