import { CreditCard, RotateCw } from "lucide-react";
import { Button } from "../ui/button";
import * as z from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
  card: z.string().min(1),
});

const billingFormSchema = z.object({
  address: z.string().min(1),
  city: z.string().min(1),
  postalCode: z.string().min(1),
  state: z.string().min(1),
  country: z.string().min(1),
});

const MainBilling = () => {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      card: "",
    },
  });

  const billingForm = useForm<z.infer<typeof billingFormSchema>>({
    resolver: zodResolver(billingFormSchema),
    defaultValues: {
      address: "",
      city: "",
      country: "",
      postalCode: "",
      state: "",
    },
  });

  const isLoading = form.formState.isSubmitting;
  const isBillingLoading = billingForm.formState.isSubmitting;

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
  };
  const onBillingSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
  };
  return (
    <div className="p-6 flex items-start gap-10">
      <div className="flex flex-col gap-4 w-[40%]">
        <div className="relative flex flex-col items-center justify-center bg-input h-40 ">
          <p className="text-xl font-semibold">Student Pro Account</p>
          <p className="text-lg font-medium">
            $400
            <span className="font-normal">/month</span>
          </p>
          <Button
            onClick={() => navigate("/subscriptions")}
            className="absolute top-4 right-4"
          >
            Upgrade
          </Button>
        </div>
        <div className="my-10">
          <p className="text-sm">
            <span className="font-medium">
              Your trial ends in Sept 19, September,
            </span>{" "}
            billing will automatically charge your account. To modify or cancel
            <span className="font-medium"> please click here.</span>
          </p>
        </div>
        <div className="">
          <p className="text-lg font-medium border-b">Credits</p>
          <div className="flex  items-center  gap-10 my-4">
            <div className="flex w-1/2 bg-input flex-col items-center p-2 mt-4 ">
              <div className="flex flex-col items-center">
                <p className="text-sm font-medium">Current Balance</p>
                <p className="text-xl font-semibold">19.77€</p>
              </div>
              <div className="flex gap-2 justify-end items-end ml-auto">
                <RotateCw className="h-4 w-4" />
                <p className="text-sm font-medium">Refresh</p>
              </div>
            </div>
            <div className="w-1/2 flex flex-col items-center">
              <Button className="flex items-center w-full gap-2">
                <CreditCard />
                Add Balance
              </Button>
              <div className="flex items-center gap-4 w-full justify-between mt-4 ">
                <p className="">Auto recharge with</p>
                <Select>
                  <SelectTrigger className="w-20">
                    <SelectValue placeholder="10" />
                  </SelectTrigger>
                  <SelectContent className="w-20">
                    <SelectItem value="10">10</SelectItem>
                    <SelectItem value="20">20</SelectItem>
                    <SelectItem value="30">30</SelectItem>
                    <SelectItem value="40">40</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-4 w-full justify-between mt-4">
                <p>when balance is lower</p>
                <Select>
                  <SelectTrigger className="w-20">
                    <SelectValue placeholder="10" />
                  </SelectTrigger>
                  <SelectContent className="w-20">
                    <SelectItem value="10">10</SelectItem>
                    <SelectItem value="20">20</SelectItem>
                    <SelectItem value="30">30</SelectItem>
                    <SelectItem value="40">40</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-[40%]">
        <p className="text-xl font-semibold border-b pb-2">Payment method</p>
        <Form {...form}>
          <form
            className="flex flex-col w-full "
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="card"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <div className="relative w-full">
                      <Input
                        // disabled={isLoading}
                        className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                        placeholder="Card Number"
                        {...field}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button
              className="justify-end ml-auto mt-4"
              disabled={isLoading}
              size="sm"
              variant="default"
            >
              Save
            </Button>
          </form>
        </Form>
        <div>
          <p className="text-xl font-semibold border-b pb-2">Billing Address</p>
          <Form {...billingForm}>
            <form className="flex flex-col space-y-4 w-full pt-2">
              <FormField
                control={billingForm.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Address*</FormLabel>
                    <FormControl>
                      <div className="relative w-full">
                        <Input
                          // disabled={isLoading}
                          className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                          placeholder="Card Number"
                          {...field}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="flex items-center gap-4">
                <FormField
                  control={billingForm.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>City*</FormLabel>
                      <FormControl>
                        <div className="relative w-full">
                          <Input
                            // disabled={isLoading}
                            className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                            placeholder="Card Number"
                            {...field}
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={billingForm.control}
                  name="postalCode"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Postal Code*</FormLabel>
                      <FormControl>
                        <div className="relative w-full">
                          <Input
                            // disabled={isLoading}
                            className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                            placeholder="Card Number"
                            {...field}
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={billingForm.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>State/ Prov/ Region*</FormLabel>
                    <FormControl>
                      <div className="relative w-full">
                        <Input
                          // disabled={isLoading}
                          className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                          placeholder="Card Number"
                          {...field}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={billingForm.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Country*</FormLabel>
                    <FormControl>
                      <div className="relative w-full">
                        <Input
                          // disabled={isLoading}
                          className="p-2 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                          placeholder="Card Number"
                          {...field}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
              <Button
                className="justify-end ml-auto mt-4"
                disabled={isLoading}
                size="sm"
                variant="default"
              >
                Save
              </Button>
            </form>
          </Form>
        </div>
        <div>
          <p className="text-xl font-semibold border-b pb-2">Billing History</p>
          <div className="flex items-center justify-between p-6">
            <p>13-09-2023</p>

            <div className="flex items-center gap-3">
              <p>---- ----</p>
              <p>33.00€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBilling;
