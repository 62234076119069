import MainLiveCall from "../components/live/main-livecall";
import LeftSidebar from "../components/sidebar-left";

const LiveCall = () => {
  return (
    <div className="flex h-screen w-screen ">
      <div>
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div>
          <div className="p-6 flex items-center justify-between w-[70%]">
            <p className="text-xl font-bold">Biology Class with Christina</p>
          </div>
        </div>
        <div className="grid grid-cols-7">
          <div className="col-span-5">
            <MainLiveCall />
          </div>
        </div>
      </main>
    </div>
  );
};

export default LiveCall;
