import HeaderDashboard from "../components/HeaderDashboard";
import MainClasses from "../components/classes/main-classes";
import SidebarClasses from "../components/classes/sidebar-classes";
// import LeftSidebar from "../components/sidebar-left";

//@ts-ignore
import NoData from "src/assets/images/no_data.png";
import { Button } from "../components/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../components/ui/navigation-menu";
import Footer from "../components/footer/Footer";

const actions = [
  { title: "All" },
  { title: "Required action" },
  { title: "Coming" },
  { title: "Pending" },
  { title: "Active" },
  { title: "Completed" },
  { title: "Cancelled" },
];

const typesClasses = [
  { title: "All types of classes" },
  { title: "Private classes" },
  { title: "Packages" },
  { title: "Classes in group" },
  { title: "Group class" },
];

const Classes = () => {
  return (
    <div className="flex flex-col min-h-screen w-screen overflow-hidden bg-[#f8f8f8]">
      {/* <div className="">
        <LeftSidebar />
      </div> */}
      <HeaderDashboard />
      <div className="flex items-center w-full mt-20 bg-white  border-t border-border px-10">
        <div className="flex items-center ">
          {actions.map((action) => (
            <div className="py-4 cursor-pointer px-6">
              <p className="text-[13px] text-gray-500 font-medium hover:text-gray-600 transition duration-300 ease-in-out">
                {action.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <main className="w-full max-w-7xl mx-auto">
        <div>
          <div className="p-6 flex items-center r">
            <p className="text-xl font-semibold">Your Classes</p>
          </div>

          <div className="grid grid-cols-7 gap-4">
            <div className="col-span-5">
              <div className="bg-white rounded-lg flex flex-col items-center justify-center py-6">
                <img
                  src={NoData}
                  className="h-28 w-28 object-cover"
                  alt="no_data"
                />
                <Button
                  variant={"orange"}
                  className="rounded-full mt-4"
                  size={"sm"}
                >
                  Reserve now
                </Button>
              </div>
              <MainClasses />
            </div>
            <div className="col-span-2">
              <div className="bg-white rounded-lg flex flex-col items-start p-4">
                <p className="text-lg mb-4">Filters</p>
                <div className="z-[9999999]">
                  <NavigationMenu>
                    <NavigationMenuList>
                      <NavigationMenuItem>
                        <NavigationMenuTrigger className=" border border-border flex items-center justify-between w-[330px]">
                          <p className="text-gray-500">All types of classes</p>
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                          <>
                            {typesClasses.map((classtype) => (
                              <NavigationMenuItem className="flex items-center justify-between w-[330px] px-4 py-2 text-[15px]  text-gray-600 hover:bg-[#f8f8f8] transition duration-200 ease-in-out cursor-pointer">
                                {classtype.title}
                              </NavigationMenuItem>
                            ))}
                          </>
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    </NavigationMenuList>
                  </NavigationMenu>
                </div>
                <div className="z-[99999]">
                  <NavigationMenu>
                    <NavigationMenuList>
                      <NavigationMenuItem>
                        <NavigationMenuTrigger className=" border border-border flex items-center justify-between w-[330px] mt-4">
                          <p className="text-gray-500">All my subjects</p>
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                          <NavigationMenuItem className="flex items-center justify-between w-[330px] px-4 py-2 text-[15px]  text-gray-600 hover:bg-[#f8f8f8] transition duration-200 ease-in-out cursor-pointer">
                            All my subjects
                          </NavigationMenuItem>
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    </NavigationMenuList>
                  </NavigationMenu>
                </div>

                <NavigationMenu>
                  <NavigationMenuList>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger className=" border border-border flex items-center justify-between w-[330px] mt-4">
                        <p className="text-gray-500">All my teachers</p>
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <NavigationMenuItem className="flex items-center justify-between w-[330px] px-4 py-2 text-[15px]  text-gray-500 hover:bg-[#f8f8f8] transition duration-200 ease-in-out cursor-pointer">
                          All my teachers
                        </NavigationMenuItem>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </div>
              <SidebarClasses />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Classes;
