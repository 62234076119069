import {
  FileText,
  HelpCircle,
  Phone,
  PlaySquare,
  Presentation,
  Projector,
  Users,
} from "lucide-react";
import SidebarLivecall from "../components/live/sidebar-livecall";
import LeftSidebar from "../components/sidebar-left";

import { Player } from "video-react";

const Live = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="">
        <LeftSidebar />
      </div>
      <main className="w-full">
        <div>
          <div className="p-6 flex items-center  justify-between w-[70%]">
            <p className="text-xl font-bold">Biology Class with Christina</p>
            <div className="justify-end flex items-center gap-4">
              <div className="flex items-center gap-3">
                <div className="h-10 w-10 rounded-full bg-green-400 flex items-center justify-center">
                  <Phone className="h-5 w-5" />
                </div>
                <p className="text-[15px]">Call Teacher</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="h-10 w-10 rounded-full bg-blue-400 flex items-center justify-center">
                  <HelpCircle className="h-5 w-5" />
                </div>
                <p className="text-[15px]">Support</p>
              </div>
            </div>
          </div>
          <div className="bg-input flex items-center border border-border rounded justify-between py-3 w-[70%] m-6 ">
            <div className="flex items-center gap-2 rounded-full px-10 py-2">
              <Users className="h-5 w-5" />
              <p className="text-lg font-medium">Classroom</p>
            </div>
            <div className="flex items-center gap-2">
              <Presentation className="h-5 w-5" />
              <p className="text-lg font-medium">Whiteboard</p>
            </div>
            <div className="flex items-center gap-2 bg-white dark:text-black rounded-full px-10 py-2">
              <PlaySquare className="h-5 w-5" />
              <p className="text-lg font-medium">Videos</p>
            </div>
            <div className="flex items-center gap-2 rounded-full px-10 py-2">
              <Projector className="h-5 w-5" />
              <p className="text-lg font-medium">Slideshow</p>
            </div>
            <div className="flex items-center gap-2 rounded-full px-10 py-2">
              <FileText className="h-5 w-5" />
              <p className="text-lg font-medium">Documents</p>
            </div>
          </div>

          <div className="grid grid-cols-7">
            <div className="col-span-1">
              <SidebarLivecall />
            </div>
            <div className="col-span-5">
              <div className="p-6">
                <video width="80%" height={"750"} controls>
                  <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                </video>
                {/* <div className="h-[1000px] w-[900px] p-6  ">
                <Player>
                </Player>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Live;
