import { Search } from "lucide-react";
import HeaderDashboard from "../components/HeaderDashboard";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../components/ui/navigation-menu";
import { useState } from "react";
import { Button } from "../components/ui/button";
//@ts-ignore
import NoTeachers from "src/assets/images/no_teachers.png";
import Footer from "../components/footer/Footer";

const TeacherSelected = () => {
  const [openSearchInput, setOpenSearchInput] = useState(false);
  return (
    <div className="flex flex-col h-screen w-screen overflow-hidden bg-[#f8f8f8]">
      <HeaderDashboard />
      <div className="min-h-[calc(100vh-370px)]">
        <div className="flex items-center w-full mt-20 bg-white border-t border-border px-10 justify-between">
          <div className="flex items-center">
            <div className="py-4 cursor-pointer px-6">
              <p>Favorites</p>
            </div>
            <div className="py-4 cursor-pointer px-6">
              <p>Actual</p>
            </div>
          </div>
          <div className="flex items-center">
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="border border-border flex items-center justify-between w-[200px]">
                    All
                  </NavigationMenuTrigger>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
            <div className="h-[60px] w-[0.2px] bg-gray-200 mx-4" />
            <div className="flex items-center">
              <Search
                onClick={() => setOpenSearchInput(!openSearchInput)}
                className="cursor-pointer"
              />
              {openSearchInput && (
                <input
                  className="bg-transparent outline-none pl-4 font-paragraph text-sm"
                  placeholder="Search by name"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex flex-col items-center mt-10">
            <img
              src={NoTeachers}
              className="h-40 w-60 object-cover"
              alt="no-teachers"
            />
            <p className="text-3xl font-medium py-4">No professors</p>
            <Button variant={"orange"} size={"lg"}>
              Search a teacher
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TeacherSelected;
