import { useParams } from "react-router-dom";
import Header from "../components/header";

//@ts-ignore
import OnlineClasses from "../assets/images/online-classes.png";
//@ts-ignore

import ExpertTeachers from "../assets/images/expert-teachers.png";

//@ts-ignore
import UsefulTopics from "../assets/images/useful-topics.png";

import FiltersGroupClasses from "../components/FiltersGroupClasses";
import { groupClasses, groupClassesExamples } from "../data/courses";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Checkbox } from "../components/ui/checkbox";
import { ChevronDown } from "lucide-react";
import moment from "moment";
import TeacherGroupClassCard from "../components/findTeacher/TeacherGroupClassCard";
import Footer from "../components/footer/Footer";

const FindYourGroupClass = () => {
  const params = useParams();

  const dateTimeObject = new Date();
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <div>
      <Header />
      <div>
        <div className="max-w-7xl mx-auto mt-10">
          {/* <div className="flex items-center justify-between"> */}
          <div className="flex flex-col ">
            <p className="text-3xl font-semibold text-[#333]">
              Xtlessons Group Class
            </p>
            <div className="grid grid-cols-3 mt-4 gap-14 ">
              <div className="flex items-center space-x-2">
                <img src={OnlineClasses} className="w-28 object-cover" />
                <p className="text-[#333] font-medium text-sm">
                  Engaging online classes with 2-6 students from arounde the
                  world
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <img src={ExpertTeachers} className="w-28 object-cover" />
                <p className="text-[#333] font-medium text-sm">
                  Designed and led by expert teachers, start from 5€
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <img src={UsefulTopics} className="w-28 object-cover" />
                <p className="text-[#333] font-medium text-sm">
                  Over 120 useful and interesting topics for you to explore
                </p>
              </div>
            </div>
          </div>
        </div>
        <FiltersGroupClasses />
      </div>
      <div className="bg-[#f8f8f8] py-20  ">
        <div className="max-w-6xl mx-auto">
          <p className="text-2xl font-semibold pb-6">
            Search Group Classes to motivate and inspire
          </p>
          <div className="flex flex-wrap items-center gap-2">
            {groupClasses.map((group) => (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="bg-white px-4 py-2 text-sm flex items-center gap-3 rounded-full">
                    <p>{group.title}</p>
                    <ChevronDown className="h-4 w-4" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <div className="p-2 w-80">
                    {group.categories?.map((category) => (
                      <div className="px-4 py-2 flex items-center justify-between hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out rounded">
                        <p className="text-sm">{category}</p>
                        <Checkbox />
                      </div>
                    ))}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            ))}
          </div>
          <div className="py-10">
            <p className="text-sm font-medium py-4">
              {dateTimeObject.toUTCString().slice(0, 12)}
            </p>
            <div className="grid grid-cols-3 gap-4">
              {groupClassesExamples.map((groupClass) => (
                <TeacherGroupClassCard groupClass={groupClass} />
              ))}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-10">
            <div className="bg-white px-16 py-2 rounded-lg cursor-pointer">
              <p className="text-[#333] font-medium text-sm">Show more</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FindYourGroupClass;
