import {
  Text,
  LayoutGrid,
  MessageSquare,
  Calendar,
  MailOpen,
  Library,
  Timer,
  Radio,
  User,
  CreditCard,
  Settings,
  Keyboard,
  Users,
  UserPlus,
  Mail,
  PlusCircle,
  Plus,
  LogOut,
} from "lucide-react";
import { ModeToggle } from "./mode-toggle";
import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";
import { useState } from "react";
import { UserAvatar } from "./user-avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

//@ts-ignore
import { ReactComponent as Logo } from "../assets/images/XT Logo Lessons-01.svg";

const sidebarNavigation = [
  {
    title: "Dashboard",
    icon: <LayoutGrid />,
    ref: "/dashboard",
  },
  {
    title: "Classes",
    icon: <MessageSquare />,
    ref: "/classes",
  },
  {
    title: "Teachers",
    icon: <Calendar />,
    ref: "/teachers",
  },
  {
    title: "Chat",
    icon: <MailOpen />,
    ref: "/conversations",
  },
  {
    title: "Materials",
    icon: <Library />,
    ref: "/materials",
  },
  {
    title: "Schedule",
    icon: <Timer />,
    ref: "/schedule",
  },
  // {
  //   title: "Help",
  //   icon: <HelpCircle />,
  //   ref: "/help",
  // },
  {
    title: "Live Call",
    icon: <Radio />,
    ref: "/live",
  },
];

const LeftSidebar = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const [showFullSidebar, setShowFullSidebar] = useState(true);

  return (
    <div
      className={cn(
        "bg-background h-screen overflow-hidden border border-border transition flex flex-col justify-between"
      )}
    >
      <div>
        <div className="p-6 flex items-center gap-4">
          <div
            onClick={() => setShowFullSidebar(!showFullSidebar)}
            className="h-10 w-10 rounded-full flex items-center justify-center cursor-pointer hover:bg-input"
          >
            <Text />
          </div>
          {showFullSidebar && (
            <Logo
              onClick={() => navigate("/")}
              className="h-16 object-cover cursor-pointer"
            />
          )}
        </div>
        <div>
          <div className="p-6">
            <p className="text-lg text-gray-400">MAIN</p>
          </div>
          {sidebarNavigation.map((nav) => (
            <button
              onClick={() => navigate(nav.ref)}
              className={cn(
                "px-8 py-4 flex items-center gap-4 relative w-full",
                params.pathname === nav.ref &&
                  "bg-gray-100 dark:bg-input dark:text-blue-400"
              )}
            >
              {params.pathname === nav.ref && (
                <div className="w-0.5 h-full bg-blue-400 absolute left-0" />
              )}
              {nav.icon}
              {/* {showFullSidebar && ( */}
              <p
                className={cn(
                  "font-medium  transition hidden",
                  showFullSidebar && "block transition"
                )}
              >
                {nav.title}
              </p>
              {/* )} */}
            </button>
          ))}
        </div>
        <div className="p-6">
          <ModeToggle />
        </div>
      </div>
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center p-6 cursor-pointer group hover:bg-gray-100 transition">
              <UserAvatar
                src={require("../assets/images/people2.jpg")}
                className="h-8 w-8 md:h-8 md:w-8 mr-2"
              />
              {showFullSidebar && (
                <p className="font-semibold dark:group-hover:text-black">
                  Christina Lee
                </p>
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => navigate("/profile")}>
                <User className="mr-2 h-4 w-4" />
                <span>Profile</span>{" "}
                <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/billing")}>
                <CreditCard className="mr-2 h-4 w-4" />
                <span>Billing</span>
                <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/payments")}>
                <CreditCard className="mr-2 h-4 w-4" />
                <span>Invoices</span>
                <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Settings className="mr-2 h-4 w-4" />
                <span>Settings</span>
                <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Keyboard className="mr-2 h-4 w-4" />
                <span>Keyboard shortcuts</span>
                <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Users className="mr-2 h-4 w-4" />
                <span>Classroom</span>
              </DropdownMenuItem>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <UserPlus className="mr-2 h-4 w-4" />
                  <span>Invite students</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem>
                      <Mail className="mr-2 h-4 w-4" />
                      <span>Email</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <MessageSquare className="mr-2 h-4 w-4" />
                      <span>Message</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                      <PlusCircle className="mr-2 h-4 w-4" />
                      <span>More...</span>
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
              <DropdownMenuItem>
                <Plus className="mr-2 h-4 w-4" />
                <span>New Classroom</span>
                <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => navigate("/login")}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log out</span>
              <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default LeftSidebar;
