import SidebarHelp from "../components/sidebar-help";
import LeftSidebar from "../components/sidebar-left";

const Help = () => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="">
        <LeftSidebar />
      </div>
      <div>{/* <SidebarHelp /> */}</div>
    </div>
  );
};

export default Help;
