//@ts-ignore
import on1Lessons from "../assets/images/1-on-1lessons.png"

//@ts-ignore
import groupClass from "../assets/images/group-class.png"

//@ts-ignore
import community from "../assets/images/community.png"

//@ts-ignore

import Teacher1 from "../assets/images/teacher-teaching.jpg"


export const coursesPrimaria = [
    {
        title: "Matemáticas",
    },
    { title: "Lengua Castellana y Literatura" },
    { title: "Primera Lengua Extranjera" },
    { title: "Ciencias Sociales" },
    { title: "Ciencias de la Naturaleza" },
    { title: "Educación Física" },
    { title: "Religión o Valores Sociales y Cívicos" },
    { title: "Segunda Lengua Extranjera" },
    { title: "Educación Artística" },
];
export const coursesSecundaria = [
    {
        title: "Biología y Geología ",
    },
    {
        title: "Física y Química",
    },
    {
        title: "Economía y Emprendimiento",
    },
    {
        title: "Geografía e Historia",
    },
    { title: "Lengua Castellana y Literatura" },
    { title: "Lengua Cooficial y Literatura" },
    { title: "Matemáticas" },
    { title: "Educación Física" },
    { title: "Música y/o Educación Plástica, Visual y Audiovisual" },
    { title: "Lengua Extranjera" },
    // { title: "Ciencias Sociales" },
    {
        title: "Segunda Lengua Extranjera",
    },
    { title: "Religión " },
    { title: "Valores Cívicos y Éticos " },
    { title: "Tecnología" },
    { title: "Cultura clásica" },
    { title: "Latín" },
];

export const coursesBachillerato = [
    { title: "Educación Física" },
    { title: "Filosofía" },
    { title: "Lengua Castellana y Literatura |" },
    { title: "Lengua Castellana y Literatura ||" },
    { title: "Lengua Cooficial y Literatura |" },
    { title: "Lengua Cooficial y Literatura ||" },
    { title: "Lengua Extranjera |" },
    { title: "Lengua Extranjera ||" },
    { title: "Dibujo Artístico |" },
    { title: "Dibujo Artístico ||" },
    { title: "Cultura Audiovisual" },
    { title: "Dibujo Técnico Aplicado a las Artes Plásticas y al Diseño |" },
    { title: "Dibujo Técnico Aplicado a las Artes Plásticas y al Diseño ||" },
    { title: "Proyectos Artísticos" },
    { title: "Diseño" },
    { title: "Fundamentos Artísticos" },
    { title: "Técnicas de Expresión Gráfico-plástica" },
    { title: "Análisis Musical |" },
    { title: "Análisis Musical ||" },
    { title: "Artes Escénicas |" },
    { title: "Artes Escénicas ||" },
    { title: "Análisis Musical |" },
    { title: "Análisis Musical ||" },
    { title: "Coro y Técnica Vocal |" },
    { title: "Cultura Audiovisual" },
    { title: "Lenguaje y Práctica Musical" },
    { title: "Historia de la Música y de la Danza" },
    { title: "Literatura Dramática" },
    { title: "Matemáticas |" },
    { title: "Matemáticas ||" },
    { title: "Biología, Geología y Ciencias Ambientales" },
    { title: "Dibujo Técnico |" },
    { title: "Física y Química" },
    { title: "Tecnología e Ingeniería |" },
    { title: "Matemáticas Aplicadas a las Ciencias Sociales ||" },
    { title: "Biología" },
    { title: "Dibujo Técnico ||" },
    { title: "Física" },
    { title: "Geología y Ciencias Ambientales" },
    { title: "Química" },
    { title: "Tecnología e Ingenieria ||" },
    { title: "Latín |" },
    { title: "Latín ||" },
    { title: "Matemáticas Aplicadas a las Ciencias Sociales |" },
    { title: "Matemáticas Aplicadas a las Ciencias Sociales ||" },
    { title: "Economía" },
    { title: "Griego |" },
    { title: "Griego ||" },
    { title: "Historia del Mundo Contemporáneo" },
    { title: "Literatura Universal" },
    { title: "Geografía" },
    { title: "Historia del Arte" },
];


export const researchAsignatures6to12 = [
    { title: "Mathematics" },
    { title: "Physical Education" },
    { title: "Language" },
    { title: "Science Natural" },
    { title: "Arts" },
    { title: "Social Studies" },
    { title: "Music" },
    { title: "Religion" },
    { title: "Ethics" },
    { title: "History" },
    { title: "Foreign language" },
    { title: "Geography" },
    { title: "Social Science" },
    { title: "Biology" },
    { title: "Chemistry" },
    { title: "Computer/IT" },
    { title: "Physics" },
    { title: "Skills of Life" },
    { title: "Reading" },
    { title: "Writing" },
    { title: "Technology & Deisgn" },
    { title: "Knowledge of Nature" }

]

export const researchAsignaturesfrom12to16 = [
    { title: "Mathematics" },
    { title: "Literature" },
    { title: "Physical Education" },
    { title: "Chemistry" },
    { title: "Biology" },
    { title: "Physics" },
    { title: "History" },
    { title: "Geography" },
    { title: "Foreign Language" },
    { title: "Arts" },
    { title: "Music" },
    { title: "Social Studies" },
    { title: "Economics" },
    { title: "Technology" },
    { title: "Natural Science" },
    { title: "Computer/IT" },
    { title: "Philosophy" },
    { title: "Religion" },
    { title: "Religion" },
    { title: "Ethics" },
    { title: "Values" },
    { title: "Business" },
    { title: "Management" },
    { title: "Marketing" },
    { title: "Civic Education" },
    { title: "Health" },
    { title: "Humanities" },
    { title: "Sociology" },
    { title: "Accounting" },
    { title: "Psychology" },
    { title: "Agriculture" },
    { title: "Home Economics" },
    { title: "Contruction" },
    { title: "Building" },
    { title: "Electronics" },
    { title: "Mechanics" },
    { title: "Automotive" },
    { title: "Anthropology" },
    { title: "Civil Technology" },
]

export const researchAsignaturesFrom16To18 = [
    {
        title: "Mathematics"
    },
    {
        title: "Literature"
    },
    {
        title: "Physical Education"
    },
    {
        title: "Chemistry"
    },
    {
        title: "Biology"
    },
    {
        title: "Physics"
    },
    { title: "History" },
    { title: "Geography" },
    { title: "Foreign Language" },
    { title: "Arts" },
    { title: "Social Studies" },
    { title: "Economics" },
    { title: "Technology" },
    { title: "Natural Science" },
    { title: "Computer/IT" },
    { title: "Philosophy" },
    { title: "Religion" },
    { title: "Ethics" },
    { title: "Values" },
    { title: "Business" },
    { title: "Management" },
    { title: "Marketing" },
    { title: "Civic Education" },
    { title: "Health" },
    { title: "Humanities" },
    { title: "Sociology" },
    { title: "Accounting" },
    { title: "Psychology" },
    { title: "Agriculture" },
    { title: "Home Economics" },
    { title: "Construction" },
    { title: "Electronics" },
    { title: "Mechanics" },
    { title: "Automotive" },
    { title: "Anthropology" },
    { title: "Civil Technology" },

]

export const universitiesDegrees = [
    { title: "Accounting", topSubjects: ["Financial Accounting", "Managerial", "Accounting", "Auditing"], topMajor: ["Managerial", "Accounting", "Financial Accounting", "Tax Accounting"] },
    { title: "Administration", topSubjects: ["Principles of Management", "Financial Accounting", "Microeconomics"], topMajor: ["Business", "Administration", "Public Administration", "Healthcare Administration"] },
    { title: "Agriculture", topSubjects: ["Soil Science", "Animal Science", "Crop Science"], topMajor: ["Agribusiness", "Agricultural", "Economics", "Agronomy"] },
    { title: "Applied Sciences and Professions", topSubjects: ["Calculus and Differential", "Equations", "Thermodynamics", "Organic Chemistry"], topMajor: ["Applied Mathematics", "Applied Chemistry", "Applied Physics",] },
    { title: "Architecture", topSubjects: ["Architectural Design", "Architectural History and Theory Graphics",], topMajor: ["Urban Design and Planning", "Sustainable Architecture", "Interior Design", "Landscape", "Architecture"] },
    { title: "Arts", topSubjects: ["Drawing", "Painting", "Sculpture"], topMajor: ["Fine Arts", "Performing Arts", "Visual Arts",] },
    { title: "Biology", topSubjects: ["General Biology ", "Anatomy and Physiology", "Microbiology"], topMajor: ["Genetics", "Botanic", "Microbiology",] },

    { title: "Biomedicine", topSubjects: ["Anatomy and Physiology  ", "Biochemistry", "Cell Biology"], topMajor: ["BioMedical", "Engineering", "Biotechonology", "Biochemistry"] },

    { title: "Building and Contruction", topSubjects: ["Building Materials and Methods ", "Contruction", "Planning and Scheduling", "Building Codes and Regulations"], topMajor: ["Contruction", "Management", "Architectural", "Engineering", "Civil Engineering"] },
    { title: "Business", topSubjects: ["Accounting", "Finance", "Marketing",], topMajor: ["Accounting", "Finance", "Marketing"] },
    { title: "Chemistry", topSubjects: ["General Chemistry", "Organic Chemistry", "Analytical Chemistry",], topMajor: ["Organic Chemistry", "Inorganic Chemistry", "Analytical Chemistry"] },
    { title: "Computer Science", topSubjects: ["Data Structures and Algorithms", "Programming", "Languages", "Computer Networks"], topMajor: ["Data Science", "Cybersecurity", "Artifical Intelligence",] },
    { title: "Anthropology", topSubjects: ["Introduction to Anthropology", "Cultural", "Anthropology", "Biological", "Anthropology"], topMajor: ["Cultural", "Anthropology", "Archaeology", "Linguistic", "Anthropology"] },
    { title: "Dentistry", topSubjects: ["Anatomy and Physiology", "Oral Biology", "Anthropology", "Biochemistry"], topMajor: ["Periodontics", "Endodontics", "Orthodontics",] },
    { title: "Design", topSubjects: ["Design Theory and Practice", "Design Thinking and Methods", "Visual Communication", "Design"], topMajor: ["Graphic Design", "Industrial Design", "Interior Design",] },
    { title: "Earth Sciences", topSubjects: ["Earth System Science", "Geology", "Mineralogy and Petrology",], topMajor: ["Geology ", "Environmental Science", "Oceanography",] },
    { title: "Economics", topSubjects: ["Microeconomics", "Macroeconomics", "Econometrics",], topMajor: ["Environmental Economics", "Financial Economics", "Behavioral Economics",] },
    { title: "Education and Teaching", topSubjects: ["Educational Psychology", "Curriculum and Instruction", "Classroom Management and Assessment",], topMajor: ["Elementary", "Education", "Secondary Education", "Special Education"] },
    { title: "Engineering", topSubjects: ["Calculus and Differential", "Equations", "Physics (Classical Mechanics, Thermodynamics,  Electricity and Magnetism, Waves and Optics", "Chemistry"], topMajor: ["Mechanical Engineering", "Electrical Engineering", "Secondary Education", "Chemical Engineering"] },
    { title: "Environmental Studies", topSubjects: ["Environmental Policy and Law", "Environmental Economics", "Ecology and Biodiversity"], topMajor: ["Environmental Policy and Management", "Environmental", "Engineering", "Conservation Biology"] },
    { title: "Finance and Banking", topSubjects: ["Managerial", "Accounting", "Corporate Finance", "Financial Accounting"], topMajor: ["Financial Management", "Investment Banking", "Corporate Finance"] },
    { title: "Health", topSubjects: ["Epidemiology", "Health Policy and Management", "Anatomy and Physiology",], topMajor: ["Public Health", "Physical Therapy", "Health", "Administration"] },
    { title: "Humanities", topSubjects: ["History of Art and Culture", "Literature and Writing", "Philosophy and Ethics",], topMajor: ["English Language and Literature", "History", "Philosophy",] },
    { title: "International Relations", topSubjects: ["International Political Economy", "International Security", "International Law and Organizations",], topMajor: ["International Security and Conflict", "Resolution Global Governance and Diplomacy", "International Development",] },


    { title: "IT", topSubjects: ["Computer Programming", "Web Development", "Database Systems",], topMajor: ["Broadcast Journalism", "Photojournalism", "Investigative Journalism",] },

    { title: "Law and Jurisprudence", topSubjects: ["Constitutional Law", "Contracts Law", "Criminal Law",], topMajor: ["Constitutional Law", "Criminal Law", "Business Law",] },

    { title: "Marketing", topSubjects: ["Principles of Marketing", "Consumer Behavior", "Marketing Research",], topMajor: ["Digital Marketing", "Brand Management", "Consumer Behavior",] },

    { title: "Mathematics", topSubjects: ["Calculus", "Linear Algebra", "Differential Equations",], topMajor: ["Pure Mathematics", "Applied Mathematics", "Computational", "Mathematics",] },

    { title: "Medicine", topSubjects: ["Anatomy and Physiology", "Medical Terminology", "Biochemistry", "Pharmacology", "Medical Microbiology and Immunology"], topMajor: ["Anesthesiology", "Cardiology", "Dermatology",] },

    { title: "Multimedia Technology", topSubjects: ["Digital Imaging and Editing", "Audio and Video Production", "Web Design and Development",], topMajor: ["Graphic Design", "Web Design and Development", "Digital Animation",] },
    { title: "Nursing", topSubjects: ["Anatomy and Physiology", "Pathophysiology", "Health Assessment",], topMajor: ["Adult Health Nursing", "Family Health", "Nursing", "Geriatric Nursing"] },

    { title: "Pharmacy", topSubjects: ["Chemistry for Pharmacy", "Biology for Pharmacy Anatomy and Physiology",], topMajor: ["Pharmaceutics", "Pharmacology", "Medicinal Chemistry"] },

    { title: "Philology", topSubjects: ["Classical Mechanics Electricity and Magnetism", "Quantum Mechanics",], topMajor: ["Linguistics", ""] },
    { title: "Physics", topSubjects: ["Classical Mechanics", "Electricity and Magnetism", "Quantum Mechanics"], topMajor: ["Astrophysics", "Atomic and Molecular Physics", "Biophysics"] },
    { title: "Political Science", topSubjects: ["Comparative Politics", "International Relations", "Political Theory"], topMajor: ["Political Theory", "Comparative Politics", "International Relations"] },
    { title: "Psychology Science", topSubjects: ["General Psychology", "Statistics for Psychology", "Research Methods in Psychology"], topMajor: ["Clinical Psychology", "Developmental", "Psychology", "Social Psychology"] },

    { title: "Sociology", topSubjects: ["Social Research Methods", "Social Inequality", "Sociological Theory"], topMajor: ["Social Psychology", "Developmental", "Social Stratification", "Criminology"] },

    { title: "Sociology", topSubjects: ["Computer hardware and software", "Electronics and circuit design", "Robotics and automation"], topMajor: ["Automotive", "Technology", "Building Technology", "Electrical Technology"] },

    { title: "Tourism", topSubjects: ["Tourism", "Management and Development", "Hospitality and Resort Management", "Marketing and Sales in Tourism"], topMajor: ["Hospitality", "Management", "Event Planning and Management", "Destination", "Management and Marketing"] },
]

export const professorSelection = [
    {
        fullName: "Ismael Diaz",
        title: "Professional Teacher",
        asignatures: [
            { title: "Math", level: 4 }
        ],
        price: "8.00",
        videoId: "igxb-K92StQ"
    },
    {
        fullName: "Gabriela Sanchez",
        title: "Professional Teacher",
        asignatures: [
            { title: "English", level: 4 }
        ],
        price: "14.00",
        videoId: "g6ORkolwYME"
    },
    {
        fullName: "Alfonsito Cuevas",
        title: "Professional Teacher",
        asignatures: [
            { title: "Physics", level: 4 }
        ],
        price: "18.00",
        videoId: "77ZF50ve6rs"
    },
]

export const seeOffers = [
    {
        img: on1Lessons,
        title: "1-on1 lessons",
        desc: "Find teachers from all over the world sharing their expertise on different subjects.",
        button: "Find my teacher"
    },
    {
        img: groupClass,
        title: "Group class",
        desc: "Fun and engagin online group classes designed and led by expert teachers",
        button: "View all classes"
    },
    {
        img: community,
        title: "Practice for free",
        desc: "Meet and share experiences with millions of students from more than 90 countries",
        button: "Explore the community"
    },
]

export const levels = [
    "Primary",
    "Seconday",
    "Bachelor's",
    "University",
    "Master's"

]

export const levelsAsignatures = [
    { title: "Beginning Level", subtitle: "Primary School", levelId: "0" },
    { title: "Basic Level", subtitle: "Secondary School", levelId: "1" },
    { title: "Intermediate Level", subtitle: "Bachelors School", levelId: "2" },
    { title: "Advanced Level", subtitle: "University ", levelId: "3" },

]

export const questions = [
    {
        question: "How does Xtlessons work?",
        answer: "Xtlessons helps you achieve your subject learning ambitions.Find your ideal teacher and book a 1-on-1 lesson.  "
    },
    {
        question: "How does Xtlessons work?",
        answer: "Xtlessons helps you achieve your subject learning ambitions.Find your ideal teacher and book a 1-on-1 lesson.  "
    },
    {
        question: "How does Xtlessons work?",
        answer: "Xtlessons helps you achieve your subject learning ambitions.Find your ideal teacher and book a 1-on-1 lesson.  "
    },
    {
        question: "How does Xtlessons work?",
        answer: "Xtlessons helps you achieve your subject learning ambitions.Find your ideal teacher and book a 1-on-1 lesson.  "
    },
    {
        question: "How does Xtlessons work?",
        answer: "Xtlessons helps you achieve your subject learning ambitions.Find your ideal teacher and book a 1-on-1 lesson.  "
    },
]

export const daysWeek = [
    {
        val: "Wed", label: "Wed"
    },
    {
        val: "Thu", label: "Thu"
    },
    {
        val: "Fri", label: "Fri"
    },
    {
        val: "Sat", label: "Sat"
    },
    {
        val: "Sun", label: "Sun"
    },
    {
        val: "Mon", label: "Mon"
    },
    {
        val: "Tue", label: "Tue"
    }

]

export const timeRange = [
    {
        hourFrom: "0:00",
        hourTo: "4:00"
    },
    {
        hourFrom: "4:00",
        hourTo: "8:00"
    },
    {
        hourFrom: "8:00",
        hourTo: "12:00"
    },
    {
        hourFrom: "12:00",
        hourTo: "16:00"
    },
    {
        hourFrom: "16:00",
        hourTo: "20:00"
    },
    {
        hourFrom: "20:00",
        hourTo: "24:00"
    },
]

export const morningHours = [
    {
        hour: "00:00"
    },
    {
        hour: "01:00"
    },
    {
        hour: "02:00"
    },
    {
        hour: "03:00"
    },
    {
        hour: "04:00"
    },
    {
        hour: "05:00"
    },
    {
        hour: "06:00"
    },
    {
        hour: "07:00"
    },
    {
        hour: "08:00"
    },
    {
        hour: "09:00"
    },
    {
        hour: "10:00"
    },
    {
        hour: "11:00"
    }
]
export const afternoonEveningHours = [
    {
        hour: "12:00"
    },
    {
        hour: "13:00"
    },
    {
        hour: "14:00"
    },
    {
        hour: "15:00"
    },
    {
        hour: "16:00"
    },
    {
        hour: "17:00"
    },
    {
        hour: "18:00"
    },
    {
        hour: "19:00"
    },
    {
        hour: "20:00"
    },
    {
        hour: "21:00"
    },
    {
        hour: "22:00"
    },
    {
        hour: "23:00"
    }
]

export const speakingLanguages = [
    {
        title: "",
        languages: ["Native speaker"]
    },
    {
        title: "Popular languages",
        languages: ["English", "Chinese (Mandarin)", "French", "Spanish", "Portuguese", "German", "Japanese", "Korean", "Arabic", "Hindi", "Italian", "Russian"]
    },
    {
        title: "Other languages",
        languages: ["Afrikaans", "Albanian", "Amharic", "Armenian"]
    }
]

export const professorCountries = [
    {
        title: "Popular countries/regions",
        countries: ["United States", "United Kingdom", "Canada", "Australia", "South Africa", "Ireland", "New Zealand"]
    },
    {
        title: "Other countries/regions",
        countries: ["Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla", "Antigua and Barbuda"]
    }
]


export const professorsExample = [

    {
        image: Teacher1,
        type: "Professional Teacher",
        rating: "5.0",
        valoraciones: "41",
        fullName: "Carl",
        location: "Reino Unido",
        numberOfClasses: 696,
        teaches: "Inglés",
        speaks: "Inglés",
        title: "Professional language teacher with over 10 years of experience",
        description: "Hi, I'm Carl, a Brith English teacher with over 10 years experience teaching in the UK, China and Europe.",
        price: "20"

    },
    {
        image: Teacher1,
        type: "Professional Teacher",
        rating: "4.0",
        valoraciones: "41",
        fullName: "Carl",
        location: "Reino Unido",
        numberOfClasses: 696,
        teaches: "Inglés",
        speaks: "Inglés",
        title: "Professional language teacher with over 10 years of experience",
        description: "Hi, I'm Carl, a Brith English teacher with over 10 years experience teaching in the UK, China and Europe.",
        price: "20"

    },
    {
        image: Teacher1,
        type: "Professional Teacher",
        rating: "4.5",
        valoraciones: "41",
        fullName: "Carl",
        location: "Reino Unido",
        numberOfClasses: 696,
        teaches: "Inglés",
        speaks: "Inglés",
        title: "Professional language teacher with over 10 years of experience",
        description: "Hi, I'm Carl, a Brith English teacher with over 10 years experience teaching in the UK, China and Europe.",
        price: "20"

    }
]

export const levelsLanguage = [
    {
        title: "A1: Beginner",
        subtitle: "I've just started learning or I know a few basic words and phrases"
    },
    {
        title: "A2: Elementary",
        subtitle: "I can describe things in simple terms and understand basic expressions"
    },
    {
        title: "B1: Intermediate",
        subtitle: "I can talk about my hobbies, work, and family in this language"
    },
    {
        title: "B2: Upper Intermediate",
        subtitle: "I can talk about my hobbies, work, and family in this language"
    },
    {
        title: "B2: Upper Intermediate",
        subtitle: "I can understand the main ideas of a complicated topic and have no trouble talking to native speakers."
    },
    {
        title: "C1: Advanced",
        subtitle: "I have a large vocabulary and can explain myself clearly."
    },
    {
        title: "C2: Proficient",
        subtitle: "I can confidently have complex conversations in this language."
    },

]

export const groupClasses = [
    {
        title: "Daily Conversation",
        categories: ["All", "Small talk", "Free talk", "Making appointments", "Shopping", "Dining at restaurants"]
    },
    {
        title: "Language essentials"
    },
    {
        title: "Travel  and global culture"
    },
    {
        title: "Business and career"
    },
    {
        title: "Debating and discussion"
    },
    {
        title: "Hobbies and interests"
    },
    {
        title: "Exam preparation"
    },
    {
        title: "Kids"
    }
]

export const groupClassesExamples = [
    {
        time: "10:00 - 11:00",
        date: "Thursday, Oct 5, 2023",
        category: "B1",
        theme: "Daily Conversation",
        presenter: "Ismael Diaz",
        title: "Conversation Club - Intermediate",
        price: "10:00",
        image: Teacher1
    },
    {
        time: "12:00 - 13:00",
        date: "Thursday, Oct 5, 2023",
        category: "C1",
        theme: "Buiness and career",
        presenter: "Ismael Diaz",
        title: "Unleash your decision-making superpowers - C1+",
        price: "10:00",
        image: Teacher1

    },
    {
        time: "13:00 - 14:00",
        date: "Thursday, Oct 5, 2023",
        category: "A2",
        theme: "Daily Conversation",
        presenter: "Ismael Diaz",
        title: "Let's talk! Fun talk - A2 - B1 levels",
        price: "10:00",
        image: Teacher1

    },
]

export const relatedSkills = [
    {
        title: "Estadística"
    },
    {
        title: "Física"
    },
    {
        title: "Química"
    },
    {
        title: "Otras ciencias"
    },
    {
        title: "Lógica matemática"
    },
    {
        title: "Trigonometría"
    },
    {
        title: "Geometría"
    },
    {
        title: "Aritmética"
    },
    {
        title: "Ciencias Naturales"
    },
    {
        title: "Álgebra "
    }
]